import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ORDER_STATUS } from '../../helpers/constants';
import { formatAddress, formatISODate, formatShortAddress } from '../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

function ColumnGridItemAvatarContent({ status, closeDate }) {
    const closeDay = closeDate ? formatISODate(closeDate, 'dd') : '';
    const closeMonth = closeDate ? formatISODate(closeDate, 'MMM') : 'TBD';

    if (status === ORDER_STATUS.canceled) {
        return <BlockIcon sx={{ color: (theme) => theme.palette.text.secondary }} />;
    }

    if (status === ORDER_STATUS.closed) {
        return <DoneIcon color="primary" />;
    }

    return (
        <Stack direction="column" spacing={0} alignItems="center">
            <Typography fontSize={12} fontWeight={500} sx={{ lineHeight: (theme) => theme.spacing(2) }}>
                {closeDay}
            </Typography>

            <Typography
                fontSize={10}
                fontWeight={500}
                textTransform="uppercase"
                color="text.secondary"
                sx={{ lineHeight: (theme) => theme.spacing(2) }}
            >
                {closeMonth}
            </Typography>
        </Stack>
    );
}

const OrderListColumnGridItemSkeleton = () => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <Skeleton variant="rectangular" width={24} height={24} />

            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                <Skeleton type="text" height={10} width="60%" />
                <Skeleton type="text" height={10} width="30%" />
            </Stack>

            <Skeleton variant="circular" width={24} height={24} />
        </Stack>
    );
};

function OrderListColumnGridItem({ order, deviceDetails }) {
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;
    const address =
        deviceDetails.size !== SCREEN_SIZE.large
            ? formatShortAddress(order.property_address)
            : formatAddress(order.property_address);

    const primaryText = address;
    const transactionType = _.startCase(order.transaction_type);
    const secondaryText = isExtraSmallScreen ? transactionType : address;

    return (
        <ListItem
            disablePadding
            secondaryAction={
                <IconButton edge="end" aria-label="view" component={Link} to={`/escrow/${order.id}`}>
                    <NavigateNextIcon />
                </IconButton>
            }
        >
            <ListItemButton
                component={Link}
                to={`/escrow/${order.id}`}
                sx={{
                    pl: 1,
                    '&.MuiListItemButton-root:hover': {
                        backgroundColor: 'transparent',

                        '& .MuiListItemText-primary': {
                            textDecoration: 'underline',
                        },
                    },
                }}
                disableGutters
            >
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <ColumnGridItemAvatarContent status={order.status} closeDate={order.close_date} />
                    </Avatar>
                </ListItemAvatar>

                <ListItemText
                    primary={primaryText}
                    primaryTypographyProps={{
                        variant: 'h6',
                        // fontSize: isExtraSmallScreen ? '14px' : '16px',
                        // fontWeight: isExtraSmallScreen ? '500' : '600',
                    }}
                    secondary={secondaryText}
                    // secondaryTypographyProps={}
                />
            </ListItemButton>
        </ListItem>
    );
}

// TODO add custom footer pagination component
function OrderListColumnGrid({ orders, loading }) {
    const deviceDetails = useDeviceDetails();

    const showSkeleton = loading && orders.length === 0;

    return (
        <Box sx={{ display: 'flex', flex: 1 }}>
            <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
                <List
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        border: (theme) => `solid 1px ${theme.palette.border}`,
                        borderRadius: (theme) => theme.spacing(2),
                        padding: 0,

                        '& .MuiListItem-root': {
                            '&:first-of-type': {
                                borderTopLeftRadius: (theme) => theme.spacing(2),
                                borderTopRightRadius: (theme) => theme.spacing(2),
                            },
                            '&:last-of-type': {
                                borderBottomLeftRadius: (theme) => theme.spacing(2),
                                borderBottomRightRadius: (theme) => theme.spacing(2),
                            },
                            '&:not(:last-of-type)': {
                                borderBottom: (theme) => `solid 1px ${theme.palette.border}`,
                            },
                        },
                    }}
                >
                    {showSkeleton && _.map(_.range(3), (i) => <OrderListColumnGridItemSkeleton key={i} />)}

                    {!showSkeleton &&
                        _.map(orders, (order) => (
                            <OrderListColumnGridItem key={order.id} deviceDetails={deviceDetails} order={order} />
                        ))}
                </List>
            </Box>
        </Box>
    );
}

OrderListColumnGrid.propTypes = {
    orders: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default OrderListColumnGrid;
