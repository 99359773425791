import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChaletOutlinedIcon from '@mui/icons-material/ChaletOutlined';
import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetOrderQuery } from '../../../../api/order';
import { TRANSACTION_PARTY_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { IMPORTANT_DASHBOARD_EVENTS, IMPORTANT_EVENT_TYPES } from '../../../../helpers/orderEvent';
import CompoundIcon from '../../../common/CompoundIcon';
import useDeviceDetails, { SCREEN_SIZE } from '../../../common/hooks/useDeviceDetails';
import { AccordionBox } from '../../../common/styled';
import SubSectionAccordion from '../common/SubSectionAccordion';
import SubSectionPaper from '../common/SubSectionPaper';
import ClosingFunding from './ClosingFunding';
import ClosingInfo from './ClosingInfo';
import ClosingParty from './ClosingParty';

const sectionType = IMPORTANT_EVENT_TYPES.closing;
const sectionEvent = IMPORTANT_DASHBOARD_EVENTS[sectionType];

const SUB_SECTION_TYPES = {
    info: 'info',
    closing_buyer: 'closing_buyer',
    closing_seller: 'closing_seller',
    closing_borrower: 'closing_borrower',
    funding: 'funding',
};

const SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.info]: {
        title: 'About Closing',
        Icon: <InfoOutlinedIcon color="default" />,
        renderContent: (props) => <ClosingInfo {...props} />,
    },
};

const PURCHASE_SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.closing_buyer]: {
        title: "Buyer's Closing",
        Icon: (
            <CompoundIcon
                PrimaryIcon={ChaletOutlinedIcon}
                SecondaryIcon={AddIcon}
                primaryProps={{
                    color: 'default',
                }}
                secondaryProps={{
                    color: 'default',
                    sx: {
                        backgroundColor: (theme) => theme.palette.background.paper,
                        fontSize: '.75rem',
                        borderRadius: '50%',
                        top: 0,
                        right: 0,
                        left: 'unset',
                        transform: 'none',
                    },
                }}
            />
        ),
        renderContent: (props) => <ClosingParty {...props} party={TRANSACTION_PARTY_TYPE.buyer} />,
    },
    [SUB_SECTION_TYPES.closing_seller]: {
        title: "Seller's Closing",
        Icon: (
            <CompoundIcon
                PrimaryIcon={ChaletOutlinedIcon}
                SecondaryIcon={RemoveIcon}
                primaryProps={{
                    color: 'default',
                }}
                secondaryProps={{
                    color: 'default',
                    sx: {
                        backgroundColor: (theme) => theme.palette.background.paper,
                        fontSize: '.75rem',
                        borderRadius: '50%',
                        top: 0,
                        right: 0,
                        transform: 'none',
                    },
                }}
            />
        ),
        renderContent: (props) => <ClosingParty {...props} party={TRANSACTION_PARTY_TYPE.seller} />,
    },
};

const REFIANCE_SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.closing_borrower]: {
        title: "Borrower's Closing",
        Icon: (
            <CompoundIcon
                PrimaryIcon={PublishedWithChangesIcon}
                SecondaryIcon={HomeIcon}
                primaryProps={{
                    color: 'default',
                }}
                secondaryProps={{
                    color: 'default',
                    sx: {
                        backgroundColor: (theme) => theme.palette.background.paper,
                        fontSize: '.75rem',
                        borderRadius: '50%',
                    },
                }}
            />
        ),
        renderContent: (props) => <ClosingParty {...props} party={TRANSACTION_PARTY_TYPE.borrower} />,
    },
};

const APPEND_SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.funding]: {
        title: 'Funding',
        Icon: <AttachMoneyIcon color="default" />,
        renderContent: (props) => <ClosingFunding {...props} />,
    },
};

function generateSubSectionConfig(transactionType) {
    if (transactionType === TRANSACTION_TYPE.purchase) {
        return {
            ...SUB_SECTION_CONFIG,
            ...PURCHASE_SUB_SECTION_CONFIG,
            ...APPEND_SUB_SECTION_CONFIG,
        };
    } else if (transactionType === TRANSACTION_TYPE.refinance) {
        return {
            ...SUB_SECTION_CONFIG,
            ...REFIANCE_SUB_SECTION_CONFIG,
            ...APPEND_SUB_SECTION_CONFIG,
        };
    }
}

const ClosingSectionGrid = ({ order }) => {
    const transactionSubSectionConfig = generateSubSectionConfig(order.transaction_type);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {_.map(transactionSubSectionConfig, (subSectionConfig, subSectionType) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} key={subSectionType}>
                            <SubSectionPaper
                                order={order}
                                sectionType={sectionType}
                                subSectionConfig={subSectionConfig}
                                subSectionType={subSectionType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

const ClosingSectionAccordion = ({ order }) => {
    const [expanded, setExpanded] = useState(_.keys(SUB_SECTION_CONFIG));

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded && !expanded.includes(panel)) {
            setExpanded([...expanded, panel]);
        } else if (!isExpanded && expanded.includes(panel)) {
            const expandedCopy = [...expanded];
            expandedCopy.splice(expandedCopy.indexOf(panel), 1);
            setExpanded(expandedCopy);
        }
    };

    const transactionSubSectionConfig = generateSubSectionConfig(order.transaction_type);

    return (
        <AccordionBox>
            {_.map(transactionSubSectionConfig, (subSectionConfig, subSectionType) => {
                return (
                    <SubSectionAccordion
                        key={subSectionType}
                        order={order}
                        expanded={expanded.includes(subSectionType) ? subSectionType : null}
                        handleChange={handleChange}
                        sectionType={sectionType}
                        subSectionConfig={subSectionConfig}
                        subSectionType={subSectionType}
                    />
                );
            })}
        </AccordionBox>
    );
};

const ClosingSection = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    // TODO dynamically 'build' the closing section grid based on transaction type

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h4" fontWeight={500}>
                    Closing
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
            </Stack>

            {isExtraSmallScreen ? <ClosingSectionAccordion order={order} /> : <ClosingSectionGrid order={order} />}
        </Stack>
    );
};

ClosingSection.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default ClosingSection;
