import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderDocumentsByType } from '../../../../api/document';
import Config from '../../../../config';
import { DOCUMENT_TYPE, PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType, formatDocumentType } from '../../../../helpers/utils';
import { Button } from '../../../common/styled';
import { calculateReceivedDate, calculateRequiredCompleteDate } from './util';

export const PRIMARY_DOCUMENT_TYPE = DOCUMENT_TYPE.EM_OF_check; // TODO should probably move these into earnest money const file
export const PRIMARY_DOCUMENT_NAME = formatDocumentType(PRIMARY_DOCUMENT_TYPE);

const EarnestMoneyPrimaryDocumentDownload = ({ document }) => {
    if (!document) {
        return null;
    }

    const viewUrl = `${Config.api_base}/order/${document.order_id}/document/${document.id}/file`;

    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
                color="primary"
                variant="contained"
                aria-label="download"
                component="a"
                href={viewUrl}
                target="_blank"
                disableElevation
                sx={{
                    minWidth: '160px',
                }}
            >
                Download
            </Button>
        </Stack>
    );
};

const EarnestMoneyPrimaryDocument = ({ order }) => {
    const {
        data: primaryDocuments,
        isError: primaryDocumentsError,
        isLoading: primaryDocumentsLoading,
    } = useGetOrderDocumentsByType({
        orderId: order.id,
        documentTypeList: [PRIMARY_DOCUMENT_TYPE],
    });
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);
    const dueDate = calculateRequiredCompleteDate(order, parcel); // TODO should probably move these functions into earnest money utils file
    const receivedDate = calculateReceivedDate(order, parcel);
    const formattedDueDate = dueDate ? format(dueDate, 'MMM d, yyyy') : 'TBD';
    const formattedReceivedDate = receivedDate ? format(receivedDate, 'MMM d, yyyy') : 'TBD';

    // TODO handle errors or eat exception?
    // TODO use recieved_date as confirmation that document has been provided even when user doesn't have access to it?

    // NOTE: if there are multiple documents of the same type, we will need to handle that
    // In some cases that is valid and in other cases we might want to only display one.
    // EX: separate check for EM and OF
    const primaryDocument = _.get(primaryDocuments, '0', null);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Received Date:</Typography>
                <Typography color="text.primary">{formattedReceivedDate}</Typography>
            </Stack>

            <Typography variant="body2" color="text.primary">
                <b>Earnest Money</b> and <b>Option Fee</b> checks are required to be submitted from the buyer to Hudly
                Title by <b>{formattedDueDate}</b> as specified in the contract.
                <br />
                <br />
                These checks should be made payable to <b>Hudly Title</b> and can be submitted in person or by mail.
            </Typography>

            <EarnestMoneyPrimaryDocumentDownload document={primaryDocument} />
        </Stack>
    );
};

EarnestMoneyPrimaryDocument.propTypes = {
    order: PropTypes.object.isRequired,
};

export default EarnestMoneyPrimaryDocument;
