import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_TYPE, TRANSACTION_PARTY_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType, formatAddress } from '../../../../helpers/utils';
import useOrderMemberData from '../../useOrderMemberData';
import useClosingParcelData from './useClosingParcelData';
import { isSchedulingComplete, isSigningComplete } from './util';

const ClosingPartySigningStatus = ({ order, closingParcel }) => {
    const { schedulingParcel, signingParcel } = useClosingParcelData({ order, parcel: closingParcel });

    const schedulingComplete = isSchedulingComplete(schedulingParcel);
    const signingComplete = schedulingComplete && isSigningComplete(signingParcel);

    const formattedSchedulingStatus = schedulingComplete ? 'Scheduled' : 'Not Scheduled';
    const formattedSigningStatus = signingComplete ? 'Complete' : formattedSchedulingStatus;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Signing:</Typography>
            <Typography color="text.primary">{formattedSigningStatus}</Typography>
        </Stack>
    );
};

ClosingPartySigningStatus.propTypes = {
    order: PropTypes.object.isRequired,
    closingParcel: PropTypes.object.isRequired,
};

const ClosingPartySigningMethod = ({ closingParcel }) => {
    const signingMethod = _.get(closingParcel, 'additional_data.signing_method');
    if (!signingMethod) {
        return null;
    }

    const formattedSigningMethod = _.startCase(signingMethod);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <AssignmentOutlinedIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize="small" />
            <Typography color="text.primary">{formattedSigningMethod}</Typography>
        </Stack>
    );
};

ClosingPartySigningMethod.propTypes = {
    closingParcel: PropTypes.object.isRequired,
};

const ClosingPartySigningTime = ({ closingParcel }) => {
    const signingTime = _.get(closingParcel, 'additional_data.signing_time');
    if (!signingTime) {
        return null;
    }

    const formattedSigningTime = signingTime ? format(parseISO(signingTime), 'MMM d, yyyy @ p') : null;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <ScheduleIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize="small" />
            <Typography color="text.primary">{formattedSigningTime}</Typography>
        </Stack>
    );
};

ClosingPartySigningTime.propTypes = {
    closingParcel: PropTypes.object.isRequired,
};

const ClosingPartySigningLocation = ({ closingParcel }) => {
    // TODO determine if user can view the location details
    const signingLocation = _.get(closingParcel, 'additional_data.signing_location');
    if (!signingLocation) {
        return null;
    }

    const lineBreak = true;
    const formattedSigningLocation = formatAddress(signingLocation, lineBreak);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <LocationOnOutlinedIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize="small" />
            <Typography
                color="text.primary"
                sx={{
                    whiteSpace: 'pre-line',
                    textAlign: 'right',
                }}
            >
                {formattedSigningLocation}
            </Typography>
        </Stack>
    );
};

ClosingPartySigningLocation.propTypes = {
    closingParcel: PropTypes.object.isRequired,
};

const ClosingPartySigning = ({ order, closingParcel }) => {
    const { userOrderMember } = useOrderMemberData(order.id);

    const showLocation = _.get(userOrderMember, 'party') === _.get(closingParcel, 'additional_data.signing_party_type');

    return (
        <Stack direction="column" spacing={2}>
            <ClosingPartySigningStatus order={order} closingParcel={closingParcel} />

            <ClosingPartySigningMethod closingParcel={closingParcel} />

            {showLocation && <ClosingPartySigningLocation closingParcel={closingParcel} />}

            <ClosingPartySigningTime closingParcel={closingParcel} />
        </Stack>
    );
};

ClosingPartySigning.propTypes = {
    order: PropTypes.object.isRequired,
    closingParcel: PropTypes.object.isRequired,
};

const ClosingParty = ({ order, party }) => {
    const partyClosingParcelType = _.get(PARCEL_TYPE, `closing_${party}`);
    const parcel = findOrderParcelByType(order, partyClosingParcelType);

    return (
        <Stack direction="column" spacing={1}>
            {/* <Typography variant="body2" color="text.primary">
                TODO describe closing process
            </Typography> */}

            <ClosingPartySigning order={order} closingParcel={parcel} />
        </Stack>
    );
};

ClosingParty.propTypes = {
    order: PropTypes.object.isRequired,
    party: PropTypes.oneOf(_.values(TRANSACTION_PARTY_TYPE)).isRequired,
};

export default ClosingParty;
