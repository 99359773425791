import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const TitleExamInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Title Commitment</b> is a document provided by Hudly during the escrow process. It summarizes
            important information about the property's title, including the current owner, any existing liens or
            encumbrances, and the conditions that must be met for the title to be insured.
        </Typography>
    );
};

const TitleExamInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Title Commitment</b> is a document provided by Hudly during the escrow process. It summarizes
            important information about the property's title, including the current owner, any existing liens or
            encumbrances, and the conditions that must be met for the title to be insured.
            <br />
            <br />A <b>Title Commitment</b> document is crucial for both the buyer and lender to understand the status
            of the property's title and ensures that it can be transferred cleanly and free of any unexpected issues at
            closing.
        </Typography>
    );
};

TitleExamInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TitleExamInfo;
