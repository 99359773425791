import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// Sets a constrasting border color when the input is filled in but not focused
// This draws visual attention to which filters are 'active' among a total list of filters
const FilterTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFormLabel-filled ~ .MuiOutlinedInput-root:not(.Mui-focused)': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
        },
    },
}));

export default FilterTextField;
