import SearchIcon from '@mui/icons-material/Search';
import { Chip, InputAdornment, ListItemButton, ListItemText, Popper, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOrdersQuery } from '../../../api/order';
import { ORDER_FILTER_TYPE } from '../../../helpers/constants';
import { formatAddress } from '../../../helpers/utils';

const StyledPopper = styled(Popper)({});

const orderFilterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    stringify: (option) => `${option.qualia_order_number} ${formatAddress(option.property_address)}`,
});

const Search = () => {
    const navigate = useNavigate();
    const searchInputRef = useRef();

    const { data: ordersList, isError: ordersListError, isLoading: ordersListLoading } = useGetOrdersQuery();

    const handleSelect = (option) => {
        navigate(`/order/${option.id}`);
    };

    return (
        <Autocomplete
            freeSolo
            blurOnSelect
            clearOnBlur
            loading={ordersListLoading}
            options={ordersList || []}
            getOptionLabel={(option) => option.qualia_order_number}
            filterOptions={orderFilterOptions}
            PopperComponent={StyledPopper}
            onChange={(event, value, reason) => {
                if (reason === 'selectOption') {
                    handleSelect(value);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    name={ORDER_FILTER_TYPE.search}
                    placeholder="Search orders ..."
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={searchInputRef}
                    sx={{
                        width: '400px',
                        padding: (theme) => theme.spacing(1),
                        background: (theme) => theme.palette.background.default,
                        border: 0,
                        borderRadius: (theme) => theme.spacing(3),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <ListItemButton key={option.id} {...props}>
                    <ListItemText
                        primary={option.qualia_order_number}
                        secondary={formatAddress(option.property_address)}
                    />
                    <Chip label={_.startCase(option.status)} size="small" />
                </ListItemButton>
            )}
            sx={{
                '& .MuiAutocomplete-popper': {
                    marginTop: (theme) => theme.spacing(1),
                },
            }}
        />
    );
};

export default Search;
