import { Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useGetOrderQuery } from '../../api/order';
import { formatAddress, formatISODate } from '../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';
import { LoadingScreen } from '../common/LoadingWidget';
import OrderCalendar from './OrderCalendar';
import OrderChat from './OrderChat';
import OrderContacts from './OrderContacts';
import OrderDashboard from './OrderDashboard';
import OrderDocuments from './OrderDocuments';
import OrderHeader from './OrderHeader';
import OrderSection from './section/OrderSection';

const OrderTransition = ({ children }) => {
    return (
        <motion.div
            // initial={{
            //     x: '100%',
            //     opacity: 0,
            // }}
            // animate={{
            //     x: 0,
            //     opacity: 1,
            //     transition: {
            //         duration: 0.1,
            //         delay: 0.1,
            //     },
            // }}
            // exit={{
            //     x: '-100%',
            //     opacity: 0,
            //     transition: {
            //         duration: 0.1,
            //     },
            // }}
            style={{
                display: 'flex',
                flex: 1,
            }}
        >
            {children}
        </motion.div>
    );
};

const Order = ({ orderId }) => {
    const location = useLocation();

    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    // TODO build out skeleton load screens for header and each order sub-page
    if (orderLoading) {
        return <LoadingScreen />;
    }

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    return (
        <Stack direction="column" spacing={0} sx={{ flex: 1, overflowX: 'hidden' }}>
            <OrderHeader
                orderId={orderId}
                primary={formatAddress(order?.property_address, true)}
                secondary={`Closing: ${formatISODate(order?.close_date, 'MMMM dd')}`}
            />

            <Stack
                sx={{
                    minHeight: 'calc(100vh - 129px)', // 128 + 1px buffer so that the header isn't auto fixed
                    backgroundColor: 'rgba(0,0,0,.15)',
                    // backgroundColor: (theme) => theme.palette.background.lightGray,
                }}
            >
                <AnimatePresence mode="sync">
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path=""
                            exact
                            element={
                                <OrderTransition>
                                    <OrderDashboard orderId={orderId} />
                                </OrderTransition>
                            }
                        />

                        <Route
                            path="/contacts"
                            element={
                                <OrderTransition>
                                    <OrderContacts orderId={orderId} />
                                </OrderTransition>
                            }
                        />

                        <Route
                            path="/documents"
                            element={
                                <OrderTransition>
                                    <OrderDocuments orderId={orderId} />
                                </OrderTransition>
                            }
                        />

                        <Route
                            path="/calendar"
                            element={
                                <OrderTransition>
                                    <OrderCalendar orderId={orderId} />
                                </OrderTransition>
                            }
                        />

                        <Route
                            path="/chat"
                            element={
                                <OrderTransition>
                                    <OrderChat orderId={orderId} />
                                </OrderTransition>
                            }
                        />

                        <Route
                            path="/section/*"
                            element={
                                <OrderTransition>
                                    <OrderSection orderId={orderId} />
                                </OrderTransition>
                            }
                        />
                    </Routes>
                </AnimatePresence>
            </Stack>
        </Stack>
    );
};

Order.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Order;
