import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetOrderQuery } from '../../api/order';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

const OrderChat = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.lightGray,
                flex: 1,
                // borderTopLeftRadius: (theme) => theme.spacing(2),
                // borderTopRightRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 2,
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ zIndex: 1 }}>
                    <Typography variant="h4" fontWeight={500}>
                        Chat
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton color="default">
                        <AddIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
};

OrderChat.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderChat;
