import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagIcon from '@mui/icons-material/Flag';
import HandshakeIcon from '@mui/icons-material/Handshake';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Timeline, TimelineConnector, TimelineDot, TimelineSeparator } from '@mui/lab';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    IconButton,
    ListItemText,
    Slide,
    Stack,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
    addBusinessDays,
    addDays,
    differenceInCalendarDays,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfMonth,
    format,
    formatISO,
    getDate,
    isWeekend,
    parseISO,
} from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { useGetOrderQuery } from '../../api/order';
import { ENTITY_TYPE, PARCEL_TYPE } from '../../helpers/constants';
import { formatISODate } from '../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

/*
    Important dates
    - Order
        * contract start date
        * close date
        * disbursement date
    - Parcel
        - Earnest money (purchase only)
            * em & op due
            * option fee period
        - HOA
            * hoa period
            * dues period
        - Title Exam
            * title commitment date (date sent out to all parties)
            * objection period (last date that parties have to object to title commitment)
            * title period
        - Survey
            * survey period
            * objection period
        - Closing
            * signing date/time & location for each party
        - Keys (purchase only)
            * buyer transfer date/time & location
            * seller transfer date/time & location
        

    ex scheduled item:
    {
        date: '2021-10-01',
        entity: 'order',
        type: 'contract_start_date',
        title: 'Contract Date',
        datetime: '2021-10-01T00:00:00.000Z', // optional
        component: 'OrderDayTimeline', // optional
    },
    {
        date: '2021-10-01',
        entity: 'order',
        type: 'contract_start_date',
        title: 'Contract Date',
        datetime: '2021-10-01T00:00:00.000Z', // optional
        component: 'OrderDayTimeline', // optional
    },
*/

const VIEW_MODE = {
    timeline: 'timeline',
    calendar: 'calendar',
};

const IMPORTANT_ORDER_EVENTS = {
    contract_start_date: {
        primary: 'Contract Date',
        content: 'The date the contract was signed by all parties.',
        icon: <FlagIcon fontSize="small" />,
    },
    disbursement_date: {
        primary: 'Disbursement Date',
        content: 'The date the managed funds are disbursed to the parties.',
        icon: <CurrencyExchangeIcon fontSize="small" />,
    },
    close_date: {
        primary: 'Close Date',
        content: 'The date the transaction is closed.', // info changes based on order type
        icon: <HandshakeIcon fontSize="small" />,
    },
};

const IMPORTANT_PARCEL_EVENTS = {
    [PARCEL_TYPE.earnest_money]: {
        // option fee period start & end date
        required_complete_date: {
            primary: 'Earnest Money & Option Fee',
            secondary: 'Due Date',
            content:
                'The last day that the buyer can submit their earnest money and option fee to the title company without being in breach of contract.',
        },
        option_period: {
            primary: 'Option Fee Period',
            secondary: 'End Date',
            dateGenerator: (order, parcel) => {
                const optionFeePeriod = _.get(parcel, 'additional_data.option_period');
                if (!optionFeePeriod) {
                    return null;
                }

                const openDate = parseISO(order.contract_start_date);
                const optionFeePeriodEnd = formatISO(addBusinessDays(openDate, optionFeePeriod), {
                    representation: 'date',
                });

                return optionFeePeriodEnd;
            },
            content: 'The last date that the buyer can exit the contract and still have their earnest money returned.',
        },
    },
    [PARCEL_TYPE.title_exam]: {
        title_commitment_date: {
            primary: 'Title Commitment',
            secondary: 'Sent Date',
            path: 'additional_data.title_commitment_date',
            content: 'The date the title commitment was sent to all parties.',
        },
        objection_date: {
            primary: 'Title Objection Period',
            secondary: 'End Date',
            path: 'additional_data.objection_date',
            content: 'The last date that a party can object to the title commitment.',
        },
    },
    [PARCEL_TYPE.title_clearing]: {},
    [PARCEL_TYPE.hoa]: {},
    [PARCEL_TYPE.survey]: {},
};

const generateParcelImportantEvents = (order) => {
    const importantEvents = [];

    _.forEach(order.parcels, (parcel) => {
        const parcelEvents = IMPORTANT_PARCEL_EVENTS[parcel.type];
        if (!parcelEvents) {
            return;
        }

        _.forEach(parcelEvents, (importantEvent, type) => {
            // const path = _.get(importantEvent, 'path', type);
            // const date = _.get(order, path);
            const generator = _.get(importantEvent, 'dateGenerator', (order, parcel) => {
                const path = _.get(importantEvent, 'path', type);
                return _.get(parcel, path);
            });

            const date = generator(order, parcel);

            if (date) {
                importantEvents.push({
                    date,
                    entity: ENTITY_TYPE.parcel,
                    type,
                    ...importantEvent,
                });
            }
        });
    });

    return importantEvents;
};

const generateOrderImportantEvents = (order) => {
    const importantEvents = [];

    _.forEach(IMPORTANT_ORDER_EVENTS, (importantEvent, type) => {
        const path = _.get(importantEvent, 'path', type);
        const date = _.get(order, path);
        if (date) {
            importantEvents.push({
                date,
                entity: ENTITY_TYPE.order,
                type,
                ...importantEvent,
            });
        }
    });

    return importantEvents;
};

const generateAllImportantEvents = (order) => {
    return [...generateOrderImportantEvents(order), ...generateParcelImportantEvents(order)];
};

const OrderTimelineDayEvent = ({ importantEvent, handleChange, expanded, isBefore, isAfter, isNow }) => {
    return (
        <Accordion expanded={expanded} onChange={handleChange(importantEvent.type)} variant="outlined">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${importantEvent.type}-content`}
                id={`${importantEvent.type}-header`}
                sx={{ alignItems: 'center' }}
            >
                {importantEvent.icon && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mr: 2,
                            color: (theme) => (isAfter ? theme.palette.text.secondary : theme.palette.text.primary),
                        }}
                    >
                        {importantEvent.icon}
                    </Box>
                )}
                <ListItemText
                    primary={importantEvent.primary}
                    secondary={importantEvent.secondary}
                    primaryTypographyProps={
                        {
                            // sx: {
                            //     textDecoration: isBefore ? 'line-through' : 'none',
                            // },
                        }
                    }
                />
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{importantEvent.content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};

const OrderTimelineDayContent = ({ importantDate, importantDateEvents, isAfter }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box
            sx={{
                '& .MuiAccordion-root:first-of-type': {
                    borderTopLeftRadius: (theme) => theme.spacing(2),
                    borderTopRightRadius: (theme) => theme.spacing(2),
                },

                '& .MuiAccordion-root:last-of-type': {
                    borderBottomLeftRadius: (theme) => theme.spacing(2),
                    borderBottomRightRadius: (theme) => theme.spacing(2),
                },

                '& .MuiAccordion-root:not(:first-of-type):not(.Mui-expanded)': {
                    marginTop: '-1px', // overlays border of previous accordion
                },
            }}
        >
            <Stack direction="column" sx={{ mb: 2 }}>
                <Typography
                    color="text.secondary"
                    fontSize={12}
                    fontWeight={600}
                    lineHeight="24px"
                    textTransform="uppercase"
                    sx={{
                        mb: 2,
                    }}
                >
                    {formatISODate(importantDate, 'EEE, MMM d')}
                </Typography>

                {_.map(importantDateEvents, (importantEvent) => (
                    <OrderTimelineDayEvent
                        key={importantEvent.type}
                        importantEvent={importantEvent}
                        handleChange={() => handleChange(importantEvent.type)}
                        expanded={expanded === importantEvent.type}
                        isAfter={isAfter}
                    />
                ))}
            </Stack>
        </Box>
    );
};

const OrderTimelineDay = ({ importantDate, importantDateEvents, isLast = false }) => {
    // outline if after today
    // dot if before today
    // something else if today
    const importantDateObj = parseISO(importantDate);

    // positive is after now, negative is before now
    const now = new Date();
    const daysAfterNow = differenceInCalendarDays(importantDateObj, now);
    const isBefore = daysAfterNow < 0;
    const isAfter = daysAfterNow > 0;
    const isNow = daysAfterNow === 0;

    return (
        <TimelineItem key={importantDate}>
            {false && (
                <TimelineOppositeContent color="text.secondary">
                    <Box sx={{ textAlign: 'center', mt: -1 }}>{formatISODate(importantDate, 'd \nMMM')}</Box>
                </TimelineOppositeContent>
            )}

            <TimelineSeparator>
                <TimelineDot
                    variant={isAfter ? 'outlined' : 'filled'}
                    color={isAfter ? 'secondary' : 'primary'}
                    sx={{ boxShadow: 'none' }}
                />
                {!isLast && (
                    <TimelineConnector
                        sx={{
                            backgroundColor: (theme) =>
                                isAfter ? theme.palette.secondary.main : theme.palette.secondary.main,
                        }}
                    />
                )}
            </TimelineSeparator>
            <TimelineContent>
                <OrderTimelineDayContent
                    importantDate={importantDate}
                    importantDateEvents={importantDateEvents}
                    isAfter={isAfter}
                />
            </TimelineContent>
        </TimelineItem>
    );
};

const OrderTimeline = ({ importantDates }) => {
    const importantDateKeys = _.keys(importantDates);
    const importantDateCount = importantDateKeys.length;

    return (
        <Timeline
            sx={{
                p: 0,
                mt: 0,
                ml: 0,
                mr: 0,
                [`& .${timelineOppositeContentClasses.root}`]: {
                    // textWrap: 'nowrap',
                    // flexShrink: 1,
                    flex: 0.1,
                },
                [`& .${timelineContentClasses.root}`]: {
                    pr: 0,
                },
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
        >
            {_.map(importantDateKeys, (importantDate, index) => {
                const importantDateEvents = importantDates[importantDate];
                const isLast = index + 1 === importantDateCount;

                return (
                    <OrderTimelineDay
                        key={importantDate}
                        importantDate={importantDate}
                        importantDateEvents={importantDateEvents}
                        isLast={isLast}
                    />
                );
            })}
        </Timeline>
    );
};

const OrderCalendarDay = ({ date, importantEvents }) => {
    const weekend = isWeekend(date);

    // positive is after now, negative is before now
    const now = new Date();
    const daysAfterNow = differenceInCalendarDays(date, now);
    const isBefore = daysAfterNow < 0;
    const isAfter = daysAfterNow > 0;
    const isNow = daysAfterNow === 0;

    return (
        <Grid
            flex={1}
            sx={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...(weekend && {
                    color: (theme) => theme.palette.text.disabled,
                }),
            }}
        >
            <Badge
                color="primary"
                overlap="circular"
                badgeContent={_.find(importantEvents, 'icon')?.icon}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiBadge-badge': {
                        '&:has(> .MuiSvgIcon-root)': {
                            padding: 0,
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '14px',
                        },
                    },
                }}
                invisible={!importantEvents}
            >
                <Box
                    sx={{
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',

                        ...(importantEvents && {
                            backgroundColor: (theme) =>
                                isBefore ? theme.palette.secondary.main : theme.palette.background.lightGray,
                            color: (theme) =>
                                isBefore ? theme.palette.secondary.contrastText : theme.palette.text.primary,
                            border: (theme) => (isBefore ? 'none' : `1px solid ${theme.palette.border}`),
                        }),

                        ...(isNow && {
                            // backgroundColor: (theme) => theme.palette.primary.main,
                            // color: (theme) => theme.palette.primary.contrastText,
                            // border: 'none',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            outline: (theme) => `1px solid ${theme.palette.primary.main}`,
                        }),
                    }}
                >
                    <span>{getDate(date)}</span>
                </Box>
            </Badge>
        </Grid>
    );
};

const OrderCalendarBufferDay = () => <Grid flex={1} sx={{ textAlign: 'center' }} />;

const OrderCalendarWeek = ({ weekIndex, weekInterval, firstDayOfMonth, lastDayOfMonth, importantDates }) => {
    const firstDayOfWeek = weekInterval[weekIndex];

    return (
        <Box>
            <Grid container direction="row" spacing={1}>
                {_.map(_.range(7), (dayIndex) => {
                    const date = dayIndex === 0 ? firstDayOfWeek : addDays(firstDayOfWeek, dayIndex);
                    const dateString = formatISO(date, { representation: 'date' });
                    const importantDateEvents = importantDates[dateString];

                    if (weekIndex === 0 && date < firstDayOfMonth) {
                        // buffer days before first day of month
                        return <OrderCalendarBufferDay key={dateString} />;
                    }

                    if (weekIndex + 1 === weekInterval.length && date > lastDayOfMonth) {
                        // buffer days after last day of month
                        return <OrderCalendarBufferDay key={dateString} />;
                    }

                    return <OrderCalendarDay key={dateString} date={date} importantEvents={importantDateEvents} />;
                })}
            </Grid>
        </Box>
    );
};

const OrderCalendarWeekHeader = () => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return (
        <Box>
            <Grid container direction="row" spacing={1}>
                {_.map(daysOfWeek, (dayOfWeek) => {
                    return (
                        <Grid key={dayOfWeek} flex={1} sx={{ textAlign: 'center' }}>
                            {dayOfWeek[0]}
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

const OrderCalendarMonth = ({ monthIndex, monthInterval, importantDates }) => {
    const firstDayOfMonth = monthInterval[monthIndex];
    const lastDayOfMonth = endOfMonth(firstDayOfMonth);
    const weekInterval = eachWeekOfInterval({
        start: firstDayOfMonth,
        end: lastDayOfMonth,
    });

    return (
        <Box>
            {_.map(weekInterval, (firstDayOfWeek, weekIndex) => {
                const dateString = formatISO(firstDayOfWeek, { representation: 'date' });
                return (
                    <OrderCalendarWeek
                        key={dateString}
                        weekIndex={weekIndex}
                        weekInterval={weekInterval}
                        firstDayOfMonth={firstDayOfMonth}
                        lastDayOfMonth={lastDayOfMonth}
                        importantDates={importantDates}
                    />
                );
            })}
        </Box>
    );
};

const ForwardRefBox = forwardRef((props, ref) => {
    return <Box ref={ref} {...props} />;
});

const OrderCalendarContent = ({ importantDates, setCurrentMonth }) => {
    const [monthIndexHistory, setMonthIndexHistory] = useState({
        previous: null,
        current: 0,
    });

    const containerRef = useRef(null);

    const handlers = useSwipeable({
        onSwipedRight: () => handlePrevMonth(), // Left -> Right
        onSwipedLeft: () => handleNextMonth(), // Right -> Left
        swipeDuration: 500,
        preventScrollOnSwipe: true,
    });

    // Pull first important date to get 'beginning' of order
    // TODO memoize
    const importantDateKeys = _.keys(importantDates);
    const firstImportantDate = importantDateKeys[0];
    const lastImportantDate = importantDateKeys[importantDateKeys.length - 1];

    const currentMonthIndex = monthIndexHistory.current;
    const previousMonthIndex = monthIndexHistory.previous;

    const setCurrentMonthIndex = (newMonthIndex) => {
        setMonthIndexHistory({
            previous: currentMonthIndex,
            current: newMonthIndex,
        });
    };

    // Calculate all months between first and last important date
    const monthInterval = eachMonthOfInterval({
        start: parseISO(firstImportantDate),
        end: parseISO(lastImportantDate),
    });

    const handlePrevMonth = () => {
        if (currentMonthIndex !== 0) {
            setCurrentMonthIndex(currentMonthIndex - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonthIndex < monthInterval.length - 1) {
            setCurrentMonthIndex(currentMonthIndex + 1);
        }
    };

    const currentMonth = monthInterval[currentMonthIndex];

    useEffect(() => {
        const currentMonth = monthInterval[monthIndexHistory.current];
        const currentMonthStr = format(currentMonth, 'yyyy-MM');
        setCurrentMonth(currentMonthStr);
    }, [monthInterval, monthIndexHistory.current]);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                background: (theme) => theme.palette.white,
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
                border: (theme) => `1px solid ${theme.palette.border}`,
                overflow: 'hidden',
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h5" fontWeight={500}>
                    {format(currentMonth, 'MMMM yyyy')}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => handlePrevMonth()} disabled={currentMonthIndex === 0}>
                        <NavigateBeforeIcon />
                    </IconButton>

                    <IconButton
                        onClick={() => handleNextMonth()}
                        disabled={currentMonthIndex === monthInterval.length - 1}
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Stack direction="column" spacing={2} {...handlers}>
                <OrderCalendarWeekHeader />

                <Box ref={containerRef} sx={{ position: 'relative' }}>
                    {_.map(monthInterval, (monthStart, monthIndex) => {
                        if (monthIndex !== currentMonthIndex && monthIndex !== previousMonthIndex) {
                            return false;
                        }

                        const checked = monthIndex === currentMonthIndex;
                        const isPrevious = monthIndex === previousMonthIndex;
                        const isCurrent = monthIndex === currentMonthIndex;

                        const direction = isPrevious
                            ? previousMonthIndex > currentMonthIndex
                                ? 'left'
                                : 'right'
                            : monthIndex > previousMonthIndex
                            ? 'left'
                            : 'right';

                        return (
                            <Slide
                                key={monthIndex}
                                in={checked}
                                direction={direction}
                                container={containerRef?.current}
                                appear={previousMonthIndex === null ? false : true}
                                // unmountOnExit
                            >
                                <ForwardRefBox
                                    sx={{
                                        ...(isPrevious && { position: 'absolute', top: 0 }),
                                    }}
                                >
                                    <OrderCalendarMonth
                                        monthIndex={monthIndex}
                                        monthInterval={monthInterval}
                                        importantDates={importantDates}
                                    />
                                </ForwardRefBox>
                            </Slide>
                        );
                    })}
                </Box>
            </Stack>
        </Stack>
    );
};

const OrderCalendarContainer = ({ importantDates }) => {
    const ref = useRef(null);
    const [selectedDates, setSelectedDates] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);

    const handleCurrentMonth = (monthStr) => {
        if (currentMonth !== monthStr) {
            setCurrentMonth(monthStr);

            // Determine which importants dates to show for the current month
            const currentMonthEventMap = {};
            _.forEach(importantDates, (importantDateEvents, importantDate) => {
                if (importantDate.startsWith(monthStr)) {
                    currentMonthEventMap[importantDate] = importantDateEvents;
                }
            });

            setSelectedDates(currentMonthEventMap);
        }
    };

    const handleSelectedDates = (dates) => {
        setSelectedDates(dates);

        if (!!dates) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Stack direction="column" spacing={2}>
            <OrderCalendarContent importantDates={importantDates} setCurrentMonth={handleCurrentMonth} />

            <Box ref={ref} sx={{ minHeight: '320px' }}>
                {selectedDates && <OrderTimeline importantDates={selectedDates} />}
            </Box>
        </Stack>
    );
};

const OrderCalendar = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const [viewMode, setViewMode] = useState(VIEW_MODE.calendar);

    // All important events as unsorted array of objects
    const importantEvents = useMemo(() => generateAllImportantEvents(order), [order]);

    // All importants events broken out by date
    const sortedImportantDates = useMemo(() => {
        const importantDates = {};

        // Pre-sort important events so that dictionary keys are added in order
        const sortedImportantEvents = _.sortBy(importantEvents, ['date']);
        _.forEach(sortedImportantEvents, (importantEvent) => {
            const importantDate = importantEvent.date;
            if (!importantDates[importantDate]) {
                // TODO eventually we will have to sort by time within a day as well
                importantDates[importantDate] = [];
            }

            importantDates[importantDate].push(importantEvent);
        });

        return importantDates;
    }, [importantEvents]);

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.lightGray,
                flex: 1,
                // borderTopLeftRadius: (theme) => theme.spacing(2),
                // borderTopRightRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    p: 2,
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ zIndex: 1 }}>
                    <Typography variant="h4" fontWeight={500}>
                        {_.startCase(viewMode)}
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton
                        onClick={() => setViewMode(VIEW_MODE.timeline)}
                        sx={{
                            color: (theme) =>
                                viewMode === VIEW_MODE.timeline
                                    ? theme.palette.text.primary
                                    : theme.palette.text.secondary,
                        }}
                    >
                        <TimelineIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => setViewMode(VIEW_MODE.calendar)}
                        sx={{
                            color: (theme) =>
                                viewMode === VIEW_MODE.calendar
                                    ? theme.palette.text.primary
                                    : theme.palette.text.secondary,
                        }}
                    >
                        <CalendarMonthIcon />
                    </IconButton>
                </Stack>
                {viewMode === VIEW_MODE.timeline && <OrderTimeline importantDates={sortedImportantDates} />}
                {viewMode === VIEW_MODE.calendar && <OrderCalendarContainer importantDates={sortedImportantDates} />}
            </Stack>
        </Box>
    );
};

OrderCalendar.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderCalendar;
