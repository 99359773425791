import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import Config from '../config';
// import { getCookieValue } from '../helpers/cookie';

const baseQuery = fetchBaseQuery({ 
    baseUrl: `${Config.api_base}/`,
    credentials: "include", // Ensure that session cookies are sent with requests
});

const fetchBaseQueryWithAuthRedirect = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && (result.meta.response.status === 403 || result.meta.response.status === 503)) {

        if (api.endpoint !== 'getAccount') {
            // Clear all local state which will trigger a redirect to the login page
            const { dispatch } = api;
            dispatch(rootApi.util.resetApiState());
        }
    }

    return result;
};

// Initialize an empty root api service that we'll inject endpoints into later as needed
const rootApi = createApi({
    baseQuery: fetchBaseQueryWithAuthRedirect,
    endpoints: () => ({}),
})

export default rootApi;