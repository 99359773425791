import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ENTITY_TYPE, ORDER_ROLE_TYPE, PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType, isEntityStatusTerminal } from '../../../../helpers/utils';
import useOrderMemberData from '../../useOrderMemberData';

function getFundingActionByType(parcel, actionType) {
    // returns action object or null;
    // NOTE: funding parcel is unique in that actions are not duplicated on multiple run-throughs
    return _.find(Object.values(parcel.action_map), { type: actionType });
}

function isActionComplete(action) {
    return isEntityStatusTerminal(ENTITY_TYPE.action, _.get(action, 'status'));
}

const FundingActionLenderFunds = ({ fundingParcel }) => {
    const lenderFundsAction = getFundingActionByType(fundingParcel, 'receive_lender_funds');
    const isComplete = isActionComplete(lenderFundsAction);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Lender Funds:</Typography>
            {isComplete ? <DoneIcon color="primary" /> : <AccessTimeIcon color="default" />}
        </Stack>
    );
};

const FundingActionBuyerFunds = ({ fundingParcel }) => {
    const buyerFundsAction = getFundingActionByType(fundingParcel, 'receive_buyer_funds');
    const isComplete = isActionComplete(buyerFundsAction);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Buyer Funds:</Typography>
            {isComplete ? <DoneIcon color="primary" /> : <AccessTimeIcon color="default" />}
        </Stack>
    );
};

const FundingActionFundingApproval = ({ fundingParcel }) => {
    const fundingApprovalAction = getFundingActionByType(fundingParcel, 'funding_approval');
    const isComplete = isActionComplete(fundingApprovalAction);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Funding Approval:</Typography>
            {isComplete ? <DoneIcon color="primary" /> : <AccessTimeIcon color="default" />}
        </Stack>
    );
};

const FundingActionWiresSent = ({ fundingParcel }) => {
    const wiresSentAction = getFundingActionByType(fundingParcel, 'approve_wires');
    const isComplete = isActionComplete(wiresSentAction);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Wires Sent:</Typography>
            {isComplete ? <DoneIcon color="primary" /> : <AccessTimeIcon color="default" />}
        </Stack>
    );
};

const ClosingFunding = ({ order, party }) => {
    const { orderMembers } = useOrderMemberData(order.id);
    // Get the funding parcel
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.funding);

    const hasLenderRole = !!_.find(orderMembers, { role: ORDER_ROLE_TYPE.lender });

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="body2" color="text.primary">
                TODO describe funding process
            </Typography>

            <Box />

            {hasLenderRole && <FundingActionLenderFunds fundingParcel={parcel} />}

            <FundingActionBuyerFunds fundingParcel={parcel} />

            {hasLenderRole && <FundingActionFundingApproval fundingParcel={parcel} />}

            <FundingActionWiresSent fundingParcel={parcel} />
        </Stack>
    );
};

ClosingFunding.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingFunding;
