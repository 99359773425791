import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const EarnestMoneyInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            <b>Earnest Money</b> and <b>Option Fee</b> are two distinct financial commitments made by the potential
            buyer in the initial stages of the escrow process.
        </Typography>
    );
};

const EarnestMoneyInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            <b>Earnest Money</b> and <b>Option Fee</b> are two distinct financial commitments made by the potential
            buyer in the initial stages of the escrow process.
            <br />
            <br />
            The <b>Earnest Money</b> is a deposit from the buyer to demonstrate their intent to purchase the property.
            This deposit is held in an escrow account until the transaction is completed.
            <br />
            <br />
            The <b>Option Fee</b> is a payment from the buyer to the seller in exchange for a window of time called the{' '}
            <b>Option Period</b> during which the buyer can inspect the property, secure financing, and decide whether
            to proceed with the purchase. During the <b>Option Period</b>, the buyer has the right to terminate the
            contract for any reason without losing the <b>Earnest Money</b>.
        </Typography>
    );
};

EarnestMoneyInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default EarnestMoneyInfo;
