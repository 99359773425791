import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import rootApi from './root';

export const ORDER_TAG = _.toUpper(ENTITY_TYPE.order);
export const ORDER_MEMBER_TAG = _.toUpper(ENTITY_TYPE.order_member);
export const ORDER_ACTION_TAG = _.toUpper(ENTITY_TYPE.action);
export const ORDER_MEMBER_ASSOCIATION_TAG = _.toUpper(`${ENTITY_TYPE.order_member}_association`);

export const orderApi = rootApi.injectEndpoints({
    reducerPath: 'orderApi',
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: () => 'orders',
            providesTags: [ORDER_TAG],
        }),
        getOrderMembers: builder.query({
            query: (orderId) => `order/${orderId}/members`,
            providesTags: [ORDER_MEMBER_TAG],
        }),
        getOrderActions: builder.query({
            query: (orderId) => `order/${orderId}/actions`,
            providesTags: [ORDER_ACTION_TAG],
        }),
        updateOrderAction: builder.mutation({
            query: ({ orderId, actionId, actionData }) => ({
                url: `order/${orderId}/action/${actionId}`,
                method: 'PUT',
                body: actionData,
            }),
            invalidatesTags: [ORDER_TAG, ORDER_MEMBER_TAG, ORDER_ACTION_TAG, ORDER_MEMBER_ASSOCIATION_TAG],
        }),
        getOrderMemberAssociations: builder.query({
            query: (orderId) => `order/${orderId}/associations`,
            providesTags: [ORDER_MEMBER_ASSOCIATION_TAG],
        }),
    }),
    overrideExisting: false,
})


export function useGetOrderQuery(orderId) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading, ...rest }) => ({
            data: data?.find((order) => order.id === orderId),
            error,
            isLoading,
            ...rest
        }),
    });
}

export function useGetOrderByQualiaNumberQuery(qualiaOrderNumber) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        selectFromResult: ({ data, error, isLoading, ...rest }) => ({
            data: data?.find((order) => order.qualia_order_number === qualiaOrderNumber),
            error,
            isLoading,
            ...rest
        }),
    });
}

export function useGetOrdersBySearchQuery(searchTerm) {
    const { useGetOrdersQuery } = orderApi;
    return useGetOrdersQuery(undefined, {
        skip: searchTerm.length < 3,
        selectFromResult: ({ data, error, isLoading, ...rest }) => ({
            data: data?.filter((order) => {
                // TODO add more aspects to search such as address
                return _.includes(_.lowerCase(`${order.qualia_order_number}`), _.lowerCase(searchTerm))
            }),
            error,
            isLoading,
            ...rest
        }),
    });
}

export const { useGetOrdersQuery, useGetOrderMembersQuery, useGetOrderActionsQuery, useUpdateOrderActionMutation, useGetOrderMemberAssociationsQuery } = orderApi