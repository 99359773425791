import { Box, Paper, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { useGetOrderActionsQuery } from 'api/order';
import useDeviceDetails, { SCREEN_SIZE } from 'components/common/hooks/useDeviceDetails';
import { Button } from 'components/common/styled';

import OrderActionDialog from './OrderActionDialog';
import OrderActionDrawer from './OrderActionDrawer';


const OrderActionCarouselItem = ({ active, actionPrompt, setViewAction, carouselRef, setActiveIndex, index, isLast = false }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { root: carouselRef, amount: 0.75 })
    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const { title, description, button, action } = actionPrompt;

    const isFirst = index === 0;

    useEffect(() => {
        if (isInView && !active) {
            setActiveIndex();
        }
    }, [isInView, active])

    const isOnly = isFirst && isLast;
    const width = isExtraSmallScreen && !isOnly ? 'calc(100vw - 80px)' : '100%';
    
    return (
        <motion.div
            initial={{ 
                scaleY: 0.9
            }}
            whileInView={{ 
                scaleY: 1
            }}
            viewport={{
                root: carouselRef,
                amount: 0.75,
                once: false,
            }}
            style={{
                transition: 'transform 0.1s ease',
            }}
        >
            <Paper
                ref={ref}
                variant="outlined"
                sx={{
                    p: 2,
                    borderRadius: (theme) => theme.spacing(2),
                    backgroundColor: (theme) => theme.palette.secondary.main,

                    ml: 0,
                    mr: 0,
                    width,
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        minHeight: '160px',
                        maxHeight: '160px',
                    }}
                >
                    <Typography variant="h4">{title}</Typography>
                    <Typography variant="body">{description}</Typography>
                    <Box sx={{ flexGrow: 1, m: '0 !important' }} />
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setViewAction(action)}
                        sx={{
                            background: 'linear-gradient(163deg, #5FCFB5 0%, #05644E 50%)',
                        }}
                    >
                        {button}
                    </Button>
                </Stack>
            </Paper>
        </motion.div>
    );
};

OrderActionCarouselItem.propTypes = {
    active: PropTypes.bool,
    actionPrompt: PropTypes.object.isRequired,
    setViewAction: PropTypes.func.isRequired,
    setActiveIndex: PropTypes.func.isRequired,
    carouselRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
    index: PropTypes.number.isRequired, 
    isLast: PropTypes.bool
};


const OrderActionCarousel = ({ orderId }) => {
    const carouselRef = useRef(null);
    const deviceDetails = useDeviceDetails();

    const { data: orderActions, isError: orderActionsError, isLoading: orderActionsLoading } = useGetOrderActionsQuery(
        orderId
    );

    const [activeIndex, setActiveIndex] = useState(0);
    const [viewAction, setViewAction] = useState(null);

    const actionPrompts = _.map(orderActions, (action) => {
        return {
            title: action.friendly_name,
            description: action.description,
            button: action.button || 'Verify', // TODO
            action: action,
        };
    });

    // const actionPrompts = [
    //     {
    //         title: 'Verify Order',
    //         description: 'Please verify the order details before proceeding.',
    //         button: 'Verify',
    //         action: {},
    //     },
    //     {
    //         title: 'Verify Profile',
    //         description: 'Please verify the profile details before proceeding.',
    //         button: 'Verify',
    //         action: {},
    //     },
    //     {
    //         title: 'Verify Marital Status',
    //         description: 'Please verify the marital status before proceeding.',
    //         button: 'Verify',
    //         action: {},
    //     },
    // ]

    const scrollToIndex = (newIndex) => {
        const childNode = carouselRef.current.children[newIndex];
        childNode.scrollIntoView({
            behavior: 'smooth',
            // block: 'center',
            block: 'nearest',
            inline: 'center',
        });
    }

    if (actionPrompts.length === 0) {
        return false;
    }

    const first = activeIndex === 0;
    const last = activeIndex === actionPrompts.length - 1;
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;
    const OrderActionComp = isExtraSmallScreen ? OrderActionDrawer : OrderActionDialog;

    return (
        <Stack
            alignItems="center"
            spacing={2}
            sx={{
                marginLeft: (theme) => `-${theme.spacing(2)} !important`,
                marginRight: (theme) => `-${theme.spacing(2)} !important`,
                marginTop: (theme) => `-${theme.spacing(9)} !important`,    // TODO move to wrapping container
            }}
        >

            <AnimatePresence initial={true} mode="sync">
                <Box sx={{position: 'relative'}}>
                    <Box
                        onClick={() => scrollToIndex(activeIndex - 1)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '40px',
                            height: '100%',
                            display: first ? 'none' : 'block',
                            zIndex: 1,
                        }}
                    />

                    <Stack 
                        ref={carouselRef}
                        direction="row" 
                        alignItems="center" 
                        spacing={2}
                        sx={{
                            width: '100vw',
                            overflowX: 'scroll',
                            WebkitOverflowScrolling: 'touch',
                            scrollSnapType: 'x mandatory',
                            // scrollSnapPointsX: 'repeat(300px)',
                            position: 'relative',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },

                            '& > *': {
                                scrollSnapStop: 'normal',
                                scrollSnapAlign: 'center',
                                position: 'relative',

                                '&:first-child': {
                                    ml: 2
                                },
                                '&:last-child': {
                                    mr: 2,
                                },
                                '&:only-child': {
                                    ml: 2,
                                    mr: 2,
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        {_.map(actionPrompts, (actionPrompt, index) => {
                            const active = activeIndex === index;
                            return (
                                <OrderActionCarouselItem
                                    key={index}
                                    carouselRef={carouselRef}
                                    setActiveIndex={() => setActiveIndex(index)}
                                    active={active}
                                    actionPrompt={actionPrompt}
                                    setViewAction={setViewAction}
                                    index={index}
                                    isLast={index === actionPrompts.length - 1}
                                />
                            );
                        })}
                    </Stack>

                    <Box
                        onClick={() => scrollToIndex(activeIndex + 1)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '40px',
                            height: '100%',
                            display: last ? 'none' : 'block',
                            zIndex: 1,
                        }}
                    />
                </Box>
            </AnimatePresence>

            <AnimatePresence initial={true} mode="sync">
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={1}
                    sx={{
                        display: !isExtraSmallScreen || actionPrompts.length === 1 ? 'none' : 'flex',
                    }}
                >
                    {_.map(actionPrompts, (actionPrompt, index) => {
                        const isActiveIndex = index === activeIndex;
                        const key = `${index}-indicator`;
                        return (
                            <Box 
                                component={motion.div}
                                key={key}
                                variants={{
                                    'active': {
                                        width: '48px',
                                    },
                                    'inactive': {
                                        width: '24px',
                                    }
                                }}
                                animate={isActiveIndex ? 'active' : 'inactive'}
                                sx={{
                                    cursor: 'pointer',
                                    borderRadius: (theme) => theme.spacing(4),
                                    height: (theme) => theme.spacing(1),
                                    transition: 'background-color 0.1s ease, width 0.1s ease',
                                    backgroundColor: isActiveIndex ? 'primary.main' : 'secondary.main',
                                }} 
                                onClick={() => scrollToIndex(index)}
                            />
                        )
                    })}
                </Stack>
            </AnimatePresence>

            <OrderActionComp action={viewAction} orderId={orderId} handleClose={() => setViewAction(null)} />
        </Stack>
    );
};

OrderActionCarousel.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderActionCarousel;
