import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { USDollar, findOrderParcelByType } from '../../../../helpers/utils';

const OptionPeriodFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);
    const placeholderText = 'TBD';

    const optionFeeAmount = _.get(parcel, 'additional_data.option_fee_amount');

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Option Fee:</Typography>
                <Typography color="text.primary">
                    {optionFeeAmount ? USDollar.format(optionFeeAmount) : placeholderText}
                </Typography>
            </Stack>
        </Stack>
    );
};

OptionPeriodFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OptionPeriodFees;
