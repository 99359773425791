import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Checkbox, Drawer, IconButton, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ORDER_FILTER_TYPE, ORDER_STATUS, TRANSACTION_TYPE } from '../../helpers/constants';
import FilterTextField from '../common/FilterTextField';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';
import useFilterQueryParam from '../common/hooks/useFilterQueryParam';
import { HeaderToolbar } from '../layout/Topbar';

const DEFAULT_STACK_PROPS = {
    direction: 'row',
    alignItems: 'center',
    spacing: 2,
};

export function OrderListFilterFields({ filters, handleFilter, stackProps = {}, hideFilters = [] }) {
    return (
        <Stack
            {...{
                ...DEFAULT_STACK_PROPS,
                ...stackProps,
            }}
        >
            <FilterTextField
                name={ORDER_FILTER_TYPE.orderStatus}
                label="Status"
                variant="outlined"
                onChange={handleFilter}
                value={filters.orderStatus}
                size="small"
                sx={{
                    minWidth: '89px',
                    ...(_.includes(hideFilters, ORDER_FILTER_TYPE.orderStatus) && { display: 'none' }),
                }}
                select
                SelectProps={{
                    renderValue: (selected) => _.map(selected, (item) => _.startCase(item)).join(', '),
                    multiple: true,
                }}
            >
                {_.map(ORDER_STATUS, (orderStatusValue) => {
                    return (
                        <MenuItem key={orderStatusValue} value={orderStatusValue}>
                            <Checkbox checked={_.includes(filters.orderStatus, orderStatusValue)} />
                            <ListItemText primary={_.startCase(orderStatusValue)} />
                        </MenuItem>
                    );
                })}
            </FilterTextField>

            <FilterTextField
                name={ORDER_FILTER_TYPE.transactionType}
                label="Type"
                variant="outlined"
                onChange={handleFilter}
                value={filters.transactionType}
                size="small"
                sx={{
                    minWidth: '89px',
                    ...(_.includes(hideFilters, ORDER_FILTER_TYPE.transactionType) && { display: 'none' }),
                }}
                select
            >
                <MenuItem value="">All</MenuItem>
                {_.map(TRANSACTION_TYPE, (transactionValue) => {
                    return (
                        <MenuItem key={transactionValue} value={transactionValue}>
                            {_.startCase(transactionValue)}
                        </MenuItem>
                    );
                })}
            </FilterTextField>
        </Stack>
    );
}

function OrderListFilterMenu({ filters, handleFilter, hideFilters = [] }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton onClick={handleClick}>
                <FilterListIcon />
            </IconButton>

            <Menu
                id="order-list-filter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-list': {
                        p: 2,
                    },
                }}
            >
                <OrderListFilterFields
                    filters={filters}
                    handleFilter={handleFilter}
                    hideFilters={hideFilters}
                    stackProps={{
                        direction: 'column',
                        alignItems: 'stretch',
                    }}
                />
            </Menu>
        </Box>
    );
}

function OrderListFilterDrawer({ filters, handleFilter, hideFilters = [] }) {
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <IconButton onClick={handleClick}>
                <FilterListIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '240px',
                    },
                }}
                keepMounted
            >
                <HeaderToolbar />

                <Stack direction="column">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
                        <Typography fontSize={16} fontWeight={500}>
                            Filters
                        </Typography>

                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    <Box sx={{ p: 2 }}>
                        <OrderListFilterFields
                            filters={filters}
                            handleFilter={handleFilter}
                            hideFilters={hideFilters}
                            stackProps={{
                                direction: 'column',
                                alignItems: 'stretch',
                            }}
                        />
                    </Box>
                </Stack>
            </Drawer>
        </Box>
    );
}

function OrderListFilter({ filters, setFilters, resetFilters, defaultFilters, hideFilters = [] }) {
    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const [syncFilterMap, searchParams] = useFilterQueryParam(filters, defaultFilters, {
        multiArgFilterTypes: [ORDER_FILTER_TYPE.orderType, ORDER_FILTER_TYPE.orderStatus],
    });

    const handleFilter = ({ target }) => {
        const filterName = target.name;
        const filterValue = target.value;
        const filterUpdate = {
            [filterName]: filterValue || '', // Empty string isn't valid for some filters
        };

        if (filterName === ORDER_FILTER_TYPE.transactionType) {
            // Order type categories are related to transaction type
            // Reset order type category filter if transaction type is changed
            filterUpdate[ORDER_FILTER_TYPE.orderType] = defaultFilters[ORDER_FILTER_TYPE.orderType];
        }

        setFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const changeHandler = (event) => {
        handleFilter(event);
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(changeHandler, 300), []);

    useEffect(() => {
        // Stop the invocation of the debounced function after unmounting
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);

    useEffect(() => {
        if (_.keys(syncFilterMap).length > 0) {
            const updatedFilterMap = {
                ...defaultFilters,
                ...syncFilterMap,
            };

            // Update local filters
            setFilters(updatedFilterMap);
        }
    }, [syncFilterMap]);

    if (isExtraSmallScreen) {
        return <OrderListFilterDrawer filters={filters} handleFilter={handleFilter} hideFilters={hideFilters} />;
    }

    return <OrderListFilterFields filters={filters} handleFilter={handleFilter} hideFilters={hideFilters} />;
}

OrderListFilter.propTypes = {
    defaultFilters: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    hideFilters: PropTypes.array,
};

export default OrderListFilter;
