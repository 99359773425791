import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import rootApi from './root';

export const DOCUMENT_TAG = _.toUpper(ENTITY_TYPE.document);

export const documentApi = rootApi.injectEndpoints({
    reducerPath: 'documentApi',
    endpoints: (builder) => ({
        getOrderDocuments: builder.query({
            query: (orderId) => `order/${orderId}/documents`,
            providesTags: [DOCUMENT_TAG],
        }),
        // createDocument: builder.mutation({
        //     query: ({orderId, documentData}) => ({
        //         url: `order/${orderId}/documents`,
        //         method: 'POST',
        //         body: documentData,
        //         // NOTE: there is a bug atm when content type 'multipart/form-data' is directly specified
        //         // headers: {
        //         //     'Content-Type': 'multipart/form-data',
        //         // },
        //     }),
        //     invalidatesTags: [DOCUMENT_TAG],
        // }),
        // deleteDocument: builder.mutation({
        //     query: ({documentId}) => ({
        //         url: `document/${documentId}`,
        //         method: 'DELETE',
        //         headers: {
        //             'Content-type': 'application/json; charset=UTF-8',
        //         },
        //     }),
        //     invalidatesTags: [DOCUMENT_TAG],
        // }),
    }),
    overrideExisting: false,
})

export function useGetOrderDocumentsByType({orderId, documentTypeList}) {
    const { useGetOrderDocumentsQuery } = documentApi;
    return useGetOrderDocumentsQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((document) => {
                return _.includes(documentTypeList, document.type);
            }),
            error,
            isLoading
        }),
    });
}

export function useGetOrderDocumentsByParcelType({orderId, parcelTypeList}) {
    const { useGetOrderDocumentsQuery } = documentApi;
    return useGetOrderDocumentsQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((document) => {
                return _.includes(parcelTypeList, document.associated_parcel_type);
            }),
            error,
            isLoading
        }),
    });
}

export const { useGetOrderDocumentsQuery } = documentApi