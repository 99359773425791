import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { OverdueTaxes, PriorYearTaxes } from './TaxFees';
import { TaxInfoShort } from './TaxInfo';

function TaxSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.tax_cert);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <PriorYearTaxes order={order} parcel={parcel} />

                <OverdueTaxes order={order} parcel={parcel} />
            </Stack>

            <TaxInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={'taxes'} />
        </Stack>
    );
}

TaxSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TaxSnapshot;
