import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Link,
    ListItemAvatar,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import AssignmentSnapshot from 'components/Order/section/Assignment/AssignmentSnapshot';
import * as assignmentUtil from 'components/Order/section/Assignment/util';
import { ENTITY_TYPE, LIEN_STATUS, PARCEL_TYPE } from '../../helpers/constants';
import { findOrderParcelByType, isEntityStatusTerminal } from '../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';
import useOrderData from '../common/hooks/useOrderData';
import { AccordionBox } from '../common/styled';
import OrderActionCarousel from './OrderActionCarousel';
import ClosingSnapshot from './section/Closing/ClosingSnapshot';
import * as closingUtil from './section/Closing/util';
import ClosingDocumentsSnapshot from './section/ClosingDocuments/ClosingDocumentsSnapshot';
import * as closingDocumentsUtil from './section/ClosingDocuments/util';
import EarnestMoneySnapshot from './section/EarnestMoney/EarnestMoneySnapshot';
import * as earnestMoneyUtil from './section/EarnestMoney/util';
import HoaSnapshot from './section/HOA/HoaSnapshot';
import * as hoaUtil from './section/HOA/util';
import OptionPeriodSnapshot from './section/OptionPeriod/OptionPeriodSnapshot';
import * as optionPeriodUtil from './section/OptionPeriod/util';
import SurveySnapshot from './section/Survey/SurveySnapshot';
import * as surveyUtil from './section/Survey/util';
import TaxSnapshot from './section/Tax/TaxSnapshot';
import * as taxUtil from './section/Tax/util';
import TitleCommitmentSnapshot from './section/TitleCommitment/TitleCommitmentSnapshot';
import * as titleCommitmentUtil from './section/TitleCommitment/util';

export const DASHBOARD_SNAPSHOT_TYPE = {
    assignment: 'assignment',
    earnest_money: 'earnest_money',
    option_period: 'option_period',
    hoa: 'hoa',
    taxes: 'taxes',
    survey: 'survey',
    title_commitment: 'title_commitment',
    closing_documents: 'closing_documents',
    closing: 'closing',
};

const DASHBOARD_SNAPSHOT_TYPE_ORDER = _.keys(DASHBOARD_SNAPSHOT_TYPE);

export const ORDER_DASHBOARD_SNAPSHOT_MAP = {
    [DASHBOARD_SNAPSHOT_TYPE.assignment]: {
        title: 'Assignment',
        Component: AssignmentSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.assignment,
        parcelType: PARCEL_TYPE.assignment_contract,
        calculateComplete: assignmentUtil.calculateComplete,
        calculateCompleteDate: assignmentUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: assignmentUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.earnest_money]: {
        title: 'Earnest Money',
        Component: EarnestMoneySnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.earnest_money,
        parcelType: PARCEL_TYPE.earnest_money,
        calculateComplete: earnestMoneyUtil.calculateComplete,
        calculateCompleteDate: earnestMoneyUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: earnestMoneyUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.option_period]: {
        title: 'Option Period',
        Component: OptionPeriodSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.option_period,
        parcelType: PARCEL_TYPE.earnest_money,
        calculateComplete: optionPeriodUtil.calculateComplete,
        calculateCompleteDate: optionPeriodUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: optionPeriodUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.title_commitment]: {
        title: 'Title Commitment',
        Component: TitleCommitmentSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.title_commitment,
        parcelType: PARCEL_TYPE.title_exam,
        calculateComplete: titleCommitmentUtil.calculateComplete,
        calculateCompleteDate: titleCommitmentUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: titleCommitmentUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.taxes]: {
        title: 'Real Estate Taxes',
        Component: TaxSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.taxes,
        parcelType: PARCEL_TYPE.tax_cert,
        calculateComplete: taxUtil.calculateComplete,
        calculateCompleteDate: taxUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: taxUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.hoa]: {
        title: 'HOA',
        Component: HoaSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.hoa,
        parcelType: PARCEL_TYPE.hoa,
        calculateComplete: hoaUtil.calculateComplete,
        calculateCompleteDate: hoaUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: hoaUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.survey]: {
        title: 'Survey',
        Component: SurveySnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.survey,
        parcelType: PARCEL_TYPE.survey,
        calculateComplete: surveyUtil.calculateComplete,
        calculateCompleteDate: surveyUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: surveyUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.closing_documents]: {
        title: 'Closing Documents',
        Component: ClosingDocumentsSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.closing_documents,
        parcelType: PARCEL_TYPE.balancing,
        calculateComplete: closingDocumentsUtil.calculateComplete,
        calculateCompleteDate: closingDocumentsUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: closingDocumentsUtil.calculateRequiredCompleteDate,
    },
    [DASHBOARD_SNAPSHOT_TYPE.closing]: {
        title: 'Closing',
        Component: ClosingSnapshot,
        type: DASHBOARD_SNAPSHOT_TYPE.closing,
        parcelType: PARCEL_TYPE.closing,
        calculateComplete: closingUtil.calculateComplete,
        calculateCompleteDate: closingUtil.calculateCompleteDate,
        calculateRequiredCompleteDate: closingUtil.calculateRequiredCompleteDate,
    },
};

const OrderDashboardCompletedPanel = ({ order, completedSections }) => {
    const [expanded, setExpanded] = useState(false);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!order || completedSections.length === 0) {
        return false;
    }

    if (!isExtraSmallScreen) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
                    Completed
                </Typography>
                <Grid container spacing={2}>
                    {_.map(completedSections, (completedSection) => {
                        const SnapshotComponent = completedSection.snapshot.Component;

                        return (
                            <Grid item xs={12} md={6} lg={4} key={completedSection.snapshot.type}>
                                <Paper
                                    variant="outlined"
                                    sx={{ p: 2, height: '100%', borderRadius: (theme) => theme.spacing(2) }}
                                >
                                    <Stack spacing={2}>
                                        <Stack direction="row" alignItems="center">
                                            <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
                                                <DoneIcon color="primary" />
                                            </ListItemAvatar>
                                            <ListItemText primary={completedSection.snapshot.title} />
                                        </Stack>

                                        <SnapshotComponent order={order} />
                                    </Stack>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    return (
        <AccordionBox>
            <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
                Completed
            </Typography>

            {_.map(completedSections, (completedSection) => {
                const SnapshotComponent = completedSection.snapshot.Component;

                return (
                    <Accordion
                        key={completedSection.snapshot.type}
                        expanded={expanded === completedSection.snapshot.type}
                        onChange={handleChange(completedSection.snapshot.type)}
                        variant="outlined"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${completedSection.snapshot.type}-content`}
                            id={`${completedSection.snapshot.type}-header`}
                        >
                            <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
                                <DoneIcon color="primary" />
                            </ListItemAvatar>
                            <ListItemText primary={completedSection.snapshot.title} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <SnapshotComponent order={order} />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </AccordionBox>
    );
};

const OrderDashboardUpcomingPanel = ({ order, upcomingSections }) => {
    const [expanded, setExpanded] = useState(false);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!order || upcomingSections.length === 0) {
        return false;
    }

    if (!isExtraSmallScreen) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
                    Upcoming
                </Typography>
                <Grid container spacing={2}>
                    {_.map(upcomingSections, (upcomingSection) => {
                        const currentDate = new Date();
                        const requiredCompleteDate = upcomingSection.date;
                        const daysDiff = requiredCompleteDate
                            ? differenceInDays(requiredCompleteDate, currentDate)
                            : null;

                        const SnapshotComponent = upcomingSection.snapshot.Component;

                        return (
                            <Grid item xs={12} md={6} lg={4} key={upcomingSection.snapshot.type}>
                                <Paper
                                    variant="outlined"
                                    sx={{ p: 2, height: '100%', borderRadius: (theme) => theme.spacing(2) }}
                                >
                                    <Stack spacing={2}>
                                        <Stack direction="row" alignItems="center">
                                            <ListItemAvatar sx={{ display: 'flex' }}>
                                                {daysDiff !== null ? (
                                                    <Stack direction="column" alignItems="center" spacing={0}>
                                                        <Typography
                                                            fontSize={12}
                                                            fontWeight={600}
                                                            sx={{ lineHeight: (theme) => theme.spacing(2) }}
                                                        >
                                                            {daysDiff}
                                                        </Typography>

                                                        <Typography
                                                            fontSize={10}
                                                            fontWeight={400}
                                                            textTransform="uppercase"
                                                            color="text.secondary"
                                                            sx={{ lineHeight: (theme) => theme.spacing(2) }}
                                                        >
                                                            Days
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={0}
                                                        sx={{ minWidth: '26px' }}
                                                    >
                                                        <AccessTimeIcon
                                                            sx={{ color: (theme) => theme.palette.text.secondary }}
                                                        />
                                                    </Stack>
                                                )}

                                                <Box sx={{ flexGrow: 1 }} />
                                            </ListItemAvatar>
                                            <ListItemText primary={upcomingSection.snapshot.title} />
                                        </Stack>

                                        <SnapshotComponent order={order} />
                                    </Stack>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }

    return (
        <AccordionBox>
            <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
                Upcoming
            </Typography>

            {_.map(upcomingSections, (upcomingSection) => {
                const currentDate = new Date();
                const requiredCompleteDate = upcomingSection.date;
                const daysDiff = requiredCompleteDate ? differenceInDays(requiredCompleteDate, currentDate) : null;

                const SnapshotComponent = upcomingSection.snapshot.Component;

                return (
                    <Accordion
                        key={upcomingSection.snapshot.type}
                        expanded={expanded === upcomingSection.snapshot.type}
                        onChange={handleChange(upcomingSection.snapshot.type)}
                        variant="outlined"
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${upcomingSection.snapshot.type}-content`}
                            id={`${upcomingSection.snapshot.type}-header`}
                            sx={{ alignItems: 'center' }}
                        >
                            <ListItemAvatar sx={{ display: 'flex' }}>
                                {daysDiff !== null ? (
                                    <Stack direction="column" alignItems="center" spacing={0}>
                                        <Typography
                                            fontSize={12}
                                            fontWeight={600}
                                            sx={{ lineHeight: (theme) => theme.spacing(2) }}
                                        >
                                            {daysDiff}
                                        </Typography>

                                        <Typography
                                            fontSize={10}
                                            fontWeight={400}
                                            textTransform="uppercase"
                                            color="text.secondary"
                                            sx={{ lineHeight: (theme) => theme.spacing(2) }}
                                        >
                                            Days
                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Stack direction="row" alignItems="center" spacing={0} sx={{ minWidth: '26px' }}>
                                        <AccessTimeIcon sx={{ color: (theme) => theme.palette.text.secondary }} />
                                    </Stack>
                                )}

                                <Box sx={{ flexGrow: 1 }} />
                            </ListItemAvatar>
                            <ListItemText primary={upcomingSection.snapshot.title} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <SnapshotComponent order={order} />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </AccordionBox>
    );
};

const OrderDashboardTitleItem = ({ order, lien, payoff, expanded, handleChange, daysUntilDue }) => {
    const isComplete = isEntityStatusTerminal(ENTITY_TYPE.lien, lien.status);
    const secondaryText = lien.status === LIEN_STATUS.payoff ? 'Payoff Ready' : _.startCase(lien.status);

    return (
        <Accordion key={lien.id} expanded={expanded === lien.id} onChange={handleChange(lien.id)} variant="outlined">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${lien.id}-content`}
                id={`${lien.id}-header`}
            >
                <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
                    {isComplete && <DoneIcon color="primary" />}
                    {!isComplete && (
                        <AccessTimeIcon
                            sx={{
                                color: (theme) =>
                                    daysUntilDue > 7
                                        ? '#797979'
                                        : daysUntilDue < 3
                                          ? theme.palette.error.main
                                          : theme.palette.warning.dark,
                            }}
                        />
                    )}
                </ListItemAvatar>
                <ListItemText primary={_.startCase(lien.type)} secondary={secondaryText} />
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="column" spacing={4}>
                    <Typography>
                        Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est,
                        id dignissim quam.
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Link
                            component={NavLink}
                            to={`/escrow/${order.id}/lien/${lien.id}`}
                            underline="hover"
                            textTransform="uppercase"
                            fontWeight={500}
                        >
                            Learn More
                        </Link>
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

const OrderDashboardTitlePanel = ({ order }) => {
    const [expanded, setExpanded] = useState(false);

    const titleClearingParcel = useMemo(() => {
        return findOrderParcelByType(order, PARCEL_TYPE.title_clearing);
    }, [order]);

    const sortedLiens = useMemo(() => {
        return _.sortBy(order.liens, ['created_datetime']);
    }, [order.liens]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const lienPayoffMap = useMemo(() => {
        const payoffMap = {};
        _.forEach(order.payoffs, (payoff) => {
            payoffMap[payoff.lien_id] = payoff;
        });
        return payoffMap;
    }, [order]);

    if (!order || sortedLiens.length === 0) {
        return false;
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(titleClearingParcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    return (
        <AccordionBox>
            <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
                Title
            </Typography>

            {_.map(sortedLiens, (lien) => {
                return (
                    <OrderDashboardTitleItem
                        key={lien.id}
                        order={order}
                        lien={lien}
                        payoff={lienPayoffMap[lien.id]}
                        expanded={expanded}
                        handleChange={handleChange}
                        daysUntilDue={daysUntilDue}
                    />
                );
            })}
        </AccordionBox>
    );
};

const OrderDashboard = ({ orderId }) => {
    const { order, orderMembers, actions, associations, assignor, assignorParcel } = useOrderData(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;
    const useContainerOffset = _.get(actions, 'length', 0) > 0;

    const orderSectionMap = useMemo(() => {
        const upcomingSections = [];
        const completedSections = [];

        _.forEach(ORDER_DASHBOARD_SNAPSHOT_MAP, (snapshot) => {
            let parcel;
            if (snapshot.type === DASHBOARD_SNAPSHOT_TYPE.assignment) {
                parcel = assignorParcel;
            } else {
                parcel = findOrderParcelByType(order, snapshot.parcelType);
            }
            if (!parcel) {
                return;
            }

            const sectionComplete = snapshot.calculateComplete(order, parcel);

            if (sectionComplete) {
                completedSections.push({
                    snapshot,
                    parcel,
                    date: snapshot.calculateCompleteDate(order, parcel),
                });
            } else {
                upcomingSections.push({
                    snapshot,
                    parcel,
                    date: snapshot.calculateRequiredCompleteDate(order, parcel),
                });
            }
        });

        // Sort completed & upcoming sections by DASHBOARD_SNAPSHOT_TYPE_ORDER
        return {
            completed: _.sortBy(completedSections, (completedSection) =>
                _.indexOf(DASHBOARD_SNAPSHOT_TYPE_ORDER, completedSection.snapshot.type)
            ),
            upcoming: _.sortBy(upcomingSections, (upcomingSection) =>
                _.indexOf(DASHBOARD_SNAPSHOT_TYPE_ORDER, upcomingSection.snapshot.type)
            ),
        };
    }, [order, assignor]);

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.lightGray,
                flex: 1,
                // borderTopLeftRadius: (theme) => theme.spacing(2),
                // borderTopRightRadius: (theme) => theme.spacing(2),
                mt: useContainerOffset ? 8 : 0,
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 2,
                }}
            >
                <OrderActionCarousel orderId={orderId} />

                <OrderDashboardCompletedPanel order={order} completedSections={orderSectionMap.completed} />

                <OrderDashboardUpcomingPanel order={order} upcomingSections={orderSectionMap.upcoming} />

                <OrderDashboardTitlePanel order={order} />
            </Stack>
        </Box>
    );
};

OrderDashboard.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderDashboard;
