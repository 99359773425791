import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import useOrderData from 'components/common/hooks/useOrderData';
import { calculateContractReceivedDate, calculateReceivedDate, calculateRequiredCompleteDate } from './util';

export const AssignmentContractReceivedDate = ({ order, parcel }) => {
    const receivedDate = calculateContractReceivedDate(order, parcel);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Contract Date:</Typography>
            <Typography color="text.primary">{receivedDate ? format(receivedDate, 'MMM d, yyyy') : 'TBD'}</Typography>
        </Stack>
    );
};

AssignmentContractReceivedDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const AssignmentDueDate = ({ order, parcel }) => {
    const dueDate = calculateRequiredCompleteDate(order, parcel);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Due Date:</Typography>
            <Typography color="text.primary">{dueDate ? format(dueDate, 'MMM d, yyyy') : 'TBD'}</Typography>
        </Stack>
    );
};

AssignmentDueDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const AssignmentReceivedDate = ({ order, parcel }) => {
    const receivedDate = calculateReceivedDate(order, parcel);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Received Date:</Typography>
            <Typography color="text.primary">{receivedDate ? format(receivedDate, 'MMM d, yyyy') : 'TBD'}</Typography>
        </Stack>
    );
};

AssignmentReceivedDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const AssignmentDates = ({ order }) => {
    const { assignorParcel: parcel } = useOrderData(order.id);

    return (
        <Stack direction="column" spacing={1}>
            <AssignmentContractReceivedDate order={order} parcel={parcel} />

            <AssignmentDueDate order={order} parcel={parcel} />

            <AssignmentReceivedDate order={order} parcel={parcel} />
        </Stack>
    );
};

AssignmentDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default AssignmentDates;
