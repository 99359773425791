import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Sidebar, { SIDEBAR_WIDTH } from './Sidebar';
import Topbar, { HeaderToolbar } from './Topbar';

import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

// NOTE: transiton matches the defaults for the drawer component
const MainContentBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})(({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Main = (props) => {
    const { children } = props;

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const deviceDetails = useDeviceDetails();
    const sidebarVariant = deviceDetails.size === SCREEN_SIZE.large ? 'persistent' : 'temporary';
    const sidebarWidthBuffer = sidebarOpen && sidebarVariant === 'persistent' ? SIDEBAR_WIDTH : 0;
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    useEffect(() => {
        // If device size changes from large to medium or small (or is initially set), close sidebar
        if (deviceDetails.size !== SCREEN_SIZE.large) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    }, [deviceDetails.size]);

    return (
        <Stack
            sx={{
                height: '100%',
            }}
        >
            <Topbar
                sidebarOpen={sidebarOpen}
                toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                showSidebarToggle={deviceDetails.size !== SCREEN_SIZE.large}
            />

            <Sidebar onClose={() => setSidebarOpen(false)} variant={sidebarVariant} open={sidebarOpen} />

            <MainContentBox component="main" open={sidebarOpen} drawerWidth={sidebarWidthBuffer}>
                <Stack
                    direction="column"
                    justifyContent="stretch"
                    sx={{
                        minHeight: '100vh',
                        height: '100%',
                        maxHeight: '-webkit-fill-available',
                        // overflow: 'hidden',
                    }}
                >
                    <HeaderToolbar />

                    <Box
                        sx={{
                            padding: (theme) => (isExtraSmallScreen ? '0' : theme.spacing(3)),
                            borderTopLeftRadius: (theme) => (sidebarWidthBuffer > 0 ? theme.spacing(1) : 0),
                            backgroundColor: (theme) => theme.palette.background.lightGray,
                            ...(isExtraSmallScreen && {
                                background: 'transparent',
                            }),
                            height: '100%',
                            flex: 1,
                            // overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {children}
                    </Box>
                </Stack>
            </MainContentBox>
        </Stack>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;
