import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Breadcrumbs, Link, Stack } from '@mui/material';
import useDeviceDetails, { SCREEN_SIZE } from 'components/common/hooks/useDeviceDetails';
import _ from 'lodash';
import { NavLink, useMatch, useParams } from 'react-router-dom';
import { useGetOrderQuery } from '../../api/order';

const BASE_LINK = '/escrow';

const OrderBreadcrumbs = () => {
    let { orderId, parcelId, payoffId, lienId, documentId } = useParams();

    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const qualiaOrderNumber = _.get(order, 'qualia_order_number');

    const contactUrl = `${BASE_LINK}/${orderId}/contacts`;
    const contactMatch = useMatch(contactUrl);

    const documentUrl = `${BASE_LINK}/${orderId}/documents`;
    const documentMatch = useMatch(documentUrl);

    const calendarUrl = `${BASE_LINK}/${orderId}/calendar`;
    const calendarMatch = useMatch(calendarUrl);

    const chatUrl = `${BASE_LINK}/${orderId}/chat`;
    const chatMatch = useMatch(chatUrl);

    const sectionUrl = `${BASE_LINK}/${orderId}/section/*`;
    const sectionMatch = useMatch(sectionUrl);
    // TODO handle all uppercase names like hoa -> HOA

    // const lien = _.get(lienEntities, lienId);
    // const payoff = _.get(payoffEntities, payoffId);
    // const document = _.get(documentEntities, documentId);

    // let implicitParcelId = null;
    // if (lien || payoff) {
    //     implicitParcelId = lien ? lien.parcelId : payoff.parcelId;
    // }

    // const confirmedParcelId = parcelId || implicit_parcelId;
    // const parcelChain = getParcelChain(order, confirmedParcelId);

    // TODO make generic and step out of order only context
    // TODO define forking paths for order:
    // 1. escrow
    // 2. escrow -> {orderId}
    // 3. escrow -> {orderId} -> documents
    // 4. escrow -> {orderId} -> documents -> {documentId}
    // 5. escrow -> {orderId} -> {parcelId}
    // 6. escrow -> {orderId} -> parcel -> {payoffId}
    // 7. escrow -> {orderId} -> contacts

    // color white for current
    // color gray green for visited

    return (
        <Breadcrumbs
            className={isExtraSmallScreen ? 'compact' : ''}
            maxItems={isExtraSmallScreen ? 2 : 3}
            itemsBeforeCollapse={isExtraSmallScreen ? 0 : 1}
            itemsAfterCollapse={isExtraSmallScreen ? 2 : 1}
            sx={{
                display: 'flex',
                '& .MuiBreadcrumbs-separator': {
                    color: (theme) => theme.palette.secondary.main,
                },

                '& .MuiBreadcrumbs-li a': {
                    fontWeight: 500,
                    fontSize: '12px',
                },
                '& .MuiBreadcrumbs-li:last-of-type a': {
                    fontWeight: 600,
                    color: '#fff',
                },
                '& .MuiBreadcrumbs-ol': {
                    width: '100%',
                },

                '&.compact': {
                    '& .MuiBreadcrumbs-li:last-of-type a': {
                        display: 'none',
                    },
                    '& .MuiBreadcrumbs-separator': {
                        display: 'none',
                    },
                    '& li > .MuiButtonBase-root': {
                        display: 'none',
                    },
                },
            }}
        >
            <Link component={NavLink} to={`/`} underline="hover" color="secondary">
                <Stack direction="row" alignItems="center" spacing={1}>
                    {isExtraSmallScreen && <KeyboardBackspaceIcon fontSize="small" />}
                    <span>Escrow</span>
                </Stack>
            </Link>
            {orderId && (
                <Link component={NavLink} to={`${BASE_LINK}/${orderId}`} underline="hover" color="secondary">
                    {isExtraSmallScreen ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {isExtraSmallScreen && <KeyboardBackspaceIcon fontSize="small" />}
                            <span>Overview</span>
                        </Stack>
                    ) : (
                        qualiaOrderNumber
                    )}
                </Link>
            )}
            {contactMatch && (
                <Link component={NavLink} to={contactUrl} underline="hover" color="secondary">
                    Contacts
                </Link>
            )}
            {documentMatch && (
                <Link component={NavLink} to={documentUrl} underline="hover" color="secondary">
                    Documents
                </Link>
            )}
            {calendarMatch && (
                <Link component={NavLink} to={calendarUrl} underline="hover" color="secondary">
                    Calendar
                </Link>
            )}
            {chatMatch && (
                <Link component={NavLink} to={chatUrl} underline="hover" color="secondary">
                    Chat
                </Link>
            )}
            {sectionMatch && (
                <Link component={NavLink} to={sectionMatch.pathname} underline="hover" color="secondary">
                    {_.startCase(sectionMatch.params['*'])}
                </Link>
            )}
            {/* {orderId && (
                <NavLink to={`/escrow/${orderId}`} exact className={classes.rootLink}>
                    Order
                </NavLink>
            )} */}
            {/* {_.map(parcelChain, function (parcelLink) {
                return <NavLink to={`/escrow/${orderId}/parcel/${parcelLink.id}`}>{parcelLink.name}</NavLink>;
            })} */}
            {/* {payoff && <NavLink to={`/order/${orderId}/payoff/${payoff.id}`}>Payoff</NavLink>}
            {lien && <NavLink to={`/order/${orderId}/lien/${lien.id}`}>Lien</NavLink>}
            {document && <NavLink to={`/order/${orderId}/document/${document.id}`}>Document</NavLink>} */}
        </Breadcrumbs>
    );
};

OrderBreadcrumbs.propTypes = {};

export default OrderBreadcrumbs;
