import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { TRANSACTION_TYPE } from '../../../../helpers/constants';

export const HoaInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            A <b>Homeowners Association (HOA)</b> is an organization established within a residential community
            primarily to manage and enforce certain rules and regulations governing the property and the behavior of its
            residents.
        </Typography>
    );
};

const HoaInfo = ({ order }) => {
    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;
    // TODO if not required, append info about why it is not required
    return (
        <Typography variant="body2" color="text.primary">
            A <b>Homeowners Association (HOA)</b> is an organization established within a residential community
            primarily to manage and enforce certain rules and regulations governing the property and the behavior of its
            residents.
            <br />
            <br />
            {isPurchase ? (
                <>
                    If an <b>HOA</b> exists for a property being sold, an <b>HOA Transfer</b> is generally required.
                </>
            ) : (
                <>
                    If an <b>HOA</b> exists for a property being refinanced, an <b>HOA Statement of Account</b>
                    is generally required as part of the escrow verification process.
                </>
            )}
        </Typography>
    );
};

HoaInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default HoaInfo;
