
export const ENTITY_TYPE = {
    action: "action",
    parcel: "parcel",
    lien: "lien",
    payoff: "payoff",
    document: "document",
    document_access: "document_access",
    order: "order",
    order_member: "order_member",
    order_member_relationship: "order_member_relationship",
    user: "user",
    user_relationship: "user_relationship",
    legal_entity: "legal_entity",
    legal_entity_relationship: "legal_entity_relationship",
    legal_entity_member: "legal_entity_member",
    legal_entity_member_relationship: "legal_entity_member_relationship",
    assignment_member: "assignment_member",
}

export const ORDER_FILTER_TYPE = {
    search: 'search',
    transactionType: 'transactionType',
    orderType: 'orderType',
    workflowType: 'workflowType',
    orderStatus: 'orderStatus',
    closeDateStatus: 'closeDateStatus',
    closeDateGreaterThan: 'closeDateGreaterThan',
    closeDateRange: 'closeDateRange',
    disbursementDateRange: 'disbursementDateRange'
};

export const TRANSACTION_TYPE = {
    purchase: 'purchase',
    refinance: 'refinance',
};

export const ACTION_STATUS = {
    not_started: 'NotStarted',
    in_progress: 'InProgress',
    complete: 'Complete',
};


export const ORDER_STATUS = {
    pre_open: 'PreOpen',
    open: 'Open',
    on_hold: 'OnHold',
    canceled: 'Canceled',
    closed: 'Closed',
};

export const ORDER_TERMINAL_STATUSES = [
    ORDER_STATUS.canceled, 
    ORDER_STATUS.closed
]

export const META_PARCEL_TYPE = {
    opening: 'opening',
    title: 'title',
    closing: 'closing',
    balancing: 'balancing'
};

export const PARCEL_TYPE = {
    [META_PARCEL_TYPE.opening]: META_PARCEL_TYPE.opening,
    earnest_money: 'earnest_money',
    tax_cert: 'tax_cert',
    hoa: 'hoa',
    assignment: 'assignment',
    assignment_contract: 'assignment_contract',

    [META_PARCEL_TYPE.title]: META_PARCEL_TYPE.title,
    title_exam: 'title_exam',
    title_clearing: 'title_clearing',

    // META_PARCEL_TYPE.supplemental
    survey: 'survey',
    home_warranty: 'home_warranty',
    cda: 'cda',
    deed: 'deed',

    [META_PARCEL_TYPE.closing]: META_PARCEL_TYPE.closing,
    closing_buyer: 'closing_buyer',
    closing_seller: 'closing_seller',
    closing_borrower: 'closing_borrower',
    scheduling: 'scheduling',
    signing: 'signing', // TODO remove - non-existant as standalone but used for rollup
    signing_electronic_notary: 'signing_electronic_notary',
    signing_mobile_notary: 'signing_mobile_notary',
    signing_office: 'signing_office',
    signing_in_house: 'signing_in_house',

    [META_PARCEL_TYPE.balancing]: META_PARCEL_TYPE.balancing,
    balancing_contract: "balancing_contract",
    balancing_earnest_money: "balancing_earnest_money",
    balancing_taxes: "balancing_taxes",
    balancing_hoa: "balancing_hoa",
    balancing_home_warranty: "balancing_home_warranty",
    balancing_commissions: "balancing_commissions",
    balancing_deed: "balancing_deed",
    balancing_liens: "balancing_liens",
    balancing_title_fees: "balancing_title_fees",
    balancing_lender_fees: "balancing_lender_fees",
    balancing_other: "balancing_other",
    balancing_final_cd_hud: "balancing_final_cd_hud",

    funding: "funding",
    keys: "keys",
    post_closing: "post_closing",
    order_member_data_collection: "order_member_data_collection",
};

export const PARCEL_NAME_OVERRIDE = {
    [PARCEL_TYPE.hoa]: 'HOA',
    [PARCEL_TYPE.cda]: 'CDA',
};

export const PARCEL_ORDER = Object.keys(PARCEL_TYPE);

export const META_PARCEL_ORDER = Object.keys(META_PARCEL_TYPE);

export const CLOSING_PARCEL_TYPES = [
    PARCEL_TYPE.closing_buyer,
    PARCEL_TYPE.closing_seller,
    PARCEL_TYPE.closing_borrower,
];

export const SIGNING_PARCEL_TYPES = [
    PARCEL_TYPE.signing_electronic_notary,
    PARCEL_TYPE.signing_mobile_notary,
    PARCEL_TYPE.signing_office,
    PARCEL_TYPE.signing_in_house
]

export const PARCEL_STATUS = {
    on_hold: 'OnHold',
    not_started: 'NotStarted',
    in_progress: 'InProgress',
    ordered: 'Ordered',
    received: 'Received',
    // TODO need to be able to dynamically request status options for each parcel
    waiting_for_tax_cert: 'WaitingForTaxCert', // hoa parcel specific
    need_to_order: 'NeedToOrder', // hoa parcel specific
    quotes_requested: 'QuotesRequested', // survey parcel specific
    waiting_for_existing_survey: 'WaitingForExistingSurvey', // survey parcel specific
    issues_found: 'IssuesFound', // title parcel specific
    waiting_for_funding_approval: 'WaitingForFundingApproval', // funding parcel specific
    funding: 'Funding', // funding parcel specific
    waiting_for_post_funding: 'WaitingForPostFunding', // post closing parcel specific
    waiting_for_contract: "WaitingForContract", // assignment-specific
    waiting_for_earnest_money: "WaitingForEarnestMoney", // assignment-specific
    needs_receipt: "NeedsReceipt", // assignment-specific
    complete: 'Complete',
    canceled: 'Canceled',
};

export const PARCEL_TERMINAL_STATUSES = [
    PARCEL_STATUS.complete,
    PARCEL_STATUS.canceled,
];

export const LIEN_STATUS = {
    researching: "Researching",
    not_started: PARCEL_STATUS.not_started,
    in_progress: PARCEL_STATUS.in_progress,
    ordered: PARCEL_STATUS.ordered,
    in_review: "InReview",
    waiting: "WaitingForPayoff",
    closing: "Closing",
    needs_ROL: "NeedsReleaseOfLien",
    ordered_ROL: "OrderedReleaseOfLien",
    receive_ROL: "ReceivedReleaseOfLien",
    waiting_signing: "WaitingOnSigning",
    ready: "ReadyToSign",
    cleared: "Cleared",
    record: "Record",
    payoff: "Payoff",
};

export const LIEN_TERMINAL_STATUSES = [
    LIEN_STATUS.cleared,
    LIEN_STATUS.ready,
    LIEN_STATUS.record,
    LIEN_STATUS.payoff,
];

export const TRANSACTION_PARTY_TYPE = {
    borrower: "borrower",
    buyer: "buyer",
    seller: "seller",
    independent: "independent",
}

export const ORDER_ROLE_TYPE = {
    borrower: 'borrower',
    buyer: 'buyer',
    seller: 'seller',
    listing_agent: 'listing_agent',
    selling_agent: 'selling_agent',
    loan_officer: 'loan_officer',
    transaction_coordinator: "transaction_coordinator",
    escrow_officer: 'escrow_officer',
    escrow_assistant: 'escrow_assistant',
    lender: 'lender',
    assignor: 'assignor',
};

export const ORDER_INTERNAL_ROLES = [ORDER_ROLE_TYPE.escrow_officer, ORDER_ROLE_TYPE.escrow_assistant];

export const ORDER_EXTERNAL_ROLES = [
    ORDER_ROLE_TYPE.borrower,
    ORDER_ROLE_TYPE.buyer,
    ORDER_ROLE_TYPE.seller,
    ORDER_ROLE_TYPE.listing_agent,
    ORDER_ROLE_TYPE.selling_agent,
    ORDER_ROLE_TYPE.loan_officer,
    ORDER_ROLE_TYPE.transaction_coordinator,
];

export const BUYER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.buyer,
    ORDER_ROLE_TYPE.selling_agent,
    ORDER_ROLE_TYPE.lender,
    ORDER_ROLE_TYPE.assignor,
]

export const SELLER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.seller,
    ORDER_ROLE_TYPE.listing_agent,
]

export const BORROWER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.borrower,
    ORDER_ROLE_TYPE.lender,
    ORDER_ROLE_TYPE.assignor,
]

export const PARTY_ROLE_MAP = {
    [TRANSACTION_PARTY_TYPE.buyer]: BUYER_PARTY_ROLES,
    [TRANSACTION_PARTY_TYPE.seller]: SELLER_PARTY_ROLES,
    [TRANSACTION_PARTY_TYPE.borrower]: BORROWER_PARTY_ROLES,
}

export const PURCHASE_ORDER_ROLES = [
    ...BUYER_PARTY_ROLES,
    ...SELLER_PARTY_ROLES,
]

export const REFINANCE_ORDER_ROLES = [
    ...BORROWER_PARTY_ROLES,
]

export const DOCUMENT_TYPE = {
    assignment_contract: "assignment_contract",
    assignment_EM_check: "assignment_EM_check",
    assignment_contract_receipted: "assignment_contract_receipted",
    bankruptcy: "bankruptcy",
    CD_HUD: "CD_HUD",
    CDA: "CDA",
    closing_documents: "closing_documents",
    contract: "contract",
    contract_receipted: "contract_receipted",
    contract_termination: "contract_termination",
    divorce_decree: "divorce_decree",
    EM_OF_check: "EM_OF_check",
    HOA_resale_certificate: "HOA_resale_certificate",
    HOA_statement_of_account: "HOA_statement_of_account",
    HOA_supporting_documents: "HOA_supporting_documents",
    home_warranty: "home_warranty",
    invoice: "invoice",
    lender_documents: "lender_documents",
    license: "license",
    lien: "lien",
    MUD_notice: "MUD_notice",
    payoff: "payoff",
    shipping_label: "shipping_label",
    signed_documents: "signed_documents",
    survey: "survey",
    metes_and_bounds: "metes_and_bounds",
    survey_invoice: "survey_invoice",
    tax_certificate: "tax_certificate",
    tax_bill: "tax_bill",
    title_commitment: "title_commitment",
    title_documents: "title_documents",
    title_request: "title_request",
    vesting_deed: "vesting_deed",
    wire_instructions: "wire_instructions",
    other: "other",
};

export const DOCUMENT_TYPE_NAME_OVERRIDE = {
    [DOCUMENT_TYPE.assignment_contract_receipted]: "Assignment Contract (Receipted)",
    [DOCUMENT_TYPE.CD_HUD]: 'CD/HUD',
    [DOCUMENT_TYPE.CDA]: 'CDA',
    [DOCUMENT_TYPE.contract_receipted]: 'Contract (Receipted)',
    [DOCUMENT_TYPE.contract_termination]: 'Contract (Termination)',
    [DOCUMENT_TYPE.EM_OF_check]: 'EM/OF Check',
    [DOCUMENT_TYPE.HOA_resale_certificate]: 'HOA Resale Certificate',
    [DOCUMENT_TYPE.HOA_statement_of_account]: 'HOA Statement of Account',
    [DOCUMENT_TYPE.HOA_supporting_documents]: 'HOA Supporting Documents',
    [DOCUMENT_TYPE.MUD_notice]: 'MUD Notice',
    [DOCUMENT_TYPE.metes_and_bounds]: "Metes & Bounds",
}

export const MARITAL_STATUS = {
    married: "married",
    single: "single",
    widowed:"widowed",
    divorced: "divorced",
    other: "other"
}

export const GENDER = {
    male: "male",
    female: "female",
    other: "other"
}

export const US_STATES = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 
    'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];