import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { USDollar, findOrderParcelByType } from '../../../../helpers/utils';

export const PriorYearTaxes = ({ order, parcel }) => {
    const priorYearTaxes = _.get(parcel, 'additional_data.prior_year_tax_amount', null);
    const placeholderText = 'TBD';

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Prior Year Taxes:</Typography>
            <Typography color="text.primary">
                {priorYearTaxes ? USDollar.format(priorYearTaxes) : placeholderText}
            </Typography>
        </Stack>
    );
};

PriorYearTaxes.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const OverdueTaxes = ({ order, parcel }) => {
    const overdueTaxes = _.get(parcel, 'additional_data.overdue_tax_amount', null);
    const placeholderText = 'TBD';

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Overdue Taxes:</Typography>
            <Typography color="text.primary">
                {overdueTaxes ? USDollar.format(overdueTaxes) : placeholderText}
            </Typography>
        </Stack>
    );
};

const TaxFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.tax_cert);
    const placeholderText = 'TBD';

    const priorYearTaxes = _.get(parcel, 'additional_data.prior_year_tax_amount', null);
    const overdueTaxes = _.get(parcel, 'additional_data.overdue_tax_amount', null);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Tax Cert Fee:</Typography>
                    <Typography color="text.primary">$35.00</Typography>
                </Stack>

                <PriorYearTaxes order={order} parcel={parcel} />

                <OverdueTaxes order={order} parcel={parcel} />
            </Stack>
        </Stack>
    );
};

TaxFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TaxFees;
