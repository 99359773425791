import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { SurveyObjectionDate, SurveySentDate } from './SurveyDates';
import { SurveyInfoShort } from './SurveyInfo';
import SurveyNotRequired from './SurveyNotRequired';

function SurveySnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.survey);

    return (
        <Stack direction="column" spacing={4}>
            <SurveyNotRequired order={order} />

            <Stack direction="column" spacing={1}>
                <SurveySentDate order={order} parcel={parcel} />

                <SurveyObjectionDate order={order} parcel={parcel} />
            </Stack>

            <SurveyInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={PARCEL_TYPE.survey} />
        </Stack>
    );
}

SurveySnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default SurveySnapshot;
