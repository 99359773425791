import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import useDeviceDetails, { SCREEN_SIZE } from 'components/common/hooks/useDeviceDetails';
import { IMPORTANT_DASHBOARD_EVENTS, IMPORTANT_EVENT_TYPES } from 'helpers/orderEvent';
// import { PARTY_ROLE_MAP, TRANSACTION_PARTY_TYPE } from '../../../helper/constant';
import useOrderData from 'components/common/hooks/useOrderData';
import { AccordionBox } from 'components/common/styled';
import SubSectionAccordion from '../common/SubSectionAccordion';
import SubSectionPaper from '../common/SubSectionPaper';
import AssignmentDates from './AssignmentDates';
import AssignmentFees from './AssignmentFees';
import AssignmentPrimaryDocument, { PRIMARY_DOCUMENT_NAME } from './AssignmentPrimaryDocument';

/*
    Sub-sections:
    * info
    * important dates
    * fees & charges
    * primary document
        - who can see this? 
    * additional documents
        - who can see this?

*/

const sectionType = IMPORTANT_EVENT_TYPES.assignment;
const sectionEvent = IMPORTANT_DASHBOARD_EVENTS[sectionType];

const SUB_SECTION_TYPES = {
    info: 'info',
    important_dates: 'important_dates',
    fees_and_charges: 'fees_and_charges',
    primary_document: 'primary_document',
    additional_documents: 'additional_documents',
};

const SUB_SECTION_CONFIG = {
    // [SUB_SECTION_TYPES.info]: {
    //     title: 'About Earnest Money',
    //     Icon: <InfoOutlinedIcon color="default" />,
    //     renderContent: (props) => <AssignmentInfo {...props} />,
    // },
    [SUB_SECTION_TYPES.important_dates]: {
        title: 'Important Dates',
        Icon: <CalendarMonthIcon color="default" />,
        renderContent: (props) => <AssignmentDates {...props} />,
    },
    [SUB_SECTION_TYPES.fees_and_charges]: {
        title: 'Fees & Charges',
        Icon: <AttachMoneyIcon color="default" />,
        renderContent: (props) => <AssignmentFees {...props} />,
    },
    [SUB_SECTION_TYPES.primary_document]: {
        title: PRIMARY_DOCUMENT_NAME,
        Icon: <RequestPageOutlinedIcon color="default" />,
        renderContent: (props) => <AssignmentPrimaryDocument {...props} />,
    },
};

const AssignmentSectionGrid = ({ order }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} key={subSectionType}>
                            <SubSectionPaper
                                order={order}
                                sectionType={sectionType}
                                subSectionConfig={subSectionConfig}
                                subSectionType={subSectionType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

const AssignmentSectionAccordion = ({ order }) => {
    const [expanded, setExpanded] = useState(_.keys(SUB_SECTION_CONFIG));

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded && !expanded.includes(panel)) {
            setExpanded([...expanded, panel]);
        } else if (!isExpanded && expanded.includes(panel)) {
            const expandedCopy = [...expanded];
            expandedCopy.splice(expandedCopy.indexOf(panel), 1);
            setExpanded(expandedCopy);
        }
    };

    return (
        <AccordionBox>
            {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => (
                <SubSectionAccordion
                    key={subSectionType}
                    order={order}
                    expanded={expanded.includes(subSectionType) ? subSectionType : null}
                    handleChange={handleChange}
                    sectionType={sectionType}
                    subSectionConfig={subSectionConfig}
                    subSectionType={subSectionType}
                />
            ))}
        </AccordionBox>
    );
};

const AssignmentSection = ({ orderId }) => {
    const { order, orderMembers, actions, associations, assignor, assignorParcel } = useOrderData(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h4" fontWeight={500}>
                    {_.startCase(sectionType)}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
            </Stack>

            {isExtraSmallScreen ? (
                <AssignmentSectionAccordion order={order} />
            ) : (
                <AssignmentSectionGrid order={order} />
            )}
        </Stack>
    );
};

AssignmentSection.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default AssignmentSection;
