import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const SectionTooltip = styled(({ boundingElement, ...rest }) => (
    <Tooltip
        {...rest}
        enterTouchDelay={250}
        leaveTouchDelay={5000}
        arrow
        PopperProps={{
            popperOptions: {
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: boundingElement.current,
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => ({}));

export default SectionTooltip;

/*
<Tooltip
    title="Party responsible for covering the HOA transfer fees"
    placement="bottom"
    enterTouchDelay={250}
    leaveTouchDelay={5000}
    arrow
    PopperProps={{
        popperOptions: {
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: boundingElement.current,
                    },
                },
            ],
        },
    }}
>

*/
