import _ from 'lodash';
import { useMemo, useState } from 'react';

import ContactForm, { INITIAL_CONTACT_DATA, formatContactPayload } from '../../contact/ContactForm';

const generateInitialContact = (formData) => {
    if (!formData) {
        return { ...INITIAL_CONTACT_DATA };
    }

    return {
        ...INITIAL_CONTACT_DATA,
        ...formData,
    };
};

const CustomContactField = (props) => {
    // NOTE: this field wraps the standard contact form and handles interoperability with the parent rjsf form
    // Because the contact interface is fairly standardized, we do not pass in much of the schema or uiSchema
    // formData can be null or object with contact fields
    // uses props.formData and props.onChange to update the parent form's contact data
    // uses props.schema.title for the label
    // uses props.uiSchema.ui:options.required to determine if the contact fields should be required
    const [contactData, setContactData] = useState(generateInitialContact(props.formData));

    const handleUpdateFormData = (newFormData) => {
        props.onChange(formatContactPayload(newFormData));
    };

    const debouncedChangeHandler = useMemo(() => _.debounce(handleUpdateFormData, 500), []);

    const handleUpdateContact = (newContactData) => {
        setContactData(newContactData);
        debouncedChangeHandler(newContactData);
    };

    // Defaults to enforce required fields - must be explicitly set to false to disable
    const uiSchema = _.get(props, 'uiSchema', {});
    const required = _.get(uiSchema, 'ui:options.required', false);
    const label = _.get(props, 'schema.title');
    const disabled = _.get(props, 'readonly', false);
    const requiredFields = _.get(props, 'schema.required', []);
    const disabledFields = [];
    _.forEach(uiSchema, (value, key) => {
        if (_.get(value, 'ui:readonly', false)) {
            disabledFields.push(key);
        }
    });

    return (
        <ContactForm
            contactData={contactData}
            setContactData={handleUpdateContact}
            label={label} // TODO enable functionality
            showLabel={true} // TODO enable functionality
            required={required} // TODO enable functionality
            disabled={disabled} // TODO enable functionality
            requiredFields={requiredFields}
            disabledFields={disabledFields}
        />
    );
};

export default CustomContactField;
