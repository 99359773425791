import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// TODO need short and long descriptions
// one for dashboard 'snapshot' view
// one for dedicated 'section' view
export const TaxInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Tax Certificate</b> is a document that provides information about the property's current tax status.
            It typically includes details such as the property's tax identification number, the amount of property taxes
            owed, any outstanding tax liens or assessments, and the due dates for tax payments.
        </Typography>
    );
};

const TaxInfo = ({ order }) => {
    // TODO dynamic text based on transaction type
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Tax Certificate</b> is a document that provides information about the property's current tax status.
            It typically includes details such as the property's tax identification number, the amount of property taxes
            owed, any outstanding tax liens or assessments, and the due dates for tax payments.
            <br />
            <br />
            The <b>Tax Certificate</b> is essential for both the buyer and the seller to ensure that property taxes are
            properly accounted for and paid during the escrow process. It helps prevent any potential issues or disputes
            related to unpaid property taxes that could affect the transfer of ownership.
        </Typography>
    );
};

TaxInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TaxInfo;
