import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { ClosingInfoShort } from './ClosingInfo';

function ClosingSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.closing);

    return (
        <Stack direction="column" spacing={4}>
            <ClosingInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={PARCEL_TYPE.closing} />
        </Stack>
    );
}

ClosingSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingSnapshot;
