import { Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import { calculateOptionPeriod, calculateRequiredCompleteDate } from './util';

export const OptionPeriodLength = ({ order, parcel }) => {
    const optionPeriod = calculateOptionPeriod(order, parcel);

    const placeholderText = 'TBD';
    const formattedOptionPeriod = optionPeriod ? `${optionPeriod} days` : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Option Period:</Typography>
            <Typography color="text.primary">{formattedOptionPeriod}</Typography>
        </Stack>
    );
};

OptionPeriodLength.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const OptionPeriodEndDate = ({ order, parcel }) => {
    const optionFeePeriodEndDate = calculateRequiredCompleteDate(order, parcel);

    const now = new Date();
    const dayDiff = optionFeePeriodEndDate ? differenceInDays(optionFeePeriodEndDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

    const placeholderText = 'TBD';
    const formattedOptionPeriodEndDate = optionFeePeriodEndDate
        ? `${format(optionFeePeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Option Period End Date:</Typography>
            <Typography color="text.primary">{formattedOptionPeriodEndDate}</Typography>
        </Stack>
    );
};

OptionPeriodEndDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const OptionPeriodDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);

    return (
        <Stack direction="column" spacing={1}>
            <OptionPeriodLength order={order} parcel={parcel} />

            <OptionPeriodEndDate order={order} parcel={parcel} />
        </Stack>
    );
};

OptionPeriodDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OptionPeriodDates;
