import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export function generateCoreButtonStyles({ theme }) {
    return {
        borderRadius: theme.spacing(3),
        textTransform: 'none',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&.MuiButton-outlined': {
            fontWeight: 400,
        },
    };
}

// Unified styled version of core buttons
const StyledButton = styled(Button)(generateCoreButtonStyles);

export default StyledButton;
