import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';

function OrderActionDialog({ action, handleClose, orderId }) {
    return (
        <BaseDialog open={!!action} setOpen={handleClose}>
            <Stack direction="column">TODO action</Stack>
        </BaseDialog>
    );
}

OrderActionDialog.propTypes = {
    action: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string,
};

export default OrderActionDialog;
