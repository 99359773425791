import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';


export function calculateComplete(order, parcel) {
    // returns true or false
    // compare today's date to the hoa period end date

    // If survey_required is false, then this is complete
    const surveyRequired = calculateRequired(order, parcel);
    if (surveyRequired === false) {
        return true;
    }

    // Survey is required, compare today's date to the survey period end date
    const surveyPeriodEnd = calculateRequiredCompleteDate(order, parcel);
    if (!surveyPeriodEnd) {
        return false;
    }

    const today = new Date();

    return isAfter(today, surveyPeriodEnd);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    // NOTE: there is no 'action' that completes this event in advance.
    // The 'complete' date is the final date of the required_complete_date (based off survey period) if it has passed
    if (!this.calculateComplete(order, parcel)) {
        return null;
    }

    // If survey_required is false, then this event is considered 'complete' when the terminal status is reached
    const surveyRequired = calculateRequired(order, parcel)
    if (surveyRequired === false) {
        return parseISO(parcel.current_status.created_datetime);
    }

    return calculateRequiredCompleteDate(order, parcel);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj or null
    // TODO rework
    const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
    if (!requiredCompleteDate) {
        return null;
    }

    return parseISO(requiredCompleteDate);
}

export function calculateRequired(order, parcel) {
    // returns true or false
    return _.get(parcel, 'additional_data.survey_required', null);
}

export function calculateSurveyOrderedDate(order, parcel) {
    const surveyOrderedDate = _.get(parcel, 'additional_data.survey_ordered_date');
    if (!surveyOrderedDate) {
        return null;
    }

    return parseISO(surveyOrderedDate);
}

export function calculateSurveyETA(order, parcel) {
    const surveyETA = _.get(parcel, 'additional_data.survey_eta');
    if (!surveyETA) {
        return null;
    }

    return parseISO(surveyETA);
}

export function calculateSurveyReceivedDate(order, parcel) {
    const surveyReceivedDate = _.get(parcel, 'additional_data.survey_received_date');
    if (!surveyReceivedDate) {
        return null;
    }

    return parseISO(surveyReceivedDate);
}

export function calculateObjectionPeriod(order, parcel) {
    return _.get(parcel, 'additional_data.objection_period', null);
}

export function calculateObjectionDate(order, parcel) {
    const surveyReceivedDate = _.get(parcel, 'additional_data.objection_date');
    if (!surveyReceivedDate) {
        return null;
    }

    return parseISO(surveyReceivedDate);
}


