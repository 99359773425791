import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import AppLayout from './AppLayout';
import ScrollToTop from './ScrollToTop';
import { useGetAccountQuery } from './api/account';
import Dashboard from './page/Dashboard';
import LoginPage from './page/Login';
import OrderPage from './page/OrderPage';

function RequireAuth({ children }) {
    const { data: account, isLoading: loadingAccount, isError } = useGetAccountQuery();
    let location = useLocation();

    // If there is no account or there is an issue fetching the account - redirect to login page
    if (!account || isError) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return <AppLayout children={children} />;
}

function AppRouter() {
    // TODO revisit when socket is needed
    // const dispatch = useDispatch();
    // const socketStore = useSelector((store) => store.socket);
    // const { connected } = socketStore;

    // useEffect(() => {
    //     if (!connected) {
    //         dispatch(SocketAction.connectSocket());
    //     }
    // }, [connected]);

    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />

                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <Dashboard />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="/escrow/:orderId/*"
                        element={
                            <RequireAuth>
                                <OrderPage />
                            </RequireAuth>
                        }
                    />
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default AppRouter;
