import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const AccordionBox = styled(Box)(({ theme }) => ({
    '& .MuiAccordion-root:first-of-type': {
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
    },

    '& .MuiAccordion-root:last-of-type': {
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
    },

    '& .MuiAccordion-root:not(:first-of-type):not(.Mui-expanded)': {
        marginTop: '-1px', // overlays border of previous accordion
    },
}));

export default AccordionBox;
