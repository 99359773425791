import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const ParentListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.MuiListItemButton-root': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        color: theme.palette.white,

        '&.active': {
            background: 'rgba(0, 48, 37, 0.5)',
        },
    },
}));

const ChildListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.MuiListItemButton-root': {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(3),
        color: theme.palette.white,

        '&.active': {
            background: 'rgba(0, 48, 37, 0.5)',
        },
    },
}));

const ParentListItemText = styled((props) => {
    return (
        <ListItemText
            {...props}
            primaryTypographyProps={{
                fontWeight: '500',
                fontSize: '16px',
                color: (theme) => theme.palette.white,
            }}
        />
    );
})``;

const ChildListItemText = styled((props) => {
    return (
        <ListItemText
            {...props}
            primaryTypographyProps={{
                fontWeight: '400',
                fontSize: '14px',
                color: (theme) => theme.palette.white,
            }}
        />
    );
})``;

const SidebarNav = (props) => {
    const [openHudlyInfo, setOpenHudlyInfo] = useState(true);
    const [openProfile, setOpenProfile] = useState(false);
    const [openHelpfulInfo, setOpenHelpfulInfo] = useState(false);

    return (
        <Box sx={{ width: '100%', pt: 4 }}>
            <nav aria-label="main navigation">
                <List sx={{ pt: 0, pb: 0 }}>
                    <ListItem disablePadding>
                        <ParentListItemButton component={NavLink} to="/">
                            <ParentListItemText primary="Overview" />
                        </ParentListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ParentListItemButton onClick={() => setOpenHudlyInfo(!openHudlyInfo)}>
                            <ParentListItemText primary="Hudly Info" />
                            {openHudlyInfo ? <ExpandLess /> : <ExpandMore />}
                        </ParentListItemButton>
                    </ListItem>
                    <Collapse in={openHudlyInfo} timeout="auto" unmountOnExit>
                        <List component="div" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                            <ChildListItemButton>
                                <ChildListItemText primary="Wire Instructions" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Your Escrow Team" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Licensing Info" />
                            </ChildListItemButton>
                        </List>
                    </Collapse>

                    <ListItem disablePadding>
                        <ParentListItemButton onClick={() => setOpenProfile(!openProfile)}>
                            <ParentListItemText primary="My Profile" />
                            {openProfile ? <ExpandLess /> : <ExpandMore />}
                        </ParentListItemButton>
                    </ListItem>
                    <Collapse in={openProfile} timeout="auto" unmountOnExit>
                        <List component="div" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 1" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 2" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 3" />
                            </ChildListItemButton>
                        </List>
                    </Collapse>

                    <ListItem disablePadding>
                        <ParentListItemButton onClick={() => setOpenHelpfulInfo(!openHelpfulInfo)}>
                            <ParentListItemText primary="Helpful Info" />
                            {openHelpfulInfo ? <ExpandLess /> : <ExpandMore />}
                        </ParentListItemButton>
                    </ListItem>
                    <Collapse in={openHelpfulInfo} timeout="auto" unmountOnExit>
                        <List component="div" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 1" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 2" />
                            </ChildListItemButton>
                            <ChildListItemButton>
                                <ChildListItemText primary="Item 3" />
                            </ChildListItemButton>
                        </List>
                    </Collapse>
                </List>
            </nav>
        </Box>
    );
};

SidebarNav.propTypes = {};

export default SidebarNav;
