import { Collapse, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../helpers/constants';
import useDeviceDetails, { SCREEN_SIZE } from '../../common/hooks/useDeviceDetails';
import ParcelSnapshotWidget from './ParcelSnapshotWidget';

// TODO determine which parcels to show for refinance
// Currently just uses these as baseline and ParcelSnapshotWidget will handle if parcel doesn't exist
const PARCEL_SNAPSHOT_TYPES = [
    PARCEL_TYPE.earnest_money,
    PARCEL_TYPE.title_exam,
    PARCEL_TYPE.title_clearing,
    PARCEL_TYPE.hoa,
    PARCEL_TYPE.survey,
];

const OrderExpandedContent = ({ order }) => {
    const deviceDetails = useDeviceDetails();
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;

    return (
        <Collapse in={true} timeout="auto" unmountOnExit>
            <Stack direction="column">
                <Stack
                    direction={isExtraSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    spacing={isExtraSmallScreen ? 0 : 2}
                    sx={{
                        ...(isExtraSmallScreen && {
                            borderRadius: (theme) => theme.spacing(2),
                            border: (theme) => `1px solid ${theme.palette.divider}`,
                        }),
                    }}
                >
                    {_.map(PARCEL_SNAPSHOT_TYPES, (type) => (
                        <ParcelSnapshotWidget key={type} order={order} type={type} />
                    ))}
                </Stack>
            </Stack>
        </Collapse>
    );
};

OrderExpandedContent.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderExpandedContent;
