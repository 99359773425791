import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import { calculateRequired } from './util';

const SurveyNotRequired = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.survey);
    const surveyRequired = calculateRequired(order, parcel);

    if (surveyRequired !== false) {
        return null;
    }

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.secondary.main,
                padding: (theme) => theme.spacing(1, 2),
                borderLeft: (theme) => `solid 4px ${theme.palette.primary.main}`,
                borderRadius: (theme) => theme.spacing(0.5),
            }}
        >
            <Typography variant="body2" color="text.primary">
                Survey is <b>not required</b> for this property
            </Typography>
        </Box>
    );
};

SurveyNotRequired.propTypes = {
    order: PropTypes.object.isRequired,
};

export default SurveyNotRequired;
