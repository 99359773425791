import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function SnapshotLink({ orderId, sectionType }) {
    return (
        <Link
            component={NavLink}
            to={`/escrow/${orderId}/section/${sectionType}`}
            color="text.link"
            underline="hover"
            textTransform="uppercase"
            fontWeight={500}
            sx={{
                marginTop: (theme) => theme.spacing(3),
            }}
        >
            Learn More
        </Link>
    );
}

SnapshotLink.propTypes = {
    orderId: PropTypes.string.isRequired,
    sectionType: PropTypes.string.isRequired,
};

export default SnapshotLink;
