import InfoIcon from '@mui/icons-material/Info';
import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { USDollar, findOrderParcelByType } from '../../../../helpers/utils';
import SectionTooltip from '../common/SectionTooltip';
import { calculateRequired } from './util';

const HoaPurchaseFees = ({ order, parcel }) => {
    const boundingElement = useRef();
    const hoaRequired = calculateRequired(order, parcel);
    const placeholderText = hoaRequired ? 'TBD' : 'N/A';

    const dues = _.get(parcel, 'additional_data.dues', null);
    const duesPeriod = _.get(parcel, 'additional_data.dues_period', null);
    const unpaidDues = _.get(parcel, 'additional_data.unpaid_dues', null);
    const otherFees = _.get(parcel, 'additional_data.other_fees', null);

    const transferFee = _.get(parcel, 'additional_data.transfer_fee', null);
    const workingCapitalFee = _.get(parcel, 'additional_data.working_capital_fee', null);
    const resaleCertFee = _.get(parcel, 'additional_data.resale_cert_fee', null);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1}>
                <Stack direction="column" alignItems="flex-start">
                    <Typography color="text.secondary">Dues:</Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-end">
                    <Typography color="text.primary">
                        {_.isNumber(dues) ? USDollar.format(dues) : placeholderText}
                    </Typography>
                    {duesPeriod && <Typography variant="body2">{_.startCase(duesPeriod)}</Typography>}
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                    <Typography color="text.secondary">Unpaid Dues:</Typography>
                    <SectionTooltip
                        title="Outstanding HOA dues that must be paid by the seller"
                        placement="bottom"
                        boundingElement={boundingElement}
                    >
                        <InfoIcon
                            fontSize="small"
                            sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '1rem' }}
                        />
                    </SectionTooltip>
                </Stack>
                <Typography color="text.primary">
                    {_.isNumber(unpaidDues) ? USDollar.format(unpaidDues) : placeholderText}
                </Typography>
            </Stack>

            <Box />
            <Box />

            <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Resale Certificate Fee:</Typography>
                    <Typography color="text.primary">
                        {_.isNumber(resaleCertFee) ? USDollar.format(resaleCertFee) : placeholderText}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Transfer Fee:</Typography>
                    <Typography color="text.primary">
                        {_.isNumber(transferFee) ? USDollar.format(transferFee) : placeholderText}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Working Capital Fee:</Typography>
                    <Typography color="text.primary">
                        {_.isNumber(workingCapitalFee) ? USDollar.format(workingCapitalFee) : placeholderText}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Other Fees:</Typography>
                    <Typography color="text.primary">
                        {_.isNumber(otherFees) ? USDollar.format(otherFees) : placeholderText}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

const HoaRefinanceFees = ({ order, parcel }) => {
    const boundingElement = useRef();
    const hoaRequired = calculateRequired(order, parcel);
    const placeholderText = hoaRequired ? 'TBD' : 'N/A';

    const unpaidDues = _.get(parcel, 'additional_data.unpaid_dues', null);
    const otherFees = _.get(parcel, 'additional_data.other_fees', null);
    const statementOfAccountFee = _.get(parcel, 'additional_data.statement_of_account_fee', null);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                    <Typography color="text.secondary">Unpaid Dues:</Typography>
                    <SectionTooltip
                        title="Outstanding HOA dues that must be paid by the seller"
                        placement="bottom"
                        boundingElement={boundingElement}
                    >
                        <InfoIcon
                            fontSize="small"
                            sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '1rem' }}
                        />
                    </SectionTooltip>
                </Stack>
                <Typography color="text.primary">
                    {_.isNumber(unpaidDues) ? USDollar.format(unpaidDues) : placeholderText}
                </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Statement of Account Fee:</Typography>
                <Typography color="text.primary">
                    {_.isNumber(statementOfAccountFee) ? USDollar.format(statementOfAccountFee) : placeholderText}
                </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Other Fees:</Typography>
                <Typography color="text.primary">
                    {_.isNumber(otherFees) ? USDollar.format(otherFees) : placeholderText}
                </Typography>
            </Stack>
        </Stack>
    );
};

const HoaFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);
    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;

    if (isPurchase) {
        return <HoaPurchaseFees order={order} parcel={parcel} />;
    }

    return <HoaRefinanceFees order={order} parcel={parcel} />;
};

HoaFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default HoaFees;
