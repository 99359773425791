import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import { calculateReceivedDate, calculateRequiredCompleteDate } from './util';

export const EarnestMoneyDueDate = ({ order, parcel }) => {
    const dueDate = calculateRequiredCompleteDate(order, parcel);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Due Date:</Typography>
            <Typography color="text.primary">{dueDate ? format(dueDate, 'MMM d, yyyy') : 'TBD'}</Typography>
        </Stack>
    );
};

EarnestMoneyDueDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const EarnestMoneyReceivedDate = ({ order, parcel }) => {
    const receivedDate = calculateReceivedDate(order, parcel);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Received Date:</Typography>
            <Typography color="text.primary">{receivedDate ? format(receivedDate, 'MMM d, yyyy') : 'TBD'}</Typography>
        </Stack>
    );
};

EarnestMoneyReceivedDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const EarnestMoneyDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);

    return (
        <Stack direction="column" spacing={1}>
            <EarnestMoneyDueDate order={order} parcel={parcel} />

            <EarnestMoneyReceivedDate order={order} parcel={parcel} />
        </Stack>
    );
};

EarnestMoneyDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default EarnestMoneyDates;
