/*
    TODO:
    - [] Snapshot widgets
        - [] Earnest Money
        - [] Title Exam
        - [] Title Clearing
        - [] HOA
            - Not required if hoa_required is false and parcel is marked as complete
            - ETA if hoa_required is true and 
        - [] Survey

    - Determine what widgets to show for refinance

    - Generic data conditions:
        - Not Required (HOA, Survey)
        - Required Complete Date (In Progress)
        - Completed Date (Completed)

    - Specific data conditions:
        - title clearing
        -  x of x cleared
        -  no liens entered
*/
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Paper, Stack, Typography } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ENTITY_TYPE, PARCEL_TYPE } from '../../../helpers/constants';
import { findOrderParcelByType, formatISODate, formatParcelType, isEntityStatusTerminal } from '../../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../../common/hooks/useDeviceDetails';

const iconKeyMap = {
    inProgress: 'inProgress',
    complete: 'complete',
    warning: 'warning',
    error: 'error',
};

const iconMap = {
    [iconKeyMap.inProgress]: <QueryBuilderIcon fontSize="medium" />,
    [iconKeyMap.complete]: (
        <CheckCircleOutlineIcon
            fontSize="medium"
            // color="success"
            sx={{
                color: (theme) => theme.palette.success.dark,
            }}
        />
    ),
    [iconKeyMap.warning]: (
        <WarningAmberIcon
            fontSize="medium"
            // color="warning"
            sx={{
                color: (theme) => theme.palette.warning.dark,
            }}
        />
    ),
    [iconKeyMap.error]: <ErrorOutlineIcon fontSize="medium" color="error" />,
};

const generateGenericParcelSnapshotContent = (order, parcel, prefix = null) => {
    const parcelComplete = isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);

    if (parcelComplete) {
        return [iconKeyMap.complete, formatISODate(parcel.current_status.created_datetime, 'MMM d')];
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const formattedRequiredCompleteDate = formatISODate(parcel.required_complete_date, 'MMM d');
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);

    if (daysUntilDue <= 7) {
        if (daysUntilDue <= 3) {
            return [iconKeyMap.error, formattedRequiredCompleteDate];
        }

        return [iconKeyMap.warning, formattedRequiredCompleteDate];
    }

    return [iconKeyMap.inProgress, formattedRequiredCompleteDate];
};

const generateOptionalParcelSnapshotContent = (order, parcel, prefix = null) => {
    const additionalData = parcel.additional_data || {};

    const parcelRequired = additionalData[`${prefix ? prefix : parcel.type}_required`];
    const parcelComplete = isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);

    if (!parcelRequired && parcelComplete) {
        return [iconKeyMap.complete, 'Not Required'];
    }

    return generateGenericParcelSnapshotContent(order, parcel, prefix);
};

const generateTitleClearingParcelSnapshotContent = (order, parcel, prefix = null) => {
    // NOTE: admin uses api query to grab all lien statuses and terminal statuses
    // need to expose that route over api and convert over
    const titleCount = order.liens.length;
    const clearedCount = _.filter(order.liens, (lien) => {
        return isEntityStatusTerminal(ENTITY_TYPE.lien, lien.status);
    }).length;

    const formattedClearedCount = `${clearedCount} of ${titleCount}`;

    if (titleCount === 0) {
        return [null, 'No Issues Recorded'];
    }

    if (clearedCount === titleCount) {
        return [iconKeyMap.complete, formattedClearedCount];
    }

    const currentDate = new Date();
    const requiredCompleteDate = parseISO(parcel.required_complete_date);
    const daysUntilDue = differenceInDays(requiredCompleteDate, currentDate);
    if (daysUntilDue <= 7) {
        if (daysUntilDue <= 3) {
            return [iconKeyMap.error, formattedClearedCount];
        }

        return [iconKeyMap.warning, formattedClearedCount];
    }

    return [iconKeyMap.inProgress, formattedClearedCount];
};

const parcelSnapshotContentMap = {
    [PARCEL_TYPE.hoa]: generateOptionalParcelSnapshotContent,
    [PARCEL_TYPE.survey]: generateOptionalParcelSnapshotContent,
    [PARCEL_TYPE.title_clearing]: generateTitleClearingParcelSnapshotContent,
};

const ParcelSnapshotWidget = ({ order, type }) => {
    const deviceDetails = useDeviceDetails();
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;

    const parcel = findOrderParcelByType(order, type);
    if (!parcel) {
        return false;
    }

    const parcelContentGenerator = _.get(parcelSnapshotContentMap, type, generateGenericParcelSnapshotContent);
    const parcelContent = parcelContentGenerator(order, parcel);
    const parcelContentIcon = parcelContent[0];
    const parcelContentText = parcelContent[1];

    return (
        <Paper
            elevation={0}
            variant="outlined"
            sx={{
                width: '100%',
                padding: (theme) => theme.spacing(2),
                borderRadius: (theme) => (isExtraSmallScreen ? 0 : theme.spacing(1)),
                boxSizing: 'border-box',
                minWidth: '172px',

                ...(isExtraSmallScreen && {
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                }),

                '&:first-of-type': {
                    borderTopLeftRadius: (theme) => theme.spacing(2),
                    borderTopRightRadius: (theme) => theme.spacing(2),
                },
                '&:last-of-type': {
                    borderBottomLeftRadius: (theme) => theme.spacing(2),
                    borderBottomRightRadius: (theme) => theme.spacing(2),
                    ...(isExtraSmallScreen && {
                        borderBottom: 0,
                    }),
                },
            }}
        >
            <Stack
                direction={isExtraSmallScreen ? 'row' : 'column'}
                alignItems="center"
                spacing={1}
                justifyContent={isExtraSmallScreen ? 'space-between' : 'center'}
            >
                <Typography fontSize={14}>{formatParcelType(type)}</Typography>

                <Stack direction={isExtraSmallScreen ? 'row-reverse' : 'row'} alignItems="center" spacing={1}>
                    {iconMap[parcelContentIcon] && iconMap[parcelContentIcon]}
                    <Typography fontSize={isExtraSmallScreen ? 14 : 16} fontWeight={isExtraSmallScreen ? 500 : 600}>
                        {parcelContentText}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    );
};

ParcelSnapshotWidget.propTypes = {
    order: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default ParcelSnapshotWidget;
