import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

export const SCREEN_SIZE = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};

export const DEVICE_TYPE = {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
};

// Access only once to prevent re-rendering
const defaultUAString = window.navigator.userAgent;

// TODO should string be the same that mui uses (large -> lg) so  that we can pipe value directly into mui components?
function useScreenSize() {
    const theme = useTheme();

    // NOTE: don't need to check medium directly as it is implicitly true or false based on smallScreen & largeScreen
    const isCompact = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const screenSize = largeScreen ? SCREEN_SIZE.large : smallScreen ? SCREEN_SIZE.small : SCREEN_SIZE.medium;

    return {
        size: screenSize,
        isCompact,
    };
}

function useDevice(uaString = defaultUAString) {
    return useMemo(() => {
        const { device } = UAParser(uaString);

        // NOTE: parsing UA can only determine mobile, tablet, smarttv, console, wearable, embedded
        // So we implicitly set desktop if it is not mobile or tablet since we don't expect to support the other options
        if (!_.includes([DEVICE_TYPE.mobile, DEVICE_TYPE.tablet], device.type)) {
            return {
                type: DEVICE_TYPE.desktop,
                isTouch: false,
            };
        }

        return {
            type: device.type,
            isTouch: true,
        };
    }, [uaString]);
}

const DeviceDetailsContext = React.createContext();

export const DeviceDetailsProvider = ({ children }) => {
    // We are using a context provider here to centralize the calls to determine device details and sync any changes
    // While this isn't an expensive operation, multiple components will use the following useDeviceDetails hook
    // so we are preventing n number of initializations and ensure all updates are triggered by a single unified context change
    const { size, isCompact } = useScreenSize();
    const { type, isTouch } = useDevice();

    const deviceDetails = {
        type,
        isTouch,
        size,
        isCompact,
    };

    return <DeviceDetailsContext.Provider value={deviceDetails}>{children}</DeviceDetailsContext.Provider>;
};

function useDeviceDetails() {
    const deviceDetails = useContext(DeviceDetailsContext);
    return deviceDetails;
}

export default useDeviceDetails;
