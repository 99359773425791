// TODO factory-style component that handles:
// - matching route to component (e.g /hoa -> HoaSection.jsx)
// - display not found if no match
// - use IMPORTANT_EVENT_TYPES or define an interface for each section to implement

import { Box } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';

import { DASHBOARD_SNAPSHOT_TYPE } from '../OrderDashboard';
import AssignmentSection from './Assignment';
import ClosingSection from './Closing';
import ClosingDocumentsSection from './ClosingDocuments';
import EarnestMoneySection from './EarnestMoney';
import HoaSection from './HOA';
import OptionPeriodSection from './OptionPeriod';
import SurveySection from './Survey';
import TaxSection from './Tax';
import TitleExamSection from './TitleCommitment';

export const ORDER_SECTION_TYPE = DASHBOARD_SNAPSHOT_TYPE;

const ORDER_SECTION_TYPE_COMPONENT_MAP = {
    [ORDER_SECTION_TYPE.assignment]: AssignmentSection,
    [ORDER_SECTION_TYPE.earnest_money]: EarnestMoneySection,
    [ORDER_SECTION_TYPE.option_period]: OptionPeriodSection,
    [ORDER_SECTION_TYPE.title_commitment]: TitleExamSection,
    [ORDER_SECTION_TYPE.taxes]: TaxSection,
    [ORDER_SECTION_TYPE.hoa]: HoaSection,
    [ORDER_SECTION_TYPE.survey]: SurveySection,
    [ORDER_SECTION_TYPE.closing_documents]: ClosingDocumentsSection,
    [ORDER_SECTION_TYPE.closing]: ClosingSection,
};

const OrderSection = ({ orderId }) => {
    // TODO error screen

    return (
        <Routes>
            {_.map(ORDER_SECTION_TYPE_COMPONENT_MAP, (SectionComponent, sectionType) => (
                <Route
                    key={sectionType}
                    path={`/${sectionType}`}
                    exact
                    element={
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.lightGray,
                                flex: 1,
                                // borderTopLeftRadius: (theme) => theme.spacing(2),
                                // borderTopRightRadius: (theme) => theme.spacing(2),
                            }}
                        >
                            <SectionComponent orderId={orderId} />
                        </Box>
                    }
                />
            ))}
        </Routes>
    );
};

OrderSection.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderSection;
