
const white = '#FFFFFF';
const black = '#121212';
const offWhite = '#f9f9f9';
const divider = '#E3EFEC';


const hudlyGreen = '#05644e'; //'#268A72'; 
// https://m2.material.io/inline-tools/color/
// Calculated based off hudly green
const darkHudlyGreen = '#1c6a56';
const midHudlyGreen = '#3ca791';
const lightHudlyGreen = '#b5DFD7';
const backgroundHudlyGreen = '#e1f2ef';
const darkGreen = '#268a41'; // #1D6937
const green = '#B5D1BE'; //'#76c487';
const lightGreen = '#e6f4ea';
const backgroundGreen = '#c8e6c9';
const darkBlue = '#014361'; //'#266f8A';
const blue = '#0288d1'; //'#5392AD';
const lightBlue = '#e5f6fd'; //'#91C1D8';
const backgroundBlue = '#d3f3ff';
const darkYellow = '#663c00'; //'#fdd835';
const yellow = '#ed6c02'; // '#ffee58'; // #EFC81D
const lightYellow = '#fff4e5'; //'#fff59d';
const backgroundYellow = '#fff9c4';
const darkRed = '#5f2120'; //'#8A263d';
const red = '#d32f2f'; //'#C23447';
const lightRed = '#fdeded'; //'#D66277';
const backgroundRed = '#f8e3e7';


const palette = {
    black,
    white,
    blue,
    lightBlue,
    offWhite,
    hudly: {
        main: hudlyGreen,
        dark: darkHudlyGreen,
        normal: midHudlyGreen,
        light: lightHudlyGreen,
        background: backgroundHudlyGreen,
    },
    primary: {
        contrastText: white,
        main: hudlyGreen,
    },
    secondary: {
        contrastText: hudlyGreen,
        main: divider,
    },
    success: {
        contrastText: white,
        // dark: '#3A724D',
        // main: '#88AE95',
        // light: '#E5EEE8',
        dark: darkGreen,
        main: green,
        light: lightGreen,
        background: backgroundGreen,
        border: '#3A724D'
    },
    info: {
        contrastText: white,
        dark: darkBlue,
        main: blue,
        light: lightBlue,
        background: backgroundBlue,
    },
    warning: {
        contrastText: '#454545', //white,
        dark: darkYellow,
        main: yellow,
        light: lightYellow,
        background: backgroundYellow,
    },
    error: {
        contrastText: white,
        dark: darkRed,
        main: red,
        light: lightRed,
        background: backgroundRed,
    },
    // white: {
    //     contrastText: '#454545',
    //     dark: '#f2f2f2',
    //     main: '#fafafa',
    //     light: offWhite,
    //     border: '#454545'
    // },
    light: {
        contrastText: black,
        dark: '#f2f2f2',
        main: '#f8f8f8',
        light: '#fff',
        border: '#fafafa'
    },
    default: {
        contrastText: white,
        dark: '#434845',
        main: '#777978',
        light: '#A3A3A3',
        border: '#A3A3A3'
    },
    text: {
        // primary: '#43436B',
        primary: '#454545',
        secondary: '#A1A1B5',
        link: '#065E9C'
    },
    background: {
        default: divider, //'#F4F6F8',
        darkGray: '#f2f2f2',
        gray: '#fafafa',
        lightGray: '#F6F6F8',
        light: '#F8FAFC',
        paper: white,
        dark: '#323232',
    },
    paper: {
        background: '#191919',
        border: divider
    },
    icon: '#A1A1B5',
    divider: divider,
    neutral: '#fafafa',
    border: divider,
};

export default palette;