import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Avatar,
    Box,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import { TRANSACTION_TYPE } from '../../helpers/constants';
import { USDollar, formatAddress, formatISODate, formatShortAddress } from '../../helpers/utils';
// import CustomDetailPanelToggle from '../common/CustomDetailPanelToggle';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';
import OrderExpandedContent from './OrderExpandedContent';

function generateColumns(deviceDetails) {
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;

    return [
        {
            field: 'qualia_order_number',
            headerName: 'Name & Address',
            flex: 3,
            minWidth: deviceDetails.size === SCREEN_SIZE.small ? 240 : 350,
            renderCell: (params) => {
                const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
                const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;
                const address =
                    deviceDetails.size !== SCREEN_SIZE.large
                        ? formatShortAddress(params.row.property_address)
                        : formatAddress(params.row.property_address);
                const avatarIcon =
                    params.row.transaction_type === TRANSACTION_TYPE.purchase ? <HomeIcon /> : <AccountBalanceIcon />;

                const primaryText = isExtraSmallScreen ? address : params.value;
                const closeDate = formatISODate(params.row.close_date, 'MMM dd');
                const closeDay = formatISODate(params.row.close_date, 'dd');
                const closeMonth = formatISODate(params.row.close_date, 'MMM');
                const transactionType = _.startCase(params.row.transaction_type);
                const secondaryText = isExtraSmallScreen ? transactionType : address;

                // TODO flesh out secondary text for small screens
                // ex: "Purchase • Closing ~ Jun 30"
                // ex: "Refinance • Closed Jun 30"
                // ex: "Purchase • Cancelled"
                // ex: "Refinance • On Hold"
                // ex: "Refinance • Closed Jan 21, '21"

                return (
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to={`/escrow/${params.row.id}`}
                            sx={{
                                '&.MuiListItemButton-root:hover': {
                                    backgroundColor: 'transparent',

                                    '& .MuiListItemText-primary': {
                                        textDecoration: 'underline',
                                    },
                                },
                            }}
                            disableGutters
                        >
                            <ListItemAvatar>
                                {isExtraSmallScreen ? (
                                    <Avatar
                                        sx={{
                                            backgroundColor: 'transparent',
                                            // backgroundColor: (theme) => theme.palette.secondary.main,
                                            // color: (theme) => theme.palette.secondary.contrastText,
                                        }}
                                    >
                                        <Stack direction="column" spacing={0} alignItems="center">
                                            <Typography variant="caption" sx={{ fontSize: '10px', fontWeight: '600' }}>
                                                {closeDay}
                                            </Typography>
                                            <Typography variant="caption" sx={{ fontSize: '10px', fontWeight: '600' }}>
                                                {closeMonth}
                                            </Typography>
                                        </Stack>
                                    </Avatar>
                                ) : (
                                    <Avatar
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.secondary.main,
                                            color: (theme) => theme.palette.secondary.contrastText,
                                        }}
                                    >
                                        {avatarIcon}
                                    </Avatar>
                                )}
                            </ListItemAvatar>

                            <ListItemText
                                primary={primaryText}
                                primaryTypographyProps={{
                                    fontSize: isExtraSmallScreen ? '14px' : '16px',
                                    fontWeight: isExtraSmallScreen ? '500' : '600',
                                }}
                                secondary={secondaryText}
                                secondaryTypographyProps={
                                    {
                                        // color: '#667085',
                                    }
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            },
        },
        {
            field: 'purchase_price',
            headerName: 'Price',
            valueGetter: ({ row }) => {
                return row.transaction_type === TRANSACTION_TYPE.refinance ? row.loan_amount : row.purchase_price;
            },
            valueFormatter: ({ value }) => USDollar.format(value),
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            hide: isExtraSmallScreen,
        },
        {
            field: 'close_date',
            headerName: 'Close Date',
            valueFormatter: ({ value }) => formatISODate(value),
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            hide: isExtraSmallScreen,
        },
        {
            field: 'transaction_type',
            headerName: 'Type',
            valueFormatter: (params) => _.startCase(params.value),
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            hide: isSmallScreen,
        },
        {
            field: 'status',
            headerName: 'Status',
            valueFormatter: (params) => _.startCase(params.value),
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            hide: isSmallScreen,
        },
        {
            field: 'id',
            headerName: '',
            flex: 0,
            minWidth: isExtraSmallScreen ? 66 : 74, // NOTE: 74 = 10 lp + 40 content + 24 rp
            maxWidth: isExtraSmallScreen ? 66 : 74,
            renderCell: (params) => (
                <IconButton component={Link} to={`/escrow/${params.row.id}`}>
                    <NavigateNextIcon />
                </IconButton>
            ),
        },
        // {
        //     ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        //     flex: 0,
        //     minWidth: isExtraSmallScreen ? 66 : 74, // NOTE: 74 = 10 lp + 40 content + 24 rp
        //     maxWidth: isExtraSmallScreen ? 66 : 74,
        //     renderCell: (params) => <CustomDetailPanelToggle id={params.row.id} value={params.value} />,
        // },
    ];
}

// TODO add custom footer pagination component
function OrderListGrid(props) {
    const { orders, loading } = props;

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    // TODO determine if we want expanded content on non-mobile devices
    const getDetailPanelContent = useCallback(
        ({ row }) => {
            return (
                <Box
                    className="expanded-content"
                    sx={{
                        p: isExtraSmallScreen ? 2 : 3,
                        pt: 1,
                    }}
                >
                    <OrderExpandedContent order={row} />
                </Box>
            );
        },
        [isExtraSmallScreen]
    );

    const generatedColumns = useMemo(() => generateColumns(deviceDetails), [deviceDetails]);

    return (
        <Box sx={{ display: 'flex', flex: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGridPro
                    rows={orders}
                    columns={generatedColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    loading={loading}
                    getRowId={(row) => row.id}
                    // getDetailPanelContent={getDetailPanelContent}
                    // getDetailPanelHeight={() => 'auto'}
                    headerHeight={isExtraSmallScreen ? 0 : 56}
                    rowHeight={67}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    autoHeight={!isExtraSmallScreen}
                    pagination
                    sx={{
                        border: 'none',
                        borderRadius: (theme) => theme.spacing(1),
                        color: (theme) => theme.palette.text.primary,
                        backgroundColor: (theme) => theme.palette.background.paper,
                        '& .MuiDataGrid-main': {
                            ...(isExtraSmallScreen && {
                                background: (theme) => theme.palette.background.lightGray,
                            }),
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cellCheckbox:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            // borderBottomColor: (theme) => theme.palette.border,
                            borderBottomColor: '#EAECF0',
                            background: 'rgba(0,0,0,.01)',
                            borderRadius: 0,

                            ...(isExtraSmallScreen && {
                                borderBottom: 0,
                            }),

                            '& .MuiDataGrid-columnHeader': {
                                '&:first-of-type': {
                                    paddingLeft: (theme) => theme.spacing(isExtraSmallScreen ? 2 : 3),
                                },
                                '&:last-of-type': {
                                    paddingRight: (theme) => theme.spacing(isExtraSmallScreen ? 2 : 3),
                                },
                            },
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: '#FFF',

                            '&:hover': {
                                backgroundColor: '#FFF',
                            },

                            '&.MuiDataGrid-row--detailPanelExpanded': {
                                backgroundColor: (theme) => theme.palette.background.light,

                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.background.light,
                                },

                                '& .MuiDataGrid-cell': {
                                    // remove bottom border of child cells as the following row will take over the border
                                    borderColor: 'transparent',
                                },

                                '& + .MuiDataGrid-row': {
                                    // style the next row to 'take over' border between rows since the inserted detail panels have no context of positon in grid
                                    '& .MuiDataGrid-cell': {
                                        borderTop: '1px solid #EAECF0',
                                    },
                                },

                                '& .MuiAvatar-root': {
                                    // invert avatar style when opened
                                    backgroundColor: (theme) => theme.palette.secondary.contrastText,
                                    color: (theme) => theme.palette.secondary.main,
                                },
                            },
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottomColor: (theme) => theme.palette.border,

                            '&:first-of-type': {
                                paddingLeft: (theme) => theme.spacing(isExtraSmallScreen ? 2 : 3),
                            },
                            '&:last-of-type': {
                                paddingRight: (theme) => theme.spacing(isExtraSmallScreen ? 2 : 3),
                            },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: '#667085',
                            fontWeight: '600',
                        },
                        '& .MuiDataGrid-detailPanel': {
                            backgroundColor: (theme) => theme.palette.background.light,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: '1px solid #EAECF0',
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

OrderListGrid.propTypes = {
    orders: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default OrderListGrid;
