import { FormLabel, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { GENDER, MARITAL_STATUS } from 'helpers/constants';

export const INITIAL_PROFILE_DATA = {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    marital_status: '',
    date_of_birth: '',
};

// TODO build out
export const formatProfilePayload = (profileData) => {
    const profilePayload = {};
    let profileEmpty = true;
    _.forEach(profileData, (value, key) => {
        if (_.isNil(value) || value === '') {
            // If single field is empty assign null for field
            profilePayload[key] = null;
        } else {
            profileEmpty = false;
            profilePayload[key] = value;
        }
    });

    // If all fields are empty return null
    if (profileEmpty) {
        return null;
    }

    return profilePayload;
};

const ProfileForm = ({
    profileData,
    setProfileData,
    label = 'Profile',
    showLabel = false,
    required = false,
    disabled = false,
    requiredFields = ['first_name', 'last_name'],
    disabledFields = [],
}) => {
    const handleChange = ({ target }) => {
        setProfileData({
            ...profileData,
            [target.name]: target.value,
        });
    };

    const handleDateChange = (date) => {
        // NOTE: not strict comparison due to issues with date error response object
        if (date && date != 'Invalid Date') {
            setProfileData({
                ...profileData,
                date_of_birth: format(date, 'yyyy-MM-dd'),
            });
        } else {
            setProfileData({
                ...profileData,
                date_of_birth: null,
            });
        }
    };

    return (
        <Stack direction="column" spacing={2}>
            {showLabel && (
                <FormLabel id="profile-group-label" required={false}>
                    {label}
                </FormLabel>
            )}
            <Stack spacing={4}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <TextField
                        name="first_name"
                        label="First Name"
                        onChange={handleChange}
                        value={profileData.first_name}
                        sx={{ flex: 1 }}
                        size="small"
                        required={required || requiredFields.includes('first_name')}
                        disabled={disabled || disabledFields.includes('first_name')}
                    />

                    <TextField
                        name="last_name"
                        label="Last Name"
                        onChange={handleChange}
                        value={profileData.last_name}
                        sx={{ flex: 1 }}
                        size="small"
                        required={required || requiredFields.includes('last_name')}
                        disabled={disabled || disabledFields.includes('last_name')}
                    />
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <TextField
                        name="gender"
                        label="Gender"
                        onChange={handleChange}
                        value={profileData.gender || ''}
                        sx={{ flex: 1 }}
                        select
                        size="small"
                        required={required || requiredFields.includes('gender')}
                        disabled={disabled || disabledFields.includes('gender')}
                    >
                        {_.map(GENDER, (genderOption) => (
                            <MenuItem key={genderOption} value={genderOption}>
                                {_.startCase(genderOption)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        name="marital_status"
                        label="Marital Status"
                        onChange={handleChange}
                        value={profileData.marital_status}
                        sx={{ flex: 1 }}
                        size="small"
                        select
                        required={required || requiredFields.includes('marital_status')}
                        disabled={disabled || disabledFields.includes('marital_status')}
                    >
                        {_.map(MARITAL_STATUS, (maritalStatusOption) => (
                            <MenuItem key={maritalStatusOption} value={maritalStatusOption}>
                                {_.startCase(maritalStatusOption)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <DatePicker
                        autoOk
                        label="Date of Birth"
                        openTo="year"
                        inputFormat="MM/dd/yyyy"
                        value={profileData.date_of_birth ? parseISO(profileData.date_of_birth) : null}
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                sx={{ flex: 1 }}
                                required={required || requiredFields.includes('date_of_birth')}
                                disabled={disabled || disabledFields.includes('date_of_birth')}
                            />
                        )}
                        disableFuture
                        clearable
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

ProfileForm.propTypes = {
    profileData: PropTypes.object.isRequired,
    setProfileData: PropTypes.func.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    requiredFields: PropTypes.array,
    disabledFields: PropTypes.array,
};

export default ProfileForm;
