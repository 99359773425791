import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';

import { getInitials } from '../../helpers/utils';

const UserAvatar = ({ firstName, lastName, avatarUrl = null, avatarProps = {} }) => {
    if (!firstName) {
        return <Avatar {...avatarProps} />;
    }

    // NOTE: disabled for now as we don't control auth0 avatars
    if (avatarUrl && false) {
        return <Avatar {...avatarProps} alt={firstName} src={avatarUrl} />;
    }

    return (
        <Avatar
            {...avatarProps}
            // sx={{
            //     background: '#439398',  // TODO - generate color based on user name
            // }}
        >
            {getInitials(firstName, lastName)}
        </Avatar>
    );
};

UserAvatar.propTypes = {
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatarUrl: PropTypes.string,
    avatarProps: PropTypes.object,
};

export default UserAvatar;
