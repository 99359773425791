import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, Chip, Fab, Stack, Typography } from '@mui/material';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useGetOrderQuery } from 'api/order';
import useDeviceDetails, { SCREEN_SIZE } from 'components/common/hooks/useDeviceDetails';
import OrderBreadcrumbs from './OrderBreadcrumbs';

const OrderHeaderActions = ({ orderId }) => {
    const actionLinks = [
        {
            to: `/escrow/${orderId}`,
            icon: <HomeIcon />,
        },
        {
            to: `/escrow/${orderId}/contacts`,
            icon: <GroupIcon />,
        },
        {
            to: `/escrow/${orderId}/documents`,
            icon: <DescriptionIcon />,
        },
        {
            to: `/escrow/${orderId}/calendar`,
            icon: <CalendarMonthIcon />,
        },
        {
            to: `/escrow/${orderId}/chat`,
            icon: <QuestionAnswerIcon />,
        },
    ];

    return (
        <Stack
            className="order-header-actions"
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
                zIndex: 1,
                position: 'relative',

                '& .MuiFab-root': {
                    boxShadow: 'none',
                    color: (theme) => theme.palette.secondary.main,
                    backgroundColor: 'rgba(0,0,0,0)',
                    opacity: '0.5',

                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0)',
                        opacity: '1',
                    },

                    '&.active': {
                        backgroundColor: 'rgba(0,0,0,.15)',
                        opacity: '1',
                        boxShadow: 'none',

                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,.15)',
                        },
                    },
                },
            }}
        >
            {_.map(actionLinks, (actionLink) => {
                return (
                    <Fab
                        key={actionLink.to}
                        variant="circular"
                        size="medium"
                        color="secondary"
                        component={NavLink}
                        to={actionLink.to}
                        end
                    >
                        {actionLink.icon}
                    </Fab>
                );
            })}
        </Stack>
    );
};

OrderHeaderActions.propTypes = {
    orderId: PropTypes.string.isRequired,
};

const OrderStatusChip = ({ orderStatus }) => {
    return (
        <Chip
            label={_.startCase(orderStatus)}
            color={orderStatus === 'Closed' ? 'success' : 'secondary'}
            size="small"
            sx={{
                borderRadius: '4px',
                // textTransform: 'uppercase',
                fontWeight: 500,
                color: orderStatus === 'Closed' ? 'primary.dark' : 'text.primary',
            }}
        />
    );
};

const OrderHeader = ({ orderId, primary, secondary }) => {
    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const { scrollY } = useScroll();

    const [isFixed, setIsFixed] = useState(false);

    const { headerContainerHeight, headerActionsHeight, appBarHeight } = useMemo(() => {
        const headerEle = document.querySelector('.order-header');
        const headerContainerHeight = _.get(headerEle, 'clientHeight', 226); // Fallback height

        const headerActions = document.querySelector('.order-header-actions');
        const headerActionsHeight = _.get(headerActions, 'clientHeight', 48);

        const appBar = document.querySelector('.MuiAppBar-root'); // TODO replace with custom id
        const appBarHeight = appBar.clientHeight;

        return {
            headerContainerHeight,
            headerActionsHeight,
            appBarHeight,
        };
    }, [isFixed]);

    useMotionValueEvent(scrollY, 'change', (latestScrollY) => {
        const fixedPoint = headerContainerHeight - appBarHeight;
        if (latestScrollY > fixedPoint) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    });

    const orderStatus = _.get(order, 'status');
    const actionBarOffset = headerContainerHeight - (appBarHeight + headerActionsHeight) - 16;

    return (
        <Box sx={{ minHeight: `${headerContainerHeight}px` }}>
            <Box
                className={`order-header ${isFixed ? 'fixed' : ''}`}
                sx={{
                    backgroundColor: (theme) => theme.palette.hudly.main,
                    // backgroundImage: 'url(./egg-shell.png)',

                    '&.fixed': {
                        position: 'fixed',
                        zIndex: 10,
                        top: `-${actionBarOffset}px`,
                        width: '100%',
                    },
                }}
            >
                <Stack
                    sx={{
                        p: 2,
                        // backgroundColor: (theme) => theme.palette.hudly.main,
                        // backgroundImage: 'url(./egg-shell.png)',
                        background: 'rgba(0,0,0,0.15)',
                    }}
                    spacing={3}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <OrderBreadcrumbs />

                        {isExtraSmallScreen && <OrderStatusChip orderStatus={orderStatus} />}
                    </Stack>

                    <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography
                                variant="h4"
                                color="white"
                                fontWeight={600}
                                sx={{
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {primary}
                            </Typography>

                            <Box sx={{ flexGrow: 1 }} />

                            {!isExtraSmallScreen && <OrderStatusChip orderStatus={orderStatus} />}
                        </Stack>

                        <Typography variant="h6" fontWeight={500} color="secondary">
                            {secondary}
                        </Typography>
                    </Stack>

                    <OrderHeaderActions orderId={orderId} />
                </Stack>
            </Box>
        </Box>
    );
};

OrderHeader.propTypes = {
    orderId: PropTypes.string.isRequired,
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
};

export default OrderHeader;
