import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import rootApi from './api/root';
import { DeviceDetailsProvider } from './components/common/hooks/useDeviceDetails';
import theme from './theme';


LicenseInfo.setLicenseKey(
    'b5a4009c44006d9176668720638416d3T1JERVI6NDIyMjQsRVhQSVJZPTE2ODIxMDY3NzcwMDAsS0VZVkVSU0lPTj0x',
);


const store = configureStore({
    reducer: {
        [rootApi.reducerPath]: rootApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(rootApi.middleware);
    }
});


function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <DeviceDetailsProvider>
                            <AppRouter />
                        </DeviceDetailsProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </LocalizationProvider>
    );
}

export default App;
