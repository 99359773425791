import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// TODO need short and long descriptions
// one for dashboard 'snapshot' view
// one for dedicated 'section' view
export const SurveyInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            A <b>Survey</b> is a detailed examination and measurement of a property's boundaries, structures, and land
            features.
        </Typography>
    );
};

const SurveyInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            A <b>Survey</b> is a detailed examination and measurement of a property's boundaries, structures, and land
            features. The <b>Survey</b> report provides essential information about the property, such as the location
            of fences, buildings, easements, encroachments, and other physical attributes.
            <br />
            <br />
            This <b>Survey</b> is crucial in the escrow process as it helps ensure that all parties involved have a
            clear understanding of the property's dimensions and any potential boundary disputes or legal issues.
        </Typography>
    );
};

SurveyInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default SurveyInfo;
