import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';


export function calculateRequired(order, parcel) {
    return _.get(parcel, 'additional_data.hoa_required', null);
}

export function calculateComplete(order, parcel) {
    // returns true or false
    // compare today's date to the hoa period end date

    // If hoa_required is false, then this event is complete
    const hoaRequired = _.get(parcel, 'additional_data.hoa_required', null);
    if (hoaRequired === false) {
        return true;
    }

    // HOA is required, compare today's date to the hoa period end date
    const hoaPeriodEnd = calculateRequiredCompleteDate(order, parcel);
    if (!hoaPeriodEnd) {
        return false;
    }

    const today = new Date();

    return isAfter(today, hoaPeriodEnd);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    // NOTE: there is no 'action' that completes this event in advance.
    // The 'complete' date is the final date of the required_complete_date (based off hoa period) if it has passed
    if (!calculateComplete(order, parcel)) {
        return null;
    }

    // If hoa_required is false, then this event is considered 'complete' when the terminal status is reached
    const hoaRequired = _.get(parcel, 'additional_data.hoa_required', null);
    if (hoaRequired === false) {
        return parseISO(parcel.current_status.created_datetime);
    }

    return calculateRequiredCompleteDate(order, parcel);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj or null
    const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
    if (!requiredCompleteDate) {
        return null;
    }

    return parseISO(requiredCompleteDate);
}

export function calculateReceivedDate(order, parcel) {
    const receivedDate = _.get(parcel, 'additional_data.hoa_received_date', null);
    if (!receivedDate) {
        return null;
    }

    return parseISO(receivedDate);
}


export function calculateObjectionDate(order, parcel) {
    const objectionDate = _.get(parcel, 'additional_data.objection_date', null);
    if (!objectionDate) {
        return null;
    }

    return parseISO(objectionDate);
}

