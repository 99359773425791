import _ from 'lodash';
import { useMemo } from 'react';

import {
    useGetOrderActionsQuery,
    useGetOrderMemberAssociationsQuery,
    useGetOrderMembersQuery,
    useGetOrderQuery,
} from 'api/order';

import { findParcelInOrder } from 'helpers/utils';

function useOrderData(orderId) {
    const {
        data: order,
        isError: isOrderError,
        isLoading: isOrderLoading,
        isFetching: isOrderFetching,
    } = useGetOrderQuery(orderId);

    const {
        data: orderMembers,
        isError: isOrderMembersError,
        isLoading: isOrderMembersLoading,
        isFetching: isOrderMembersFetching,
    } = useGetOrderMembersQuery(orderId);

    const {
        data: orderActions,
        isError: isOrderActionsError,
        isLoading: isOrderActionsLoading,
        isFetching: isOrderActionsFetching,
    } = useGetOrderActionsQuery(orderId);

    const {
        data: orderMemberAssociations,
        isError: isOrderMemberAssociationsError,
        isLoading: isOrderMemberAssociationsLoading,
        isFetching: isOrderMemberAssociationsFetching,
    } = useGetOrderMemberAssociationsQuery(orderId);

    const assignmentMemberships = _.get(orderMemberAssociations, 'assignment_memberships', []);
    const assignor = _.find(assignmentMemberships, { type: 'assignor' }); // TODO enum
    const assignee = _.find(assignmentMemberships, { type: 'assignee' }); // TODO enum - NOTE: might not exist

    const assignorParcel = useMemo(() => findParcelInOrder(order, assignor?.assignment_parcel_id), [order, assignor]);
    const assigneeParcel = useMemo(() => findParcelInOrder(order, assignee?.assignment_parcel_id), [order, assignee]);

    const isError = isOrderError || isOrderMembersError || isOrderActionsError || isOrderMemberAssociationsError;
    const isLoading =
        isOrderLoading || isOrderMembersLoading || isOrderActionsLoading || isOrderMemberAssociationsLoading;
    const isFetching =
        isOrderFetching || isOrderMembersFetching || isOrderActionsFetching || isOrderMemberAssociationsFetching;

    return {
        order,
        orderMembers,
        actions: orderActions,
        associations: orderMemberAssociations,
        assignor,
        assignorParcel,
        assignee,
        assigneeParcel,
        isError,
        isLoading,
        isFetching,
    };
}

export default useOrderData;
