import {
    Box,
    FormLabel,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const google = window.google;

export const INITIAL_ADDRESS_DATA = {
    street: '',
    street_additional: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
};

export const formatAddressPayload = (addressData) => {
    const addressPayload = {};
    let addressEmpty = true;
    _.forEach(addressData, (value, key) => {
        if (_.isNil(value) || value === '') {
            // If single field is empty assign null for field
            addressPayload[key] = null;
        } else {
            addressEmpty = false;
            addressPayload[key] = value;
        }
    });

    // If all fields are empty or main street is empty return null
    if (addressEmpty || addressPayload.street === null) {
        return null;
    }

    return addressPayload;
};

const AddressForm = ({
    addressData,
    setAddressData,
    label = 'Property Address',
    showLabel = false,
    required = false,
    disabled = false,
}) => {
    const searchOptions = {
        types: ['address'],
        fields: ['address_components', 'geometry'],
        componentRestrictions: { country: ['us'] },
        location: google ? new google.maps.LatLng(-30, 98) : undefined,
        radius: 1000000,
        // locationBias: {
        //     radius: 1000000, // meters
        //     center: new google.maps.LatLng(-30, 98),
        // },
    };

    const handleStreetChange = (street) => {
        setAddressData({
            ...addressData,
            street: street,
        });
    };

    const handleChange = ({ target }) => {
        setAddressData({
            ...addressData,
            [target.name]: target.value,
        });
    };

    const handleAutocomplete = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                if (results.length === 0) {
                    return;
                }

                const result = results[0];
                const newAddress = { ...INITIAL_ADDRESS_DATA };

                _.forEach(result.address_components, (component) => {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case 'street_number': {
                            // ie: road number (ex: 1234)
                            newAddress.street = `${component.long_name} ${newAddress.street}`;
                            break;
                        }

                        case 'route': {
                            // ie: road name (ex: Hart Lane)
                            newAddress.street += component.long_name;
                            break;
                        }

                        case 'subpremise': {
                            // ie: apartment number (ex: 1234)
                            newAddress.street_additional = component.long_name;
                            break;
                        }

                        case 'postal_code': {
                            // ie: zip (ex: 78759)
                            newAddress.zipcode = `${component.long_name}${newAddress.zipcode}`;
                            break;
                        }

                        case 'postal_code_suffix': {
                            newAddress.zipcode = `${newAddress.zipcode}-${component.long_name}`;
                            break;
                        }

                        case 'locality':
                            // ie: town (ex: Austin)
                            newAddress.city = component.long_name;
                            break;

                        case 'administrative_area_level_1': {
                            // ie: state (ex: Texas)
                            newAddress.state = component.long_name;
                            break;
                        }

                        case 'country':
                            newAddress.country = component.short_name;
                            break;
                    }
                });

                // Update state
                setAddressData({
                    ...newAddress,
                });
            })
            .catch((error) => console.error(error));
    };

    return (
        <Stack spacing={2}>
            {showLabel && (
                <FormLabel id="address-group-label" required={false}>
                    {label}
                </FormLabel>
            )}

            <PlacesAutocomplete
                value={addressData.street}
                onChange={handleStreetChange}
                onSelect={handleAutocomplete}
                searchOptions={searchOptions}
                shouldFetchSuggestions={Boolean(addressData.street) && addressData.street.length > 3}
                debounce={500}
                highlightFirstSuggestion
                // googleCallbackName="initMap"
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Box sx={{ flexGrow: 1, position: 'relative' }}>
                        <TextField
                            name="street"
                            placeholder="Street"
                            label="Street"
                            value={addressData.street}
                            onChange={handleChange}
                            inputProps={{ ...getInputProps() }}
                            variant="outlined"
                            fullWidth
                            size="small"
                            required={!!addressData.street || required}
                            disabled={disabled}
                        />

                        <Paper
                            className="autocomplete-dropdown-container"
                            sx={{
                                position: 'absolute',
                                zIndex: '10',
                            }}
                        >
                            <Box sx={{ position: 'relative' }}>
                                {loading && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                        }}
                                    />
                                )}

                                <List disablePadding>
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';

                                        return (
                                            <ListItemButton
                                                key={suggestion.placeId}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <ListItemText primary={suggestion.description} />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Paper>
                    </Box>
                )}
            </PlacesAutocomplete>
            <TextField
                name="street_additional"
                label="Street (Additional)"
                value={addressData.street_additional}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                disabled={disabled}
            />
            <Stack direction="row" spacing={2}>
                <TextField
                    name="city"
                    label="City"
                    value={addressData.city}
                    onChange={handleChange}
                    variant="outlined"
                    required={!!addressData.street || required}
                    size="small"
                    sx={{ flex: 1 }}
                    disabled={disabled}
                />
                <TextField
                    name="state"
                    label="State"
                    value={addressData.state}
                    onChange={handleChange}
                    variant="outlined"
                    required={!!addressData.street || required}
                    size="small"
                    sx={{ flex: 1 }}
                    disabled={disabled}
                />
                <TextField
                    name="zipcode"
                    label="Zipcode"
                    value={addressData.zipcode}
                    inputProps={{ pattern: '(^[0-9]{5}$)|(^[0-9]{9}$)|(^[0-9]{5}-[0-9]{4}$)' }}
                    onChange={handleChange}
                    variant="outlined"
                    required={!!addressData.street || required}
                    size="small"
                    sx={{ flex: 1 }}
                    disabled={disabled}
                />
            </Stack>
        </Stack>
    );
};

AddressForm.propTypes = {
    addressData: PropTypes.shape({
        street: PropTypes.string.isRequired,
        street_additional: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
    }).isRequired,
    setAddressData: PropTypes.func.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default AddressForm;
