import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
    palette,
    typography,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: palette.hudly.main,
                    // backgroundImage: "url(./egg-shell.png)",
                }
            },
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,    // Prevent SSR double render since we are client-side only atm
            },
        },
    },
});

export default theme;
