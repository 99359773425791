import { ListItemAvatar, ListItemText, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const SubSectionPaper = ({ order, sectionType, subSectionConfig, subSectionType }) => {
    const { title, Icon, renderContent, renderTitle } = subSectionConfig;

    const Title = title ? <ListItemText primary={title} /> : renderTitle({ order });
    const Content = renderContent({ order });

    return (
        <Paper variant="outlined" sx={{ p: 2, height: '100%', borderRadius: (theme) => theme.spacing(2) }}>
            <Stack spacing={2}>
                <Stack direction="row" alignItems="center">
                    <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>{Icon}</ListItemAvatar>
                    {Title}
                </Stack>

                {Content}
            </Stack>
        </Paper>
    );
};

SubSectionPaper.propTypes = {
    order: PropTypes.object.isRequired,
    sectionType: PropTypes.string.isRequired,
    subSectionType: PropTypes.string.isRequired,
    subSectionConfig: PropTypes.object.isRequired, // TODO define shape of
};

export default SubSectionPaper;
