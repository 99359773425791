import { useParams } from 'react-router-dom';
import Order from '../components/Order';

function OrderPage() {
    let { orderId } = useParams();

    return <Order orderId={orderId} />;
}

export default OrderPage;
