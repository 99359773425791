import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { TitleCommitmentObjectionDate, TitleCommitmentSentDate } from './TitleCommitmentDates';
import { TitleExamInfoShort } from './TitleCommitmentInfo';

function TitleCommitmentSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_exam);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <TitleCommitmentSentDate order={order} parcel={parcel} />

                <TitleCommitmentObjectionDate order={order} parcel={parcel} />
            </Stack>

            <TitleExamInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={'title_commitment'} />
        </Stack>
    );
}

TitleCommitmentSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TitleCommitmentSnapshot;
