import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// TODO need short and long descriptions
// one for dashboard 'snapshot' view
// one for dedicated 'section' view
export const ClosingDocumentsInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            TODO describe closing documents and what to review before closing
        </Typography>
    );
};

const ClosingDocumentsInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            TODO describe closing documents and what to review before closing
        </Typography>
    );
};

ClosingDocumentsInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingDocumentsInfo;
