import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderMembersQuery } from '../../api/order';
import { useGetUserQuery } from '../../api/user';
import { ENTITY_TYPE } from '../../helpers/constants';

function findUserInOrderMemberList(user, orderMembers) {
    if (!user || !orderMembers) {
        return null;
    }

    return _.find(orderMembers, (orderMember) => {
        if (orderMember.member_type === ENTITY_TYPE.user) {
            return orderMember.member.id === user.id;
        }

        return _.get(orderMember, 'member.user_id') === user.id;
    });
}

function useOrderMemberData(orderId) {
    const { data: user, isError: userError, isLoading: userLoading } = useGetUserQuery();
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        orderId
    );
    const userOrderMember = findUserInOrderMemberList(user, orderMembers);

    return {
        orderMembers,
        orderMembersLoading,
        orderMembersError,
        userOrderMember,
    };
}

useOrderMemberData.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default useOrderMemberData;
