import { parseISO } from 'date-fns';
import _ from 'lodash';

import { ENTITY_TYPE } from '../../../../helpers/constants';
import { isEntityStatusTerminal } from '../../../../helpers/utils';


export function calculateComplete(order, parcel) {
    // returns true or false
    return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    // date of parcel complete status
    if (!calculateComplete(order, parcel)) {
        return null;
    }

    const completedDate = _.get(parcel, 'current_status.created_datetime', null);
    if (!completedDate) {
        return null;
    }

    return parseISO(completedDate);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj or null
    const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
    if (!requiredCompleteDate) {
        return null;
    }

    return parseISO(requiredCompleteDate);
}

export function calculateTaxCertOrderedDate(order, parcel) {
    const taxCertOrderedDate = _.get(parcel, 'additional_data.tax_cert_ordered_date');
    if (!taxCertOrderedDate) {
        return null;
    }

    return parseISO(taxCertOrderedDate);
}

export function calculateTaxCertETA(order, parcel) {
    const taxCertETA = _.get(parcel, 'additional_data.tax_cert_eta');
    if (!taxCertETA) {
        return null;
    }

    return parseISO(taxCertETA);
}

export function calculateTaxCertReceivedDate(order, parcel) {
    const taxCertReceivedDate = _.get(parcel, 'additional_data.tax_cert_received_date');
    if (!taxCertReceivedDate) {
        return null;
    }

    return parseISO(taxCertReceivedDate);
}


