import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { EarnestMoneyDueDate, EarnestMoneyReceivedDate } from './EarnestMoneyDates';
import { EarnestMoneyAmount, OptionFeeAmount } from './EarnestMoneyFees';
import { EarnestMoneyInfoShort } from './EarnestMoneyInfo';
import { calculateReceivedDate } from './util';

function EarnestMoneySnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);

    // If received date is available, show it instead of due date
    const showReceivedDate = !!calculateReceivedDate(order, parcel);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <EarnestMoneyAmount parcel={parcel} />

                <OptionFeeAmount parcel={parcel} />

                {showReceivedDate ? (
                    <EarnestMoneyReceivedDate order={order} parcel={parcel} />
                ) : (
                    <EarnestMoneyDueDate order={order} parcel={parcel} />
                )}
            </Stack>

            <EarnestMoneyInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={PARCEL_TYPE.earnest_money} />
        </Stack>
    );
}

EarnestMoneySnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default EarnestMoneySnapshot;
