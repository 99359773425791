import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';

const TitleExamFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_exam);
    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;
    const placeholderText = 'TBD';

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            TODO static text
        </Stack>
    );
};

TitleExamFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TitleExamFees;
