import { addYears, format, getUnixTime, isBefore, isWithinInterval, parseISO } from 'date-fns';
import _ from 'lodash';
import Config from '../config';
import { DOCUMENT_TYPE_NAME_OVERRIDE, ENTITY_TYPE, LIEN_TERMINAL_STATUSES, ORDER_FILTER_TYPE, ORDER_TERMINAL_STATUSES, PARCEL_NAME_OVERRIDE, PARCEL_TERMINAL_STATUSES } from './constants';

export function requestDocumentDownload(documentId) {
    const url = `${Config.api_base}/document/${documentId}/file?attachment`;
    
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            window.open(url);
            resolve(true);
        }, 500);
    });
};

export function formatAddress({ street, street_additional, city, state, zipcode }, lineBreak = false, shortZip = true) {
    // lineBreak: if true, will require rendering component to include "white-space: pre-line" css
    // shortZip: if true, will conver 9 digit zip to 5 digit zip

    const formattedZip = shortZip ? zipcode.substring(0, 5) : zipcode;  // NOTE: might cause issues outside of us zips
    const streetStr = _.join(_.compact([street, street_additional]), ' ');
    const stateZipStr = _.join(_.compact([state, formattedZip]), ' ');
    const cityStateZipStr = _.join(_.compact([city, stateZipStr]), ', ');

    if (lineBreak) {
        return `${streetStr}\n ${cityStateZipStr}`;
    }
    return `${streetStr}, ${cityStateZipStr}`;
}

export function formatShortAddress({ street,  street_additional}) {
    return _.join(_.compact([street, street_additional]), ' ');
}

export function formatPhone(rawPhone) {
    // expected raw format: +12223334444
    // expected return format: +1 (222) 333-4444
    if (rawPhone.length !== 12) {
        console.error("invalid raw phone format provided");
        return rawPhone;
    }

    const countryCode = rawPhone.substring(0, 2);
    const areaCode = rawPhone.substring(2, 5);
    const prefix = rawPhone.substring(5, 8);
    const lineNumber = rawPhone.substring(8);
    return `${countryCode} (${areaCode}) ${prefix}-${lineNumber}`
}

export function unmaskPhone(maskedPhone) {
    // ex: +1 (222) 333-4444 -> +12223334444
    return _.replace(maskedPhone, /[\s()-]/g, '');
}

export function convertArrayCase(rawArray, convertCase = _.snakeCase) {
    return _.map(rawArray, (item) => {
        return convertPayloadCase(item, convertCase);
    });
}

export function convertDictCase(rawDict, convertCase = _.snakeCase) {
    const caseConvertedData = {};
    _.forEach(rawDict, (value, key) => {
        caseConvertedData[convertCase(key)] = convertPayloadCase(value, convertCase);
    });
    return caseConvertedData;
}

export function convertPayloadCase(rawPayload, convertCase = _.snakeCase) {
    if (_.isObject(rawPayload)) {
        if (_.isArray(rawPayload)) {
            return convertArrayCase(rawPayload, convertCase);
        }
        return convertDictCase(rawPayload, convertCase);
    } else {
        return rawPayload;
    }
}

export function findParcelInOrder(order, parcelId) {
    return _.find(order.parcels, function (o) {
        return o.id === parcelId;
    });
}

export function findChildParcelsInOrder(order, parcelId) {
    return _.filter(order.parcels, function (o) {
        return o.parent_parcel_id === parcelId;
    });
}

export function findOrderParcelByType(order, parcelType) {
    return _.find(order.parcels, function (o) {
        return o.type === parcelType;
    });
}

export function findChildParcelsByType(order, parcelId, parcelTypeList = []) {
    return _.filter(order.parcels, function (o) {
        return o.parent_parcel_id === parcelId && _.includes(parcelTypeList, o.type);
    });
}

export function getInitials(firstName, lastName) {
    return `${firstName[0]}${lastName[0]}`
}

export const convertDateRangeToTimestamp = (dateRange) => {
    const timestampRange = [];
    _.forEach(dateRange, (date) => {
        timestampRange.push(getUnixTime(date));
    });
    
    return timestampRange;
}

export function formatISODate(rawDate, to_format='MM/dd/yyyy') {
    return format(parseISO(rawDate), to_format)
}

export const copyToClipboard = (text) => {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            navigator.clipboard.writeText(text);
        } else {
            alert("Clipboard access not granted!");
        }
    });
}

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


export const ORDER_FILTER_FUNC_MAP = {
    [ORDER_FILTER_TYPE.search]: (order, filterValue) => {
        if (
            !_.includes(order.id, filterValue) &&
            !_.includes(order.qualia_order_number, filterValue) &&
            !_.includes(_.toLower(formatAddress(order.property_address)), _.toLower(filterValue)) &&
            !_.includes(order.close_date, filterValue)
        ) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.transactionType]: (order, filterValue) => {
        if (order.transaction_type !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.orderType]: (order, filterValue) => {
        // Handles either a single order type or an array of order types
        if (_.isArray(filterValue)) {
            if (!_.includes(filterValue, order.order_type)) {
                return false;
            }
        } else if (order.order_type !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.workflowType]: (order, filterValue) => {
        if (order.title_workflow !== filterValue) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.orderStatus]: (order, filterValue) => {
        // Handles either a single order status or an array of order statuses
        const lowerCaseOrderStatus = _.lowerCase(order.status);

        if (_.isArray(filterValue)) {
            const matchLowercaseFilterValue = _.find(filterValue, (value) => _.lowerCase(value) === lowerCaseOrderStatus);
            if (!matchLowercaseFilterValue) {
                return false;
            }
        } else if (lowerCaseOrderStatus !== _.lowerCase(filterValue)) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateStatus]: (order, filterValue) => {
        // TODO rethink
        const nullValue = filterValue === 'null';
        if (nullValue) {
            if (order.close_date) {
                return false;
            }
        } else {
            const boolValue = filterValue === 'true';
            if (order.confirmed_close_date !== boolValue) {
                return false;
            }
            if (!boolValue && !order.close_date) {
                return false;
            }
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateGreaterThan]: (order, filterValue) => {
        // NOTE: filter value should be a date
        const closeDate = parseISO(order.close_date)

        // If close date is before provided date, remove order
        if (isBefore(closeDate, filterValue)) {
            return false;
        }

        return true;
    },
    [ORDER_FILTER_TYPE.closeDateRange]: (order, filterValue) => {
        if (!filterValue || (!filterValue[0] && !filterValue[1])) {
            return;
        }

        const interval = {
            start: filterValue[0] || new Date(null),    // null will start at unix beginning in 1969
            end: filterValue[1] || addYears(new Date(), 10) // if no end date, add 10 years from now
        }
    
        // Ensure date is after the start date and before the end date
        if (!isWithinInterval(parseISO(order.close_date), interval)) {
            return false;
        }

        return true
    },
    [ORDER_FILTER_TYPE.disbursementDateRange]: (order, filterValue) => {
        if (!filterValue || (!filterValue[0] && !filterValue[1])) {
            return;
        }

        const interval = {
            start: filterValue[0] || new Date(null),    // null will start at unix beginning in 1969
            end: filterValue[1] || addYears(new Date(), 10) // if no end date, add 10 years from now
        }
    
        // Ensure date is after the start date and before the end date
        if (!isWithinInterval(parseISO(order.disbursement_date), interval) || !order.disbursement_date) {
            return false;
        }
    
        return true;
    },
};

// An array declared here will maintain a stable reference rather than be re-created again
const emptyOrdersArray = []

export const filterOrders = (orders, filters) => {
    // NOTE: This function should be memoized to prevent unnecessary re-renders
    // Can be done in a react component using useMemo
    return orders?.filter((order) => {
        let includeOrder = true;

        // For each order, iterate over each provided filter
        _.forEach(filters, (filterValue, localFilterType) => {
            // If filter value and filter function exist, run filter function against order
            if (!!filterValue && ORDER_FILTER_FUNC_MAP[localFilterType]) {
                if (_.isArray(filterValue) && _.isEmpty(filterValue)) {
                    // If filter value is an array and is empty, skip filter
                    return;
                }

                // Filter functions return true, false or nothing (incorrect values provided)
                const include = ORDER_FILTER_FUNC_MAP[localFilterType](order, filterValue);
                if (include === false) {
                    // If filter function return is false, set includeOrder to false
                    includeOrder = false;
                    // And return false to exit filter loop
                    return false;
                }
            }
        });


        return includeOrder;
     }) ?? emptyOrdersArray
}

export const formatParcelType = (parcelType) => {
    const parcelTypeOverride = _.get(PARCEL_NAME_OVERRIDE, parcelType)
    if (parcelTypeOverride) {
        return parcelTypeOverride
    }
    
    return _.startCase(parcelType)
}

export const isEntityStatusTerminal = (entityType, status) => {
    const entityStatusMap = {
        [ENTITY_TYPE.order]: ORDER_TERMINAL_STATUSES,
        [ENTITY_TYPE.parcel]: PARCEL_TERMINAL_STATUSES,
        [ENTITY_TYPE.lien]: LIEN_TERMINAL_STATUSES,
        [ENTITY_TYPE.action]: ["Complete"]
    }

    const entityTerminalStatuses = entityStatusMap[entityType]

    if (entityTerminalStatuses) {
        return _.includes(entityTerminalStatuses, status)
    }

    // TODO raise error? 
    return false    
}

export const formatDocumentType = (documentType) => {
    const overrideName = _.get(DOCUMENT_TYPE_NAME_OVERRIDE, documentType)
    if (overrideName) {
        return overrideName;
    }
    
    return _.startCase(documentType);
}