import AddIcon from '@mui/icons-material/Add';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { Box, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery, useGetOrderQuery } from '../../api/order';
import { PARTY_ROLE_MAP, TRANSACTION_PARTY_TYPE } from '../../helpers/constants';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

const OrderContactGroupItemSkeleton = () => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{
                p: 0,
            }}
        >
            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                <Skeleton type="text" height={10} width="60%" />
                <Skeleton type="text" height={10} width="30%" />
            </Stack>

            <Skeleton variant="circular" width={24} height={24} />

            <Skeleton variant="circular" width={24} height={24} />
        </Stack>
    );
};

const OrderContactGroupItem = ({ name, secondary, phone, email }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                p: 0,
            }}
        >
            <Stack direction="column">
                <Typography variant="h6">{name}</Typography>
                {secondary && (
                    <Typography variant="h8" color="text.secondary">
                        {secondary}
                    </Typography>
                )}
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: -1 }}>
                <IconButton aria-label="email" disabled={!email}>
                    <EmailIcon />
                </IconButton>
                <IconButton aria-label="call" disabled={!phone}>
                    <CallIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
};

const OrderContactGroupSkeleton = () => {
    return (
        <Paper
            variant="outlined"
            sx={{
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
            }}
        >
            <Skeleton type="text" height={10} width="20%" />

            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <OrderContactGroupItemSkeleton />
                <OrderContactGroupItemSkeleton />
            </Stack>
        </Paper>
    );
};

const OrderContactRoleGroup = ({ members, role }) => {
    return (
        <Box>
            <Typography
                fontSize={14}
                fontWeight={500}
                textTransform="uppercase"
                sx={{
                    color: '#797979',
                }}
            >
                {_.startCase(role)}
            </Typography>

            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                {_.map(members, (roleMember) => {
                    // const onTitle = member.on_title ? ' • Title' : '';
                    // const onLoan = member.on_loan ? ' • Loan' : '';
                    // const secondaryText = `${_.startCase(member.role)}${onTitle}${onLoan}`;
                    // TODO determine what secondary text to display as role is kind of redundant
                    const secondaryText = false;
                    const member = roleMember.member;
                    const name = member.name;
                    const email = _.get(member, 'contact.email');
                    const phone = _.get(member, 'contact.phone');
                    return (
                        <OrderContactGroupItem
                            key={member.id}
                            name={name}
                            secondary={secondaryText}
                            email={email}
                            phone={phone}
                        />
                    );
                })}
            </Stack>
        </Box>
    );
};

const OrderContactGroup = ({ members, party, loading = false }) => {
    // Reduce list of members down to those that match the party and role
    const partyMembers = useMemo(
        () =>
            _.filter(members, (member) => {
                return member.party === party;
            }),
        [members, party]
    );

    // Break each party member into array of their associated roles
    // TODO cleanup initial partyMembers filter as it is currently not directly needed
    const partyRoleMemberMap = useMemo(() => {
        const partyRoleMembers = {};

        // Set up base role key -> empty array
        _.forEach(PARTY_ROLE_MAP[party], (role) => {
            partyRoleMembers[role] = [];
        });

        // Add members to their associated role array
        // TODO do we care about sorting the members? What grouping rules would we use? Currently uses order of members in the parent array
        _.forEach(partyMembers, (member) => {
            partyRoleMembers[member.role].push(member);
        });

        return partyRoleMembers;
    }, [partyMembers, party, PARTY_ROLE_MAP]);

    if (loading) {
        return <OrderContactGroupSkeleton />;
    }

    if (partyMembers.length === 0) {
        return false;
    }

    return (
        <Paper
            variant="outlined"
            sx={{
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack direction="column" spacing={4}>
                {_.map(partyRoleMemberMap, (roleMembers, role) => {
                    if (roleMembers.length === 0) {
                        return false;
                    }

                    return <OrderContactRoleGroup key={role} members={roleMembers} role={role} />;
                })}
            </Stack>
        </Paper>
    );
};

const OrderContacts = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        orderId
    );

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const loading = orderLoading || orderMembersLoading;

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    // console.log(orderMembers);

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.lightGray,
                flex: 1,
                // borderTopLeftRadius: (theme) => theme.spacing(2),
                // borderTopRightRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 2,
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ zIndex: 1 }}>
                    <Typography variant="h4" fontWeight={500}>
                        Contacts
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton color="default">
                        <AddIcon />
                    </IconButton>
                </Stack>

                <OrderContactGroup members={orderMembers} party={TRANSACTION_PARTY_TYPE.buyer} loading={loading} />

                <OrderContactGroup members={orderMembers} party={TRANSACTION_PARTY_TYPE.seller} loading={loading} />

                <OrderContactGroup members={orderMembers} party={TRANSACTION_PARTY_TYPE.borrower} loading={loading} />

                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                        borderRadius: (theme) => theme.spacing(2),
                        minHeight: '200px',
                    }}
                >
                    <Typography
                        fontSize={14}
                        fontWeight={500}
                        textTransform="uppercase"
                        sx={{
                            color: '#797979',
                        }}
                    >
                        Escrow Team
                    </Typography>
                    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                        <OrderContactGroupItem name="Alyssa Olvera" secondary="Escrow Assistant" />
                        <OrderContactGroupItem name="Amanda Blanco" secondary="Escrow Officer, Closing" />
                        <OrderContactGroupItem name="Caitlin Davis" secondary="Escrow Officer, Title" />
                        <OrderContactGroupItem name="Palak Shah" secondary="CEO & Escrow Officer" />
                    </Stack>
                </Paper>
            </Stack>
        </Box>
    );
};

OrderContacts.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderContacts;
