import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_TYPE, SIGNING_PARCEL_TYPES } from '../../../../helpers/constants';
import { findChildParcelsByType } from '../../../../helpers/utils';

export function useClosingParcelSchedulingData({ order, parcel }) {
    const schedulingParcelList = findChildParcelsByType(order, parcel.id, [PARCEL_TYPE.scheduling]);
    // NOTE: should only ever be one scheduling parcel, but we can assume it always exists
    const schedulingParcel = _.first(schedulingParcelList);

    return {
        schedulingParcel,
    };
}

export function useClosingParcelSigningData({ order, parcel }) {
    const signingParcelList = findChildParcelsByType(order, parcel.id, SIGNING_PARCEL_TYPES);
    const sortedSigningParcelList = _.sortBy(signingParcelList, 'created_datetime');
    // NOTE: there can be multiple signing parcels if rescheduled or cancelled
    // After sorting, we can assume the last value is the most recent signing parcel
    // However, it might not be 'active' if scheduling is ongoing (i.e. it was cancelled)
    const signingParcel = _.last(sortedSigningParcelList);

    return {
        signingParcelList: sortedSigningParcelList,
        signingParcel,
    };
}

function useClosingParcelData({ order, parcel }) {
    const { schedulingParcel } = useClosingParcelSchedulingData({ order, parcel });
    const { signingParcelList, signingParcel } = useClosingParcelSigningData({ order, parcel });

    return {
        schedulingParcel,
        signingParcel,
        signingParcelList,
    };
}

useClosingParcelData.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default useClosingParcelData;
