import { Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderDocumentsByType } from '../../../../api/document';
import Config from '../../../../config';
import { DOCUMENT_TYPE, PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType, formatDocumentType } from '../../../../helpers/utils';
import { Button } from '../../../common/styled';
import { calculateRequired, calculateRequiredCompleteDate } from './util';

export const PURCHASE_PRIMARY_DOCUMENT_TYPE = DOCUMENT_TYPE.HOA_resale_certificate;
export const PURCHASE_PRIMARY_DOCUMENT_NAME = formatDocumentType(PURCHASE_PRIMARY_DOCUMENT_TYPE);
export const REFINANCE_PRIMARY_DOCUMENT_TYPE = DOCUMENT_TYPE.HOA_statement_of_account;
export const REFINANCE_PRIMARY_DOCUMENT_NAME = formatDocumentType(REFINANCE_PRIMARY_DOCUMENT_TYPE);

// TODO can standardize this component
const HoaPrimaryDocumentDownload = ({ document }) => {
    if (!document) {
        return null;
    }

    const viewUrl = `${Config.api_base}/order/${document.order_id}/document/${document.id}/file`;

    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
                color="primary"
                variant="contained"
                aria-label="download"
                component="a"
                href={viewUrl}
                target="_blank"
                disableElevation
                sx={{
                    minWidth: '160px',
                }}
            >
                Download
            </Button>
        </Stack>
    );
};

const HoaPurchasePrimaryDocument = ({ order, parcel }) => {
    const {
        data: primaryDocuments,
        isError: primaryDocumentsError,
        isLoading: primaryDocumentsLoading,
    } = useGetOrderDocumentsByType({
        orderId: order.id,
        documentTypeList: [PURCHASE_PRIMARY_DOCUMENT_TYPE],
    });

    const hoaRequired = calculateRequired(order, parcel);
    const placeholderText = hoaRequired ? 'TBD' : 'N/A';

    const objectionPeriodEndDate = calculateRequiredCompleteDate(order, parcel);
    const now = new Date();
    const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

    const formattedObjectionPeriodEndDate = objectionPeriodEndDate
        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    // TODO handle errors or eat exception?
    // TODO use received_date as confirmation that document has been provided even when user doesn't have access to it?

    // NOTE: if there are multiple documents of the same type, we will need to handle that
    // In some cases that is valid and in other cases we might want to only display one.
    // EX: separate check for EM and OF
    const primaryDocument = _.get(primaryDocuments, '0', null);

    return (
        <Stack direction="column" spacing={4}>
            <Typography variant="body2" color="text.primary">
                The <b>HOA Resale Certificate</b> is a document that provides information about the property being sold
                and the seller's relationship with the homeowners association.
                <br />
                <br />
                It is a due diligence report for the buyer that helps them understand how their property is affected by
                being in an HOA.
                <br />
                <br />
                As outlined in the contract, the buyer has until <b>{formattedObjectionPeriodEndDate}</b> to raise any
                objections.
            </Typography>

            <HoaPrimaryDocumentDownload document={primaryDocument} />
        </Stack>
    );
};

const HoaRefinancePrimaryDocument = ({ order, parcel }) => {
    const {
        data: primaryDocuments,
        isError: primaryDocumentsError,
        isLoading: primaryDocumentsLoading,
    } = useGetOrderDocumentsByType({
        orderId: order.id,
        documentTypeList: [REFINANCE_PRIMARY_DOCUMENT_TYPE],
    });

    const hoaRequired = calculateRequired(order, parcel);
    const placeholderText = hoaRequired ? 'TBD' : 'N/A';

    const objectionPeriodEndDate = calculateRequiredCompleteDate(order, parcel);
    const now = new Date();
    const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

    const formattedObjectionPeriodEndDate = objectionPeriodEndDate
        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    // TODO handle errors or eat exception?
    // TODO use received_date as confirmation that document has been provided even when user doesn't have access to it?

    // NOTE: if there are multiple documents of the same type, we will need to handle that
    // In some cases that is valid and in other cases we might want to only display one.
    // EX: separate check for EM and OF
    const primaryDocument = _.get(primaryDocuments, '0', null);

    return (
        <Stack direction="column" spacing={4}>
            <Typography variant="body2" color="text.primary">
                The <b>HOA Statement of Account</b> is a document that provides information about the property and any
                outstanding obligations the owner may have with the homeowners association.
                <br />
                <br />
                It is a due diligence report that outlines or clears any unpaid fees that will need to be addressed
                before the refinance can be completed.
            </Typography>

            <HoaPrimaryDocumentDownload document={primaryDocument} />
        </Stack>
    );
};

const HoaPrimaryDocument = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);
    const hoaRequired = calculateRequired(order, parcel);
    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;

    if (!isPurchase) {
        return <HoaRefinancePrimaryDocument order={order} parcel={parcel} />;
    }

    return <HoaPurchasePrimaryDocument order={order} parcel={parcel} />;
};

HoaPrimaryDocument.propTypes = {
    order: PropTypes.object.isRequired,
};

export default HoaPrimaryDocument;
