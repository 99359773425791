import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetOrderQuery } from '../../../../api/order';
import { IMPORTANT_DASHBOARD_EVENTS, IMPORTANT_EVENT_TYPES } from '../../../../helpers/orderEvent';
import useDeviceDetails, { SCREEN_SIZE } from '../../../common/hooks/useDeviceDetails';
import { AccordionBox } from '../../../common/styled';
import SubSectionAccordion from '../common/SubSectionAccordion';
import SubSectionPaper from '../common/SubSectionPaper';
import SurveyAdditionalDocuments from './SurveyAdditionalDocuments';
import SurveyDates from './SurveyDates';
import SurveyFees from './SurveyFees';
import SurveyInfo from './SurveyInfo';
import SurveyNotRequired from './SurveyNotRequired';
import SurveyPrimaryDocument, { PRIMARY_DOCUMENT_NAME } from './SurveyPrimaryDocument';

const sectionType = IMPORTANT_EVENT_TYPES.survey;
const sectionEvent = IMPORTANT_DASHBOARD_EVENTS[sectionType];

const SUB_SECTION_TYPES = {
    info: 'info',
    important_dates: 'important_dates',
    fees_and_charges: 'fees_and_charges',
    primary_document: 'primary_document',
    additional_documents: 'additional_documents',
};

const SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.info]: {
        title: 'About Survey',
        Icon: <InfoOutlinedIcon color="default" />,
        renderContent: (props) => <SurveyInfo {...props} />,
    },
    [SUB_SECTION_TYPES.important_dates]: {
        title: 'Important Dates',
        Icon: <CalendarMonthIcon color="default" />,
        renderContent: (props) => <SurveyDates {...props} />,
    },
    [SUB_SECTION_TYPES.fees_and_charges]: {
        title: 'Fees & Charges',
        Icon: <AttachMoneyIcon color="default" />,
        renderContent: (props) => <SurveyFees {...props} />,
    },
    [SUB_SECTION_TYPES.primary_document]: {
        title: PRIMARY_DOCUMENT_NAME,
        Icon: <DescriptionOutlinedIcon color="default" />,
        renderContent: (props) => <SurveyPrimaryDocument {...props} />,
    },
    [SUB_SECTION_TYPES.additional_documents]: {
        title: 'Additional Documents',
        Icon: <FolderOutlinedIcon color="default" />,
        renderContent: (props) => <SurveyAdditionalDocuments {...props} />,
    },
};

const SurveySectionGrid = ({ order }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} key={subSectionType}>
                            <SubSectionPaper
                                order={order}
                                sectionType={sectionType}
                                subSectionConfig={subSectionConfig}
                                subSectionType={subSectionType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

const SurveySectionAccordion = ({ order }) => {
    const [expanded, setExpanded] = useState(_.keys(SUB_SECTION_CONFIG));

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded && !expanded.includes(panel)) {
            setExpanded([...expanded, panel]);
        } else if (!isExpanded && expanded.includes(panel)) {
            const expandedCopy = [...expanded];
            expandedCopy.splice(expandedCopy.indexOf(panel), 1);
            setExpanded(expandedCopy);
        }
    };

    return (
        <AccordionBox>
            {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => {
                return (
                    <SubSectionAccordion
                        key={subSectionType}
                        order={order}
                        expanded={expanded.includes(subSectionType) ? subSectionType : null}
                        handleChange={handleChange}
                        sectionType={sectionType}
                        subSectionConfig={subSectionConfig}
                        subSectionType={subSectionType}
                    />
                );
            })}
        </AccordionBox>
    );
};

const SurveySection = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h4" fontWeight={500}>
                    Survey
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
            </Stack>

            <SurveyNotRequired order={order} />

            {isExtraSmallScreen ? <SurveySectionAccordion order={order} /> : <SurveySectionGrid order={order} />}
        </Stack>
    );
};

SurveySection.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default SurveySection;
