import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { AppBar, Box, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { AnimatePresence, motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { useGetOrderQuery } from 'api/order';
import useDeviceDetails, { SCREEN_SIZE } from 'components/common/hooks/useDeviceDetails';
import Notification from './Notification';
import Profile from './Profile';
import Search from './Search';

export const TOPBAR_HEIGHT = 89;
export const SCROLL_BREAKPOINT = 160;

export const HeaderToolbar = (props) => {
    const { size, isCompact } = useDeviceDetails();
    const isSmallScreen = size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && isCompact;

    return (
        <Toolbar
            {...props}
            sx={{
                ...props.sx,
                '&.MuiToolbar-regular': {
                    minHeight: (theme) => theme.spacing(isExtraSmallScreen ? 8 : 12),
                },
            }}
        />
    );
};

const ScrollContainer = (props) => {
    const { scrollY } = useScroll();
    const background = useTransform(scrollY, [0, SCROLL_BREAKPOINT], ['rgba(0,0,0,0)', 'rgba(0,0,0,0.15)']);
    return (
        <motion.div
            {...props}
            style={{
                backgroundColor: background,
                overflow: 'hidden',
            }}
        />
    );
};

const OrderLogo = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    return (
        <motion.div
            initial={{
                y: '100%',
                opacity: 0,
            }}
            animate={{
                y: 0,
                opacity: 1,
                transition: {
                    duration: 0.05,
                    delay: 0.05,
                },
            }}
            exit={{
                y: '100%',
                opacity: 0,
                transition: {
                    duration: 0.05,
                },
            }}
            style={{
                maxWidth: '60%',
            }}
        >
            <Typography variant="h5" color="inherit" fontSize="18px" noWrap>
                {_.get(order, 'property_address.street')}
            </Typography>
        </motion.div>
    );
};

const HudlyLogo = ({ isExtraSmallScreen }) => {
    return (
        <motion.div
            initial={{
                y: '-100%',
                opacity: 0,
            }}
            animate={{
                y: 0,
                opacity: 5,
                transition: {
                    duration: 0.05,
                    delay: 0.05,
                },
            }}
            exit={{
                y: '-100%',
                opacity: 0,
                transition: {
                    duration: 0.05,
                },
            }}
        >
            <RouterLink to="/">
                <img
                    alt="Hudly Title Logo"
                    src="/logo-white-transparent.png"
                    style={{
                        width: isExtraSmallScreen ? '100px' : '144px',
                    }}
                />
            </RouterLink>
        </motion.div>
    );
};

const HeaderLogo = ({ isExtraSmallScreen }) => {
    const { pathname } = useLocation();
    const { scrollY } = useScroll();

    const [isScrollBreakpoint, setIsScrollBreakpoint] = useState(false);

    const isOrderPage = matchPath(
        {
            path: '/escrow/:orderId/*',
            exact: false,
        },
        pathname
    );

    useMotionValueEvent(scrollY, 'change', (latest) => {
        // console.log('Page scroll: ', latest);
        if (latest >= SCROLL_BREAKPOINT) {
            setIsScrollBreakpoint(true);
        } else if (latest == 0) {
            setIsScrollBreakpoint(false);
        }
    });
    // console.log('isOrderPage', isOrderPage);

    const showOrderHeader = isOrderPage && isScrollBreakpoint;

    return (
        <AnimatePresence mode="wait" initial={false}>
            {showOrderHeader && <OrderLogo key="orderLogo" orderId={_.get(isOrderPage, 'params.orderId')} />}
            {!showOrderHeader && <HudlyLogo key="hudlyLogo" isExtraSmallScreen={isExtraSmallScreen} />}
        </AnimatePresence>
    );
};

const Topbar = (props) => {
    const { sidebarOpen, showSidebarToggle, toggleSidebar } = props;

    const deviceDetails = useDeviceDetails();
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;
    const isExtraSmallScreen = isSmallScreen && deviceDetails.isCompact;

    return (
        <AppBar
            elevation={0}
            sx={{
                // background: 'rgba(0,0,0,0)',
                zIndex: 1300, // push above sidebar
                background: (theme) => theme.palette.hudly.main,
                // backgroundImage: 'url(./egg-shell.png)',
            }}
            position="fixed"
        >
            <Box component={ScrollContainer}>
                <HeaderToolbar
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: isExtraSmallScreen ? 'space-between' : 'flex-start',
                    }}
                >
                    {showSidebarToggle && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleSidebar}
                            edge="start"
                            sx={{
                                mr: 2,
                            }}
                        >
                            {sidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
                        </IconButton>
                    )}

                    <HeaderLogo isExtraSmallScreen={isExtraSmallScreen} />

                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            ...(!isExtraSmallScreen && {
                                flex: 1,
                            }),
                        }}
                        spacing={2}
                    >
                        <Box sx={{ flex: 1 }} />

                        {!isSmallScreen && (
                            <>
                                <Search />

                                <Notification />

                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        marginTop: (theme) => `${theme.spacing(0.5)} !important`,
                                        marginBottom: (theme) => `${theme.spacing(0.5)} !important`,
                                        borderColor: (theme) => theme.palette.background.default,
                                    }}
                                    flexItem
                                />
                            </>
                        )}

                        <Profile />
                    </Stack>
                </HeaderToolbar>
            </Box>
        </AppBar>
    );
};

Topbar.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    showSidebarToggle: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};

export default Topbar;
