import _ from 'lodash';
import rootApi from './root';

export const USER_TAG = _.toUpper('user');

export const userApi = rootApi.injectEndpoints({
    reducerPath: 'userApi',
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => 'user',
            providesTags: [USER_TAG],
        }),
    }),
    overrideExisting: false,
})


export const { useGetUserQuery } = userApi