import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { HoaObjectionDate, HoaSentDate } from './HoaDates';
import { HoaInfoShort } from './HoaInfo';

function HoaSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <HoaSentDate order={order} parcel={parcel} />

                <HoaObjectionDate order={order} parcel={parcel} />
            </Stack>

            <HoaInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={PARCEL_TYPE.hoa} />
        </Stack>
    );
}

HoaSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default HoaSnapshot;
