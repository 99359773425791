import { List, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderDocumentsByParcelType } from '../../../../api/document';
import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import AdditionalDocumentItem from '../common/AdditionalDocumentItem';
import { PRIMARY_DOCUMENT_TYPE } from './TitleCommitmentPrimaryDocument';

const TitleExamAdditionalDocuments = ({ order }) => {
    const {
        data: parcelDocuments,
        isError: parcelDocumentsError,
        isLoading: parcelDocumentsLoading,
    } = useGetOrderDocumentsByParcelType({
        orderId: order.id,
        parcelTypeList: [PARCEL_TYPE.title_exam, PARCEL_TYPE.title_clearing],
    });

    const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_exam);

    const additionalDocuments = _.filter(parcelDocuments, (doc) => {
        // Remove any primary documents from the list
        return doc.type !== PRIMARY_DOCUMENT_TYPE;
    });

    if (!additionalDocuments || additionalDocuments.length === 0) {
        return (
            <Stack spacing={4}>
                <Typography variant="body2">No additional documents currently available</Typography>
            </Stack>
        );
    }

    return (
        <List disablePadding>
            {_.map(additionalDocuments, (additionalDocument) => {
                return <AdditionalDocumentItem key={additionalDocument.id} document={additionalDocument} />;
            })}
        </List>
    );
};

TitleExamAdditionalDocuments.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TitleExamAdditionalDocuments;
