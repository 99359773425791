import InfoIcon from '@mui/icons-material/Info';
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { USDollar, findOrderParcelByType } from '../../../../helpers/utils';
import SectionTooltip from '../common/SectionTooltip';

const SurveyFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.survey);
    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;
    const placeholderText = 'TBD';

    const surveyFee = _.get(parcel, 'additional_data.survey_fee', null);
    const surveyPayer = _.get(parcel, 'additional_data.survey_payer', null);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Survey Fee:</Typography>
                    <Typography color="text.primary">
                        {surveyFee ? USDollar.format(surveyFee) : placeholderText}
                    </Typography>
                </Stack>

                {isPurchase && (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                            <Typography color="text.secondary">Survey Payer:</Typography>
                            <SectionTooltip
                                title="Which party is contractually responsible for the cost of the survey."
                                placement="bottom"
                                boundingElement={boundingElement}
                            >
                                <InfoIcon
                                    fontSize="small"
                                    sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '1rem' }}
                                />
                            </SectionTooltip>
                        </Stack>
                        <Typography color="text.primary">
                            {surveyPayer ? _.startCase(surveyPayer) : placeholderText}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

SurveyFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default SurveyFees;
