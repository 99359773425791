import { Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import {
    calculateObjectionDate,
    calculateTitleCommitmentDate,
    calculateTitleExamETA,
    calculateTitleExamOrderedDate,
    calculateTitleExamReceivedDate,
} from './util';

export const TitleCommitmentSentDate = ({ order, parcel }) => {
    const titleCommitmentDate = calculateTitleCommitmentDate(order, parcel);

    const placeholderText = 'TBD';
    const formattedTitleCommitmentDate = titleCommitmentDate
        ? format(titleCommitmentDate, 'MMM d, yyyy')
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Title Commitment Date:</Typography>
            <Typography color="text.primary">{formattedTitleCommitmentDate}</Typography>
        </Stack>
    );
};

TitleCommitmentSentDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const TitleCommitmentObjectionDate = ({ order, parcel }) => {
    // TODO is object only for purchase orders?
    const objectionPeriodEndDate = calculateObjectionDate(order, parcel);

    const placeholderText = 'TBD';
    const now = new Date();
    const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';
    const formattedObjectionDate = objectionPeriodEndDate
        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Objection Period End Date:</Typography>
            <Typography color="text.primary">{formattedObjectionDate}</Typography>
        </Stack>
    );
};

TitleCommitmentObjectionDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const TitleExamDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.title_exam);

    const isPurchase = order.transaction_type === TRANSACTION_TYPE.purchase;
    const placeholderText = 'TBD';

    const titleExamOrderedDate = calculateTitleExamOrderedDate(order, parcel);
    const titleExamETA = calculateTitleExamETA(order, parcel);
    const titleExamReceivedDate = calculateTitleExamReceivedDate(order, parcel);
    const titleCommitmentDate = calculateTitleCommitmentDate(order, parcel);
    const objectionPeriodEndDate = calculateObjectionDate(order, parcel);

    const formattedTitleExamOrderedDate = titleExamOrderedDate
        ? format(titleExamOrderedDate, 'MMM d, yyyy')
        : placeholderText;
    const formattedTitleExamETA = titleExamETA ? format(titleExamETA, 'MMM d, yyyy') : placeholderText;
    const formattedTitleExamReceivedDate = titleExamReceivedDate
        ? format(titleExamReceivedDate, 'MMM d, yyyy')
        : placeholderText;

    const showETA = titleExamOrderedDate && !titleExamReceivedDate;
    const showReceivedDate = titleExamOrderedDate && titleExamReceivedDate;
    const showObjectionDate = titleCommitmentDate && objectionPeriodEndDate;
    const showCommitmentDate = titleCommitmentDate;

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Ordered Date:</Typography>
                <Typography color="text.primary">{formattedTitleExamOrderedDate}</Typography>
            </Stack>

            {showETA && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">ETA:</Typography>
                    <Typography color="text.primary">{formattedTitleExamETA}</Typography>
                </Stack>
            )}

            {showReceivedDate && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Received Date:</Typography>
                    <Typography color="text.primary">{formattedTitleExamReceivedDate}</Typography>
                </Stack>
            )}

            {showCommitmentDate && <TitleCommitmentSentDate order={order} parcel={parcel} />}

            {showObjectionDate && <TitleCommitmentObjectionDate order={order} parcel={parcel} />}
        </Stack>
    );
};

TitleExamDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TitleExamDates;
