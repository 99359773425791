import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const CompoundIcon = ({ PrimaryIcon, SecondaryIcon, primaryProps = {}, secondaryProps = {}, ...rest }) => {
    // overlay secondary icon on top of primary icon
    return (
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={0} sx={{ position: 'relative' }}>
            <PrimaryIcon
                {...primaryProps}
                sx={{
                    ..._.get(primaryProps, 'sx', {}),
                }}
            />
            <SecondaryIcon
                {...secondaryProps}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    ..._.get(secondaryProps, 'sx', {}),
                }}
            />
        </Stack>
    );
};

CompoundIcon.propTypes = {
    PrimaryIcon: PropTypes.object.isRequired,
    SecondaryIcon: PropTypes.object.isRequired,
    primaryProps: PropTypes.object,
    secondaryProps: PropTypes.object,
};

export default CompoundIcon;
