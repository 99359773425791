import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, ListItemAvatar, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

const SubSectionAccordion = ({ order, expanded, handleChange, sectionType, subSectionConfig, subSectionType }) => {
    const { title, Icon, renderContent, renderTitle } = subSectionConfig;

    const Title = title ? <ListItemText primary={title} /> : renderTitle({ order });
    const Content = renderContent({ order });

    return (
        <Accordion expanded={expanded === subSectionType} onChange={handleChange(subSectionType)} variant="outlined">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${sectionType}-section-${subSectionType}-content`}
                id={`${sectionType}-section--${subSectionType}-header`}
            >
                <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>{Icon}</ListItemAvatar>
                {Title}
            </AccordionSummary>
            <AccordionDetails>{Content}</AccordionDetails>
        </Accordion>
    );
};

SubSectionAccordion.propTypes = {
    order: PropTypes.object.isRequired,
    expanded: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    sectionType: PropTypes.string.isRequired,
    subSectionType: PropTypes.string.isRequired,
    subSectionConfig: PropTypes.object.isRequired, // TODO define shape of
};

export default SubSectionAccordion;
