import { parseISO } from 'date-fns';
import _ from 'lodash';

import { ENTITY_TYPE } from '../../../../helpers/constants';
import { isEntityStatusTerminal } from '../../../../helpers/utils';


export function calculateComplete(order, parcel) {
    // returns true or false
    return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    // date of parcel complete status
    // TODO eventually record explicit date of contract reception and receipt of earnest money
    if (!this.calculateComplete(order, parcel)) {
        return null;
    }

    const completedDate = _.get(parcel, 'current_status.created_datetime', null);
    if (!completedDate) {
        return null;
    }

    return parseISO(completedDate);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj or null
    const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
    if (!requiredCompleteDate) {
        return null;
    }

    return parseISO(requiredCompleteDate);
}

export function calculateReceivedDate(order, parcel) {
    // earnest_money_received_date
    const receivedDate = _.get(parcel, 'additional_data.earnest_money_received_date', null);
    if (!receivedDate) {
        return null;
    }

    return parseISO(receivedDate);
}

