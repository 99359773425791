import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useUpdateOrderActionMutation } from 'api/order';
import ActionForm from 'components/Action/ActionForm';
import { HeaderToolbar } from 'components/layout/Topbar';

const OrderActionDrawerContent = ({ action, handleClose, orderId }) => {
    const [updateOrderAction, { isSubmitting }] = useUpdateOrderActionMutation();

    const handleUpdateAction = (updateData) => {
        return submitActionUpdate(updateData).then(
            (response) => {
                if (response) {
                    handleClose();
                } else {
                    console.error('Failed to update action');
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const submitActionUpdate = async (updateData) => {
        const updateActionData = { ...updateData };

        const { data: udpateResponseData } = await updateOrderAction({
            orderId: orderId,
            actionId: action.id,
            actionData: updateActionData,
        });

        if (udpateResponseData) {
            return true;
        } else {
            console.warn(`Failed to be update action ${action.id}`);
            return false;
        }
    };

    if (!action) {
        return false;
    }

    return (
        <Stack sx={{ p: 2 }} spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Typography variant="h5">{action.friendly_name}</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>

            <Stack direction="column" spacing={1}>
                <Typography variant="body1" color="text.secondary">
                    {action.description}
                </Typography>
            </Stack>

            <Stack>
                <ActionForm
                    action={action}
                    orderId={orderId}
                    isSubmitting={isSubmitting || false}
                    handleUpdateAction={handleUpdateAction}
                />
            </Stack>
        </Stack>
    );
};

function OrderActionDrawer({ action, handleClose, orderId }) {
    return (
        <SwipeableDrawer
            anchor="right"
            open={!!action}
            onOpen={() => console.log('prevent open')}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '240px',
                    width: '100%',
                },
            }}
            keepMounted
            disableSwipeToOpen
        >
            <HeaderToolbar />

            <OrderActionDrawerContent action={action} handleClose={handleClose} orderId={orderId} />
        </SwipeableDrawer>
    );
}

OrderActionDrawer.propTypes = {
    action: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.string,
};

export default OrderActionDrawer;
