import { CircularProgress, Paper, Stack } from '@mui/material';

const LoadingWidget = () => {
    return (
        <Paper
            elevation={2}
            sx={{
                width: '100%',
                borderRadius: (theme) => theme.spacing(4),
                padding: (theme) => theme.spacing(1),
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <CircularProgress color="primary" />
            </Stack>
        </Paper>
    );
};

export const LoadingScreen = () => {
    return (
        <Stack direction="column" alignItems="center" justifyContent="space-around" sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center">
                <LoadingWidget />
            </Stack>
        </Stack>
    );
};

export default LoadingWidget;
