import InfoIcon from '@mui/icons-material/Info';
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { USDollar, findOrderParcelByType } from '../../../../helpers/utils';
import SectionTooltip from '../common/SectionTooltip';

export const EarnestMoneyAmount = ({ parcel }) => {
    const earnestMoneyAmount = _.get(parcel, 'additional_data.earnest_money_amount', null);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Earnest Money:</Typography>
            <Typography color="text.primary">
                {earnestMoneyAmount ? USDollar.format(earnestMoneyAmount) : 'TBD'}
            </Typography>
        </Stack>
    );
};

export const OptionFeeAmount = ({ parcel }) => {
    const optionFeeAmount = _.get(parcel, 'additional_data.option_fee_amount', null);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Option Fee:</Typography>
            <Typography color="text.primary">{optionFeeAmount ? USDollar.format(optionFeeAmount) : 'TBD'}</Typography>
        </Stack>
    );
};

export const OptionFeeSalesCredit = ({ parcel, boundingElement }) => {
    const optionFeeSalesCredit = _.get(parcel, 'additional_data.option_fee_sales_credit', null);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                <Typography color="text.secondary">Sales Credit:</Typography>

                <SectionTooltip
                    title="Whether or not the option fee will be applied as a credit to the purchase price"
                    placement="bottom"
                    boundingElement={boundingElement}
                >
                    <InfoIcon
                        fontSize="small"
                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '1rem' }}
                    />
                </SectionTooltip>
            </Stack>
            <Typography color="text.primary">{optionFeeSalesCredit ? 'Yes' : 'No'}</Typography>
        </Stack>
    );
};

const EarnestMoneyFees = ({ order }) => {
    const boundingElement = useRef();
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <EarnestMoneyAmount parcel={parcel} />

            <OptionFeeAmount parcel={parcel} />

            <OptionFeeSalesCredit parcel={parcel} boundingElement={boundingElement} />
        </Stack>
    );
};

EarnestMoneyFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default EarnestMoneyFees;
