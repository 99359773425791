import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, ListItemText, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useGetOrderQuery } from '../../../../api/order';
import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { IMPORTANT_DASHBOARD_EVENTS, IMPORTANT_EVENT_TYPES } from '../../../../helpers/orderEvent';
import { findOrderParcelByType } from '../../../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../../../common/hooks/useDeviceDetails';
import { AccordionBox } from '../../../common/styled';
import SubSectionAccordion from '../common/SubSectionAccordion';
import SubSectionPaper from '../common/SubSectionPaper';
import HoaAdditionalDocuments from './HoaAdditionalDocuments';
import HoaDates from './HoaDates';
import HoaFees from './HoaFees';
import HoaInfo from './HoaInfo';
import HoaNotRequired from './HoaNotRequired';
import HoaPrimaryDocument, {
    PURCHASE_PRIMARY_DOCUMENT_NAME,
    REFINANCE_PRIMARY_DOCUMENT_NAME,
} from './HoaPrimaryDocument';
import { calculateRequired } from './util';

const sectionType = IMPORTANT_EVENT_TYPES.hoa;
const sectionEvent = IMPORTANT_DASHBOARD_EVENTS[sectionType];

const SUB_SECTION_TYPES = {
    info: 'info',
    important_dates: 'important_dates',
    fees_and_charges: 'fees_and_charges',
    primary_document: 'primary_document',
    additional_documents: 'additional_documents',
};

const SUB_SECTION_CONFIG = {
    [SUB_SECTION_TYPES.info]: {
        title: 'About HOA',
        Icon: <InfoOutlinedIcon color="default" />,
        renderContent: (props) => <HoaInfo {...props} />,
    },
    [SUB_SECTION_TYPES.important_dates]: {
        title: 'Important Dates',
        Icon: <CalendarMonthIcon color="default" />,
        renderContent: (props) => <HoaDates {...props} />,
    },
    [SUB_SECTION_TYPES.fees_and_charges]: {
        title: 'Fees & Charges',
        Icon: <AttachMoneyIcon color="default" />,
        renderContent: (props) => <HoaFees {...props} />,
    },
    [SUB_SECTION_TYPES.primary_document]: {
        renderTitle: (props) => {
            const { order } = props;
            const isPurchase = order?.transaction_type === TRANSACTION_TYPE.purchase;
            const title = isPurchase ? PURCHASE_PRIMARY_DOCUMENT_NAME : REFINANCE_PRIMARY_DOCUMENT_NAME;
            return <ListItemText primary={title} />;
        },
        Icon: <DescriptionOutlinedIcon color="default" />,
        renderContent: (props) => <HoaPrimaryDocument {...props} />,
    },
    [SUB_SECTION_TYPES.additional_documents]: {
        title: 'Additional Documents',
        Icon: <FolderOutlinedIcon color="default" />,
        renderContent: (props) => <HoaAdditionalDocuments {...props} />,
    },
};

const HoaSectionGrid = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);
    const hoaRequired = calculateRequired(order, parcel);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} key={subSectionType}>
                            <SubSectionPaper
                                order={order}
                                sectionType={sectionType}
                                subSectionConfig={subSectionConfig}
                                subSectionType={subSectionType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

const HoaSectionAccordion = ({ order }) => {
    const [expanded, setExpanded] = useState(_.keys(SUB_SECTION_CONFIG));

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded && !expanded.includes(panel)) {
            setExpanded([...expanded, panel]);
        } else if (!isExpanded && expanded.includes(panel)) {
            const expandedCopy = [...expanded];
            expandedCopy.splice(expandedCopy.indexOf(panel), 1);
            setExpanded(expandedCopy);
        }
    };

    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);
    const hoaRequired = calculateRequired(order, parcel);

    if (!hoaRequired) {
        // If HOA is not required, only show the info section
        // NOTE: dirty expanded hardcoded atm - fix later
        const hoaInfoSection = SUB_SECTION_CONFIG[SUB_SECTION_TYPES.info];
        return (
            <AccordionBox>
                <SubSectionAccordion
                    order={order}
                    expanded={[SUB_SECTION_TYPES.info]}
                    handleChange={handleChange}
                    sectionType={sectionType}
                    subSectionConfig={hoaInfoSection}
                    subSectionType={SUB_SECTION_TYPES.info}
                />
            </AccordionBox>
        );
    }

    return (
        <AccordionBox>
            {_.map(SUB_SECTION_CONFIG, (subSectionConfig, subSectionType) => {
                return (
                    <SubSectionAccordion
                        key={subSectionType}
                        order={order}
                        expanded={expanded.includes(subSectionType) ? subSectionType : null}
                        handleChange={handleChange}
                        sectionType={sectionType}
                        subSectionConfig={subSectionConfig}
                        subSectionType={subSectionType}
                    />
                );
            })}
        </AccordionBox>
    );
};

const HoaSection = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <Stack direction="row" alignItems="center">
                <Typography variant="h4" fontWeight={500}>
                    Homeowners Association
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
            </Stack>

            <HoaNotRequired order={order} />

            {isExtraSmallScreen ? <HoaSectionAccordion order={order} /> : <HoaSectionGrid order={order} />}
        </Stack>
    );
};

HoaSection.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default HoaSection;
