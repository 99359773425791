import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Config from '../../config';
import { formatISODate } from '../../helpers/utils';

import { useGetOrderDocumentsQuery } from '../../api/document';
import { useGetOrderQuery } from '../../api/order';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';

const OrderDocumentGroupItemSkeleton = () => {
    return (
        <Paper
            variant="outlined"
            sx={{
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                    <Skeleton type="text" height={10} width="60%" />
                    <Skeleton type="text" height={10} width="30%" />
                </Stack>

                <Skeleton variant="circular" width={24} height={24} />
            </Stack>
        </Paper>
    );
};

const OrderDocumentGroupItem = ({ name, secondary, download, view }) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                p: 2,
                borderRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    p: 0,
                }}
            >
                <Stack direction="column">
                    <Typography variant="h6">{name}</Typography>
                    {secondary && (
                        <Typography variant="h8" color="text.secondary">
                            {secondary}
                        </Typography>
                    )}
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center">
                    <IconButton aria-label="download" component="a" href={view} target="_blank" sx={{ mr: -1 }}>
                        <SaveAltIcon />
                    </IconButton>

                    {/* <IconButton edge="end" aria-label="open" component="a" href={view} target="_blank">
                        <OpenInNewIcon />
                    </IconButton> */}
                </Stack>
            </Stack>
        </Paper>
    );
};

const OrderDocumentGroup = ({ documents, loading = false }) => {
    // sort list of documeents
    const sortedDocuments = useMemo(
        () =>
            _.sortBy(documents, (document) => {
                return document.created_datetime;
            }),
        [documents]
    );

    // console.log(sortedDocuments);

    if (loading) {
        return (
            <Stack direction="column" spacing={2}>
                {_.map(_.range(3), (i) => {
                    return <OrderDocumentGroupItemSkeleton key={i} />;
                })}
            </Stack>
        );
    }

    if (sortedDocuments.length === 0) {
        return false;
    }

    return (
        <Stack direction="column" spacing={2}>
            {_.map(sortedDocuments, (document) => {
                const primaryText = _.startCase(document.type);
                const contentType = document.content_type
                    ? ` • ${_.upperCase(_.split(document.content_type, '/')[1])}`
                    : '';
                const secondaryText = `${formatISODate(document.created_datetime, 'MMM d')}${contentType}`;

                const viewUrl = `${Config.api_base}/order/${document.order_id}/document/${document.id}/file`;
                const downloadUrl = `${viewUrl}?attachment`;
                return (
                    <OrderDocumentGroupItem
                        key={document.id}
                        name={primaryText}
                        secondary={secondaryText}
                        download={downloadUrl}
                        view={viewUrl}
                    />
                );
            })}
        </Stack>
    );
};

const OrderDocuments = ({ orderId }) => {
    const { data: order, isError: orderError, isLoading: orderLoading } = useGetOrderQuery(orderId);
    const {
        data: orderDocuments,
        isError: orderDocumentsError,
        isLoading: orderDocumentsLoading,
    } = useGetOrderDocumentsQuery(orderId);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const loading = orderLoading || orderDocumentsLoading;

    if (!order) {
        // TODO need standard error component
        return <Stack direction="column">No order found</Stack>;
    }

    // console.log(orderDocuments);

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.lightGray,
                flex: 1,
                minHeight: 'calc(100vh - 129px)',
                // borderTopLeftRadius: (theme) => theme.spacing(2),
                // borderTopRightRadius: (theme) => theme.spacing(2),
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 2,
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ zIndex: 1 }}>
                    <Typography variant="h4" fontWeight={500}>
                        Documents
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton color="default">
                        <AddIcon />
                    </IconButton>
                </Stack>

                <OrderDocumentGroup documents={orderDocuments} loading={loading} />
            </Stack>
        </Box>
    );
};

OrderDocuments.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderDocuments;
