import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findParcelInOrder } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { AssignmentDueDate, AssignmentReceivedDate } from './AssignmentDates';
import { AssignmentAmount } from './AssignmentFees';
import { calculateReceivedDate } from './util';

import { useGetOrderMemberAssociationsQuery } from 'api/order';

function AssignmentSnapshot({ order }) {
    const {
        data: orderMemberAssociations,
        isError,
        isLoading,
        isFetching,
    } = useGetOrderMemberAssociationsQuery(order.id);

    const assignmentMemberships = _.get(orderMemberAssociations, 'assignment_memberships', []);
    const assignor = _.find(assignmentMemberships, { type: 'assignor' }); // TODO enum
    const assignee = _.find(assignmentMemberships, { type: 'assignee' }); // TODO enum - NOTE: might not exist

    const parcel = findParcelInOrder(order, assignor?.assignment_parcel_id);

    // If received date is available, show it instead of due date
    const showReceivedDate = !!calculateReceivedDate(order, parcel);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <AssignmentAmount parcel={parcel} />

                {showReceivedDate ? (
                    <AssignmentReceivedDate order={order} parcel={parcel} />
                ) : (
                    <AssignmentDueDate order={order} parcel={parcel} />
                )}
            </Stack>

            {/* <AssignmentInfoShort order={order} /> */}

            <SnapshotLink orderId={order.id} sectionType={PARCEL_TYPE.assignment} />
        </Stack>
    );
}

AssignmentSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default AssignmentSnapshot;
