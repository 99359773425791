import { FormLabel, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PhoneFieldMask from '../../mask/PhoneFieldMask';

// NOTE: confusing terminology as 'contact' was decided to be used on the frontend to refer to the users
// However, here contact refers to the contact details object used by multiple entities such as users, legal entities, etc.

export const INITIAL_CONTACT_DATA = {
    email: '',
    phone: '',
};

export const formatContactPayload = (contactData) => {
    const contactPayload = {};
    let contactEmpty = true;
    _.forEach(contactData, (value, key) => {
        if (_.isNil(value) || value === '') {
            // If single field is empty assign null for field
            contactPayload[key] = null;
        } else {
            contactEmpty = false;
            contactPayload[key] = value;
        }
    });

    // If all fields are empty return null
    if (contactEmpty) {
        return null;
    }

    return contactPayload;
};

const ContactForm = ({
    contactData,
    setContactData,
    label = 'Contact',
    showLabel = false,
    required = false,
    disabled = false,
    requiredFields = [],
    disabledFields = [],
}) => {
    // TODO add support for required, disabled, label, showLabel

    const handleChange = ({ target }) => {
        setContactData({
            ...contactData,
            [target.name]: target.value,
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            {showLabel && (
                <FormLabel id="contact-group-label" required={false}>
                    {label}
                </FormLabel>
            )}

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <TextField
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    value={contactData.email}
                    size="small"
                    sx={{ flex: 1 }}
                    required={required || requiredFields.includes('email')}
                    disabled={disabled || disabledFields.includes('email')}
                />

                <TextField
                    name="phone"
                    label="Phone"
                    onChange={handleChange}
                    value={contactData.phone}
                    size="small"
                    sx={{ flex: 1 }}
                    InputProps={{
                        inputComponent: PhoneFieldMask,
                    }}
                    required={required || requiredFields.includes('phone')}
                    disabled={disabled || disabledFields.includes('phone')}
                />
            </Stack>
        </Stack>
    );
};

ContactForm.propTypes = {
    contactData: PropTypes.object.isRequired,
    setContactData: PropTypes.func.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    requiredFields: PropTypes.array,
    disabledFields: PropTypes.array,
};

export default ContactForm;
