import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton } from '@mui/material';
import React from 'react';

const Notification = () => {
    return (
        <IconButton aria-label="notification" size="large" sx={{ color: (theme) => theme.palette.background.default }}>
            <NotificationsNoneIcon fontSize="inherit" />
        </IconButton>
    );
};

export default Notification;
