import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// TODO need short and long descriptions
// one for dashboard 'snapshot' view
// one for dedicated 'section' view
export const ClosingInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Closing</b> process includes the final steps required of escrow before the property is officially
            transferred from the seller to the buyer and all funds are released.
        </Typography>
    );
};

const ClosingInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Closing</b> process includes the final steps required of escrow before the property is officially
            transferred from the seller to the buyer and all funds are released.
        </Typography>
    );
};

ClosingInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingInfo;
