import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import { calculateTaxCertETA, calculateTaxCertOrderedDate, calculateTaxCertReceivedDate } from './util';

const TaxDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.tax_cert);

    const taxCertOrderedDate = calculateTaxCertOrderedDate(order, parcel);
    const taxCertETA = calculateTaxCertETA(order, parcel);
    const taxCertReceivedDate = calculateTaxCertReceivedDate(order, parcel);

    const placeholderText = 'TBD';

    const formattedTaxCertOrderedDate = taxCertOrderedDate
        ? format(taxCertOrderedDate, 'MMM d, yyyy')
        : placeholderText;
    const formattedTaxCertETA = taxCertETA ? format(taxCertETA, 'MMM d, yyyy') : placeholderText;
    const formattedTaxCertReceivedDate = taxCertReceivedDate
        ? format(taxCertReceivedDate, 'MMM d, yyyy')
        : placeholderText;

    const showETA = taxCertOrderedDate && !taxCertReceivedDate;
    const showReceivedDate = taxCertOrderedDate && taxCertReceivedDate;

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Typography color="text.secondary">Ordered Date:</Typography>
                <Typography color="text.primary">{formattedTaxCertOrderedDate}</Typography>
            </Stack>

            {showETA && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">ETA:</Typography>
                    <Typography color="text.primary">{formattedTaxCertETA}</Typography>
                </Stack>
            )}

            {showReceivedDate && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography color="text.secondary">Received Date:</Typography>
                    <Typography color="text.primary">{formattedTaxCertReceivedDate}</Typography>
                </Stack>
            )}
        </Stack>
    );
};

TaxDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default TaxDates;
