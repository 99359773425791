import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useGetOrderDocumentsByType } from '../../../../api/document';
import Config from '../../../../config';
import { DOCUMENT_TYPE, PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType, formatDocumentType } from '../../../../helpers/utils';
import { Button } from '../../../common/styled';

export const PRIMARY_DOCUMENT_TYPE = DOCUMENT_TYPE.CD_HUD;
export const PRIMARY_DOCUMENT_NAME = formatDocumentType(PRIMARY_DOCUMENT_TYPE);

const ClosingDocumentsPrimaryDocumentDownload = ({ document }) => {
    if (!document) {
        return null;
    }

    const viewUrl = `${Config.api_base}/order/${document.order_id}/document/${document.id}/file`;

    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
                color="primary"
                variant="contained"
                aria-label="download"
                component="a"
                href={viewUrl}
                target="_blank"
                disableElevation
                sx={{
                    minWidth: '160px',
                }}
            >
                Download
            </Button>
        </Stack>
    );
};

const ClosingDocumentsPrimaryDocument = ({ order }) => {
    const {
        data: primaryDocuments,
        isError: primaryDocumentsError,
        isLoading: primaryDocumentsLoading,
    } = useGetOrderDocumentsByType({
        orderId: order.id,
        documentTypeList: [PRIMARY_DOCUMENT_TYPE],
    });
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.closing);

    // TODO handle errors or eat exception?
    // TODO use recieved_date as confirmation that document has been provided even when user doesn't have access to it?

    // NOTE: if there are multiple documents of the same type, we will need to handle that
    // In some cases that is valid and in other cases we might want to only display one.
    const primaryDocument = _.get(primaryDocuments, '0', null);

    return (
        <Stack direction="column" spacing={4}>
            <Typography variant="body2" color="text.primary">
                TODO describe CD/HUD
            </Typography>

            <ClosingDocumentsPrimaryDocumentDownload document={primaryDocument} />
        </Stack>
    );
};

ClosingDocumentsPrimaryDocument.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingDocumentsPrimaryDocument;
