import { Stack } from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { addBusinessDays, format, subBusinessDays } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import customFields from 'components/common/form/rjsf/fields';
import customWidgets from 'components/common/form/rjsf/widgets';
import useActionData from 'components/common/hooks/useActionData';
import { LoadingButton } from 'components/common/styled';
import { ACTION_STATUS } from 'helpers/constants';

const UI_SCHEMA_SEED_KEY = 'ui:seed';

// TODO move/consolidate this logic elsewhere
const generateDateSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "date",
            "source": "parcel",
            "path": "additional_data.hoa_eta",
            "base": "today",
            "offset": 1,
        }

        base options:
            * today

        response format:
            string iso date or null
    */

    // Generate default seed date
    const baseDate = new Date();
    let seedDate = null;
    if (seedValue.offset && seedValue.offset > 0) {
        seedDate = addBusinessDays(baseDate, seedValue.offset);
    } else if (seedValue.offset && seedValue.offset < 0) {
        seedDate = subBusinessDays(baseDate, seedValue.offset);
    }

    // Override seed date if source exists and is not null
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return format(sourceData, 'yyyy-MM-dd');
        }
    }

    return seedDate ? format(seedDate, 'yyyy-MM-dd') : null;
};

const generateDateTimeSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "date-time",
            "source": "parcel",
            "path": "additional_data.seller_transfer_time",
        }

        response format:
            string iso datetime or null
    */

    // Override seed date if source exists and is not null
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceDateTime = _.get(sourceEntity, seedValue.path);
        if (sourceDateTime) {
            // Note: we don't need to format as the found date should already be in ison format string
            return sourceDateTime;
        }
    }

    return null;
};

const generateNumberSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "number",
            "source": "parcel",
            "path": "additional_data.hoa_period",
            "default": 10,
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return seedValue.default || null;
};

const generateAddressSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "address",
            "source": "parcel",
            "path": "additional_data.hoa_period",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

const generateStringSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "string",
            "source": "parcel",
            "path": "additional_data.hoa_period",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

const generateProfileSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "profile",
            "source": "user",
            "path": "",
        }
    */
    const source = seedValue.source;

    if (source) {
        const sourceEntity = sourceEntityMap[source];

        const path = _.get(seedValue, 'path');
        // Allow empty path to return the entire source entity
        const sourceData = !!path ? _.get(sourceEntity, path) : sourceEntity;
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

const generateContactSeedValue = (seedValue, sourceEntityMap) => {
    /*
        "ui:seed": {
            "type": "contact",
            "source": "user",
            "path": "contact",
        }
    */
    const source = seedValue.source;
    if (source) {
        const sourceEntity = sourceEntityMap[source];
        const sourceData = _.get(sourceEntity, seedValue.path);
        if (sourceData) {
            return sourceData;
        }
    }

    return null;
};

const generateSeedValue = (seedValue, sourceEntityMap) => {
    const seedValueType = seedValue.type;
    // TODO enum all cases
    switch (seedValueType) {
        case 'date':
            return generateDateSeedValue(seedValue, sourceEntityMap);
        case 'date-time':
            return generateDateTimeSeedValue(seedValue, sourceEntityMap);
        case 'number':
            return generateNumberSeedValue(seedValue, sourceEntityMap);
        case 'address':
            return generateAddressSeedValue(seedValue, sourceEntityMap);
        case 'profile':
            return generateProfileSeedValue(seedValue, sourceEntityMap);
        case 'contact':
            return generateContactSeedValue(seedValue, sourceEntityMap);
        case 'string':
            return generateStringSeedValue(seedValue, sourceEntityMap);
        default:
            return null;
    }
};

const ActionForm = ({ action, orderId, isSubmitting, handleUpdateAction }) => {
    const actionEntityData = useActionData(orderId, action);
    const [actionAdditionalData, setActionAdditionalData] = useState();

    useEffect(() => {
        if (action && !actionEntityData.isFetching) {
            // const sourceEntityMap = {
            //     ...actionEntityData,
            // };
            const newActionCopy = JSON.parse(JSON.stringify(action));

            const formData = newActionCopy.additional_data;
            const uiSchema = action.additional_data_schema.ui_schema;

            _.forEach(uiSchema, (uiSchemaField, uiSchemaKey) => {
                if (_.has(uiSchemaField, UI_SCHEMA_SEED_KEY)) {
                    const seedValue = generateSeedValue(uiSchemaField[UI_SCHEMA_SEED_KEY], actionEntityData);
                    if (seedValue !== null) {
                        formData[uiSchemaKey] = seedValue;
                    }
                }
            });
            setActionAdditionalData(formData);
        }
    }, [
        action,
        actionEntityData.order,
        actionEntityData.orderMember,
        actionEntityData.parcel,
        actionEntityData.parentParcel,
        actionEntityData.user,
        actionEntityData.isFetching,
    ]);

    const handleChange = (event) => {
        const { formData } = event;

        setActionAdditionalData(_.isEmpty(formData) ? null : formData);
    };

    const handleSubmit = (event) => {
        const { formData } = event;

        const updateActionData = {
            status: ACTION_STATUS.complete,
        };

        if (!_.isEmpty(formData)) {
            // Only add formData if it is not empty
            // This allows us to support 'mark' as done actions with no form fields in the modal
            // And allows parent component to decide whether or not to launch modal in this scenario
            updateActionData['additional_data'] = formData;
        }

        handleUpdateAction(updateActionData);
    };

    // Determine if action is a simple 'mark as done' action or if it requires additional data
    const hasAdditionalDataRequirement = !_.isEmpty(action.additional_data_schema.json_schema);

    if (actionEntityData.isFetching || !actionAdditionalData) {
        return <div>Loading...</div>;
    }

    return (
        <Stack spacing={3}>
            <Form
                schema={action.additional_data_schema.json_schema}
                uiSchema={action.additional_data_schema.ui_schema}
                validator={validator}
                fields={customFields}
                widgets={customWidgets}
                formData={actionAdditionalData}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onError={(e) => console.log('errors', e)}
                omitExtraData={true}
                // liveOmit={true}
            >
                <Stack
                    direction="row-reverse"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={3}
                    sx={{
                        marginTop: (theme) => theme.spacing(6),
                    }}
                >
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        disableElevation
                        sx={{
                            minWidth: '160px',
                        }}
                    >
                        {hasAdditionalDataRequirement ? 'Submit' : 'Complete'}
                    </LoadingButton>
                </Stack>
            </Form>
        </Stack>
    );
};

ActionForm.propTypes = {
    action: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleUpdateAction: PropTypes.func.isRequired,
};

export default ActionForm;
