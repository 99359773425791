import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { OptionPeriodEndDate } from './OptionPeriodDates';
import { OptionPeriodInfoShort } from './OptionPeriodInfo';

function OptionPeriodSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.earnest_money);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={1}>
                <OptionPeriodEndDate order={order} parcel={parcel} />
            </Stack>

            <OptionPeriodInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={'option_period'} />
        </Stack>
    );
}

OptionPeriodSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OptionPeriodSnapshot;
