/*
    TODO:
    - [] Factory pattern to display relevant order info depending on user role
        - Since roles are order-specific, how do we determine which role is the 'main' one for cross-order views?
    - [] OrderList component
    - [] OrderListGrid component
    - [] OrderListFilter component
    - [] Solidify mobile responsiveness for OrderListGrid
        - Probably just cut down columns progressively
        - Perhaps replace some like type with icons in place of text (purchase/refinance)
        - Can all filter dropdowns fit on mobile screen?
    - [] Determine better term than 'order'
    - [] No Order Screen
*/

import { Box, Paper, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { useGetOrdersQuery } from '../../api/order';
import { ORDER_FILTER_TYPE, ORDER_STATUS } from '../../helpers/constants';
import { filterOrders } from '../../helpers/utils';
import useDeviceDetails, { SCREEN_SIZE } from '../common/hooks/useDeviceDetails';
import OrderListColumnGrid from './OrderListColumnGrid';
import OrderListFilter from './OrderListFilter';
import OrderListGrid from './OrderListGrid';

// TODO pair this down to just the relevant filters
const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.search]: '',
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.workflowType]: '',
    [ORDER_FILTER_TYPE.orderStatus]: [ORDER_STATUS.open],
    [ORDER_FILTER_TYPE.closeDateStatus]: '',
    [ORDER_FILTER_TYPE.closeDateRange]: [null, null],
};

const OrderList = () => {
    const { data: orders, isError: ordersError, isLoading: ordersLoading } = useGetOrdersQuery();

    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    const deviceDetails = useDeviceDetails();
    const isExtraSmallScreen = deviceDetails.size === SCREEN_SIZE.small && deviceDetails.isCompact;

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    return (
        <Paper
            elevation={0}
            sx={{
                width: '100%',
                borderRadius: (theme) => theme.spacing(1),
                ...(isExtraSmallScreen && {
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 0,
                    backgroundColor: (theme) => theme.palette.background.lightGray,
                    // borderTopLeftRadius: (theme) => theme.spacing(2),
                    // borderTopRightRadius: (theme) => theme.spacing(2),
                }),
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    padding: (theme) => theme.spacing(isExtraSmallScreen ? 2 : 3),
                    borderBottom: !isExtraSmallScreen ? '1px solid #EAECF0' : 'none',
                }}
            >
                <Typography
                    variant="h3"
                    fontWeight={isExtraSmallScreen ? 500 : 600}
                    fontSize={isExtraSmallScreen ? 20 : 24}
                >
                    Escrow
                </Typography>
                <Box sx={{ flex: 1 }} />
                <OrderListFilter
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    defaultFilters={DEFAULT_FILTER_STATE}
                />
            </Stack>

            {isExtraSmallScreen ? (
                <OrderListColumnGrid orders={filteredOrders} loading={ordersLoading} />
            ) : (
                <OrderListGrid orders={filteredOrders} loading={ordersLoading} />
            )}
        </Paper>
    );
};

OrderList.propTypes = {};

export default OrderList;
