import { Box, Link, Stack, Typography } from '@mui/material';
import { addBusinessDays, differenceInDays, format, isAfter, parseISO } from 'date-fns';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import { ENTITY_TYPE, PARCEL_TYPE, TRANSACTION_TYPE } from './constants';
import { USDollar, formatISODate, isEntityStatusTerminal } from './utils';

/*
TODO move to components folder as it will now container components

PARCEL:
    - Earnest Money
        - Earnest Money & Option Fee Due Date (Event)
            - Earnest Money: $$$
            - Option Fee: $$$
            - Due Date: Aug 30, 2023
        - Option Fee Period (Event)
            - Option Ends: Sep 15, 2023 (7 days)
    - Title Commitment (Event)
        - Commitment Sent: Sep 5, 2023
        - Objection Date: Sep 10, 2023 (5 days)
    - Tax Cert
        - Real Estate Taxes (Event)
            - Prior Year Taxes: $$$
            - Overdue Taxes: $$$
    - HOA (Event)
        - HOA Sent: Sep 5, 2023
        - Objection Date: Sep 15, 2023 (10 days)
    - Survey (Event)
        - Survey Required: No
            - don't show if survey is required
        - Existing Survey: Yes/No
        - Survey Sent: Sep 15, 2023
        - Objection Date: Sep 20, 2023 (5 days)
    - Prep for Closing (Event - TBD not directly associated w/ specific parcel)
    - Closing (Event)


    TODO make function that generates all important events and breaks them into sorted groups:
    - Completed
        - sorted by order of completion
    - Upcoming
        - sorted by closest date

    This means each event needs:
    - completion criteria
    - associated completion date (for completed)
    - required complete date (for upcoming)

*/

export const IMPORTANT_EVENT_TYPES = {
    assignment: 'assignment',
    earnest_money: 'earnest_money',
    option_period: 'option_period',
    hoa: 'hoa',
    tax_cert: 'tax_cert',
    survey: 'survey',
    title_commitment: 'title_commitment',
    closing_prep: 'closing_prep',
    closing: 'closing',
};

export const IMPORTANT_EVENT_TYPE_ORDER = _.keys(IMPORTANT_EVENT_TYPES);

export const IMPORTANT_DASHBOARD_EVENTS = {
    [IMPORTANT_EVENT_TYPES.earnest_money]: {
        title: 'Earnest Money',
        type: IMPORTANT_EVENT_TYPES.earnest_money,
        parcelType: PARCEL_TYPE.earnest_money,
        calculateComplete(order, parcel) {
            // returns true or false
            return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // date of parcel complete status
            // TODO eventually record explicit date of contract reception and receipt of earnest money
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            const completedDate = _.get(parcel, 'current_status.created_datetime', null);
            if (!completedDate) {
                return null;
            }

            return parseISO(completedDate);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
            if (!requiredCompleteDate) {
                return null;
            }

            return parseISO(requiredCompleteDate);
        },
    },
    [IMPORTANT_EVENT_TYPES.option_period]: {
        title: 'Option Period',
        type: IMPORTANT_EVENT_TYPES.option_period,
        parcelType: PARCEL_TYPE.earnest_money,
        calculateComplete(order, parcel) {
            // returns true or false
            // compare today's date to the option fee period end date
            const optionFeePeriodEnd = this.calculateRequiredCompleteDate(order, parcel);
            if (!optionFeePeriodEnd) {
                return false;
            }

            const today = new Date();

            return isAfter(today, optionFeePeriodEnd);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // NOTE: there is no 'action' that completes this event in advance.
            // The 'complete' date is the final date of the option fee period if it has passed
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            return this.calculateRequiredCompleteDate(order, parcel);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // TODO shift to using pre-calculated option_period_end_date in additional data
            // returns date obj or null
            const optionFeePeriod = _.get(parcel, 'additional_data.option_period');
            if (!optionFeePeriod) {
                return null;
            }

            // TODO need to verify this
            const openDate = parseISO(order.contract_start_date);
            const optionFeePeriodEnd = addBusinessDays(openDate, optionFeePeriod);

            return optionFeePeriodEnd;
        },
    },
    [IMPORTANT_EVENT_TYPES.title_commitment]: {
        title: 'Title Commitment',
        type: IMPORTANT_EVENT_TYPES.title_commitment,
        parcelType: PARCEL_TYPE.title_exam,
        calculateComplete(order, parcel) {
            // returns true or false
            // compare today's date to the objection period end date
            const objectionPeriodEnd = this.calculateRequiredCompleteDate(order, parcel);
            if (!objectionPeriodEnd) {
                return false;
            }

            const today = new Date();

            return isAfter(today, objectionPeriodEnd);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // NOTE: there is no 'action' that completes this event in advance.
            // The 'complete' date is the final date of the objection period if it has passed
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            return this.calculateRequiredCompleteDate(order, parcel);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            const objectionPeriodEndDate = _.get(parcel, 'additional_data.objection_date');
            if (!objectionPeriodEndDate) {
                return null;
            }

            return parseISO(objectionPeriodEndDate);
        },
    },
    [IMPORTANT_EVENT_TYPES.tax_cert]: {
        title: 'Real Estate Taxes',
        type: IMPORTANT_EVENT_TYPES.tax_cert,
        parcelType: PARCEL_TYPE.tax_cert,
        calculateComplete(order, parcel) {
            // returns true or false
            return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // date of parcel complete status
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            const completedDate = _.get(parcel, 'current_status.created_datetime', null);
            if (!completedDate) {
                return null;
            }

            return parseISO(completedDate);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
            if (!requiredCompleteDate) {
                return null;
            }

            return parseISO(requiredCompleteDate);
        },
    },
    [IMPORTANT_EVENT_TYPES.hoa]: {
        title: 'HOA',
        type: IMPORTANT_EVENT_TYPES.hoa,
        parcelType: PARCEL_TYPE.hoa,
        calculateComplete(order, parcel) {
            // returns true or false
            // compare today's date to the hoa period end date

            // If hoa_required is false, then this event is complete
            const hoaRequired = _.get(parcel, 'additional_data.hoa_required', null);
            if (hoaRequired === false) {
                return true;
            }

            // HOA is required, compare today's date to the hoa period end date
            const hoaPeriodEnd = this.calculateRequiredCompleteDate(order, parcel);
            if (!hoaPeriodEnd) {
                return false;
            }

            const today = new Date();

            return isAfter(today, hoaPeriodEnd);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // NOTE: there is no 'action' that completes this event in advance.
            // The 'complete' date is the final date of the required_complete_date (based off hoa period) if it has passed
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            // If hoa_required is false, then this event is considered 'complete' when the terminal status is reached
            const hoaRequired = _.get(parcel, 'additional_data.hoa_required', null);
            if (hoaRequired === false) {
                return parseISO(parcel.current_status.created_datetime);
            }

            return this.calculateRequiredCompleteDate(order, parcel);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
            if (!requiredCompleteDate) {
                return null;
            }

            return parseISO(requiredCompleteDate);
        },
    },
    [IMPORTANT_EVENT_TYPES.survey]: {
        title: 'Survey',
        type: IMPORTANT_EVENT_TYPES.survey,
        parcelType: PARCEL_TYPE.survey,
        calculateComplete(order, parcel) {
            // returns true or false
            // compare today's date to the hoa period end date

            // If survey_required is false, then this event is complete
            const surveyRequired = _.get(parcel, 'additional_data.survey_required', null);
            if (surveyRequired === false) {
                return true;
            }

            // Survey is required, compare today's date to the survey period end date
            const surveyPeriodEnd = this.calculateRequiredCompleteDate(order, parcel);
            if (!surveyPeriodEnd) {
                return false;
            }

            const today = new Date();

            return isAfter(today, surveyPeriodEnd);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            // NOTE: there is no 'action' that completes this event in advance.
            // The 'complete' date is the final date of the required_complete_date (based off survey period) if it has passed
            if (!this.calculateComplete(order, parcel)) {
                return null;
            }

            // If survey_required is false, then this event is considered 'complete' when the terminal status is reached
            const surveyRequired = _.get(parcel, 'additional_data.survey_required', null);
            if (surveyRequired === false) {
                return parseISO(parcel.current_status.created_datetime);
            }

            return this.calculateRequiredCompleteDate(order, parcel);
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            // TODO rework
            const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);
            if (!requiredCompleteDate) {
                return null;
            }

            return parseISO(requiredCompleteDate);
        },
    },
    [IMPORTANT_EVENT_TYPES.closing_prep]: {
        title: 'Prep for Closing',
        type: IMPORTANT_EVENT_TYPES.closing_prep,
        parcelType: PARCEL_TYPE.closing,
        calculateComplete(order, parcel) {
            // returns true or false
            return false;
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            return null;
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            return null;
        },
    },
    [IMPORTANT_EVENT_TYPES.closing]: {
        title: 'Closing',
        type: IMPORTANT_EVENT_TYPES.closing,
        parcelType: PARCEL_TYPE.closing,
        calculateComplete(order, parcel) {
            // returns true or false
            return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
        },
        calculateCompleteDate(order, parcel) {
            // returns date obj or null
            return null;
        },
        calculateRequiredCompleteDate(order, parcel) {
            // returns date obj or null
            return parseISO(order.close_date);
        },
    },
};

const EventAlert = ({ children }) => {
    return (
        <Box
            sx={{
                background: (theme) => theme.palette.secondary.main,
                padding: (theme) => theme.spacing(1),
                // borderLeft: (theme) => `solid 4px ${theme.palette.primary.main}`,
                borderRadius: (theme) => theme.spacing(0.5),
            }}
        >
            <Typography color="text.primary">{children}</Typography>
        </Box>
    );
};

const EventBlurb = ({ children }) => {
    return (
        <Typography variant="body2" color="text.primary">
            {children}
        </Typography>
    );
};

export const IMPORTANT_DASHBOARD_EVENT_COMPONENT_MAP = {
    [IMPORTANT_EVENT_TYPES.earnest_money]: ({ order, parcel }) => {
        const earnestMoneyAmount = _.get(parcel, 'additional_data.earnest_money_amount', null);
        const optionFeeAmount = _.get(parcel, 'additional_data.option_fee_amount', null);
        const requiredCompleteDate = _.get(parcel, 'required_complete_date', null);

        return (
            <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Earnest Money:</Typography>
                        <Typography color="text.primary">
                            {earnestMoneyAmount ? USDollar.format(earnestMoneyAmount) : 'TBD'}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Option Fee:</Typography>
                        <Typography color="text.primary">
                            {optionFeeAmount ? USDollar.format(optionFeeAmount) : 'TBD'}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Due Date:</Typography>
                        <Typography color="text.primary">
                            {requiredCompleteDate ? formatISODate(requiredCompleteDate, 'MMM d, yyyy') : 'TBD'}
                        </Typography>
                    </Stack>
                </Stack>

                <EventBlurb>
                    <b>Earnest Money</b> and <b>Option Fee</b> are two distinct financial commitments made by the
                    potential buyer in the initial stages of the escrow process.
                    <br />
                    <br />
                    The <b>Earnest Money</b> is a deposit from the buyer to demonstrate their intent to purchase the
                    property. This deposit is held in an escrow account until the transaction is completed.
                    <br />
                    <br />
                    The <b>Option Fee</b> is a payment from the buyer to the seller in exchange for a window of time
                    called the <b>Option Period</b> during which the buyer can inspect the property, secure financing,
                    and decide whether to proceed with the purchase. During the <b>Option Period</b>, the buyer has the
                    right to terminate the contract for any reason without losing the <b>Earnest Money</b>.
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.earnest_money}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.option_period]: ({ order, parcel }) => {
        const optionFeePeriodEvent = IMPORTANT_DASHBOARD_EVENTS[IMPORTANT_EVENT_TYPES.option_period];
        const optionFeePeriodEndDate = optionFeePeriodEvent.calculateRequiredCompleteDate(order, parcel);
        const now = new Date();
        const dayDiff = optionFeePeriodEndDate ? differenceInDays(optionFeePeriodEndDate, now) : null;
        const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

        return (
            <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Option Ends:</Typography>
                        <Typography color="text.primary">
                            {optionFeePeriodEndDate
                                ? `${format(optionFeePeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
                                : 'TBD'}
                        </Typography>
                    </Stack>
                </Stack>

                <EventBlurb>
                    The <b>Option Period</b> is a window of time in the initial stages of the escrow process during
                    which the buyer can inspect the property, secure financing, and decide whether to proceed with the
                    purchase. During the <b>Option Period</b>, the buyer has the right to terminate the contract for any
                    reason without losing the Earnest Money.
                    <br />
                    <br />
                    This window of time is secured by a payment from the buyer to the seller called the{' '}
                    <b>Option Fee</b>.
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.option_period}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.title_commitment]: ({ order, parcel }) => {
        // TODO what do we show for refinance?
        const titleCommitmentEvent = IMPORTANT_DASHBOARD_EVENTS[IMPORTANT_EVENT_TYPES.title_commitment];

        const commitmentSentDate = _.get(parcel, 'additional_data.title_commitment_date', null);
        const objectionPeriodEndDate = titleCommitmentEvent.calculateRequiredCompleteDate(order, parcel);
        const now = new Date();
        const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
        const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

        return (
            <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Commitment Sent:</Typography>
                        <Typography color="text.primary">
                            {commitmentSentDate ? formatISODate(commitmentSentDate, 'MMM d, yyyy') : 'TBD'}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Objection Date:</Typography>
                        <Typography color="text.primary">
                            {objectionPeriodEndDate
                                ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
                                : 'TBD'}
                        </Typography>
                    </Stack>
                </Stack>

                <EventBlurb>
                    The <b>Title Commitment</b> is a document provided by Hudly during the escrow process. It summarizes
                    important information about the property's title, including the current owner, any existing liens or
                    encumbrances, and the conditions that must be met for the title to be insured.
                    <br />
                    <br />A <b>Title Commitment</b> document is crucial for both the buyer and lender to understand the
                    status of the property's title and ensures that it can be transferred cleanly and free of any
                    unexpected issues at closing.
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.title_commitment}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.tax_cert]: ({ order, parcel }) => {
        // const taxCertEvent = IMPORTANT_DASHBOARD_EVENTS[IMPORTANT_EVENT_TYPES.tax_cert];

        const priorYearTaxes = _.get(parcel, 'additional_data.prior_year_tax_amount', null);
        const overdueTaxes = _.get(parcel, 'additional_data.overdue_tax_amount', null);

        return (
            <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Prior Year Taxes:</Typography>
                        <Typography color="text.primary">
                            {priorYearTaxes ? USDollar.format(priorYearTaxes) : 'TBD'}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography color="text.secondary">Overdue Taxes:</Typography>
                        <Typography color="text.primary">
                            {overdueTaxes ? USDollar.format(overdueTaxes) : 'TBD'}
                        </Typography>
                    </Stack>
                </Stack>

                <EventBlurb>
                    The <b>Tax Certificate</b> is a document that provides information about the property's current tax
                    status. It typically includes details such as the property's tax identification number, the amount
                    of property taxes owed, any outstanding tax liens or assessments, and the due dates for tax
                    payments.
                    <br />
                    <br />
                    The <b>Tax Certificate</b> is essential for both the buyer and the seller to ensure that property
                    taxes are properly accounted for and paid during the escrow process. It helps prevent any potential
                    issues or disputes related to unpaid property taxes that could affect the transfer of ownership.
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.tax_cert}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.hoa]: ({ order, parcel }) => {
        // TODO what do we show for refinance?
        const hoaEvent = IMPORTANT_DASHBOARD_EVENTS[IMPORTANT_EVENT_TYPES.hoa];

        const hoaRequired = _.get(parcel, 'additional_data.hoa_required', null);
        const hoaSentDate = _.get(parcel, 'additional_data.hoa_received_date', null);
        const objectionPeriodEndDate = hoaEvent.calculateRequiredCompleteDate(order, parcel);
        const now = new Date();
        const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
        const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

        return (
            <Stack direction="column" spacing={4}>
                {hoaRequired === false ? (
                    <EventAlert>
                        HOA {order.transaction_type === TRANSACTION_TYPE.purchase ? 'transfer ' : ''}is not required for
                        this property
                    </EventAlert>
                ) : (
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography color="text.secondary">HOA Sent:</Typography>
                            <Typography color="text.primary">
                                {hoaSentDate ? formatISODate(hoaSentDate, 'MMM d, yyyy') : 'TBD'}
                            </Typography>
                        </Stack>
                        {order.transaction_type === TRANSACTION_TYPE.purchase && (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                <Typography color="text.secondary">Objection Date:</Typography>
                                <Typography color="text.primary">
                                    {objectionPeriodEndDate
                                        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
                                        : 'TBD'}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                )}

                <EventBlurb>
                    A <b>Homeowners Association (HOA)</b> is an organization established within a residential community
                    primarily to manage and enforce certain rules and regulations governing the property and the
                    behavior of its residents.
                    <br />
                    <br />
                    {order.transaction_type === TRANSACTION_TYPE.purchase ? (
                        <>
                            If an <b>HOA</b> exists for a property being sold, an <b>HOA Transfer</b> is generally
                            required.
                        </>
                    ) : (
                        <>
                            If an <b>HOA</b> exists for a property being refinanced, details regarding the <b>HOA</b>
                            are generally required as part of the escrow verification process.
                        </>
                    )}
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.hoa}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.survey]: ({ order, parcel }) => {
        const surveyEvent = IMPORTANT_DASHBOARD_EVENTS[IMPORTANT_EVENT_TYPES.survey];

        const surveyRequired = _.get(parcel, 'additional_data.survey_required', null);
        const existingSurvey = _.get(parcel, 'additional_data.existing_survey', null);
        const surveySentDate = _.get(parcel, 'additional_data.survey_received_date', null);
        const objectionPeriodEndDate = surveyEvent.calculateRequiredCompleteDate(order, parcel);
        const now = new Date();
        const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
        const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

        return (
            <Stack direction="column" spacing={4}>
                {surveyRequired === false ? (
                    <EventAlert>Survey is not required for this property</EventAlert>
                ) : (
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography color="text.secondary">Existing Survey:</Typography>
                            <Typography color="text.primary">
                                {existingSurvey === null ? 'TBD' : existingSurvey === true ? 'Yes' : 'No'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography color="text.secondary">Survey Sent:</Typography>
                            <Typography color="text.primary">
                                {surveySentDate ? formatISODate(surveySentDate, 'MMM d, yyyy') : 'TBD'}
                            </Typography>
                        </Stack>
                        {order.transaction_type === TRANSACTION_TYPE.purchase && (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                <Typography color="text.secondary">Objection Date:</Typography>
                                <Typography color="text.primary">
                                    {objectionPeriodEndDate
                                        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
                                        : 'TBD'}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                )}

                <EventBlurb>
                    A <b>Survey</b> is a detailed examination and measurement of a property's boundaries, structures,
                    and land features. The <b>Survey</b> report provides essential information about the property, such
                    as the location of fences, buildings, easements, encroachments, and other physical attributes.
                    <br />
                    <br />
                    This <b>Survey</b> is crucial in the escrow process as it helps ensure that all parties involved
                    have a clear understanding of the property's dimensions and any potential boundary disputes or legal
                    issues.
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.survey}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.closing_prep]: ({ order, parcel }) => {
        return (
            <Stack direction="column" spacing={4}>
                <EventBlurb>TODO</EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.closing_prep}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
    [IMPORTANT_EVENT_TYPES.closing]: ({ order, parcel }) => {
        return (
            <Stack direction="column" spacing={4}>
                <EventBlurb>
                    The <b>Closing</b> process includes the final steps required of escrow before the property is
                    officially transferred from the seller to the buyer and all funds are released.
                    <br />
                    <br />
                    {/* This includes:
                    <ul>
                        <li>
                            A <b>Title Commitment</b> document is crucial for both the buyer and lender to understand
                            the status of the property's title and ensures that it can be transferred cleanly and free
                            of any unexpected issues at closing.
                        </li>
                    </ul> */}
                </EventBlurb>

                <Link
                    component={NavLink}
                    to={`/escrow/${order.id}/section/${IMPORTANT_EVENT_TYPES.closing}`}
                    underline="hover"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{ marginTop: (theme) => theme.spacing(3) }}
                >
                    Learn More
                </Link>
            </Stack>
        );
    },
};
