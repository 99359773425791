import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Config from '../../../../config';
import { formatISODate } from '../../../../helpers/utils';

const AdditionalDocumentItem = ({ document }) => {
    if (!document) {
        return null;
    }

    const primaryText = _.startCase(document.type);
    const contentType = document.content_type ? ` • ${_.upperCase(_.split(document.content_type, '/')[1])}` : '';
    const secondaryText = `${formatISODate(document.created_datetime, 'MMM d')}${contentType}`;
    const viewUrl = `${Config.api_base}/order/${document.order_id}/document/${document.id}/file`;

    return (
        <ListItem
            secondaryAction={
                <IconButton
                    component="a"
                    href={viewUrl}
                    target="_blank"
                    aria-label="download"
                    sx={{
                        mr: -1,
                    }}
                >
                    <DownloadIcon />
                </IconButton>
            }
            disableGutters
        >
            <ListItemText primary={primaryText} secondary={secondaryText} />
        </ListItem>
    );
};

AdditionalDocumentItem.propTypes = {
    document: PropTypes.object.isRequired,
};

export default AdditionalDocumentItem;
