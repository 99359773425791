import { TextField } from '@mui/material';

import _ from 'lodash';

import DollarFieldMask from 'components/common/mask/DollarFieldMask';

const CustomCurrencyWidget = (props) => {
    // NOTE: unlike other current textfield implementations that expect the value to be a string,
    // this one expects a number as the values are stored in a json blob in the database

    const handleChange = ({ target }) => {
        const floatValue = parseFloat(target.value);
        const originalFloatValue = parseFloat(props.value);

        if (isNaN(floatValue)) {
            props.onChange(0);
        } else if (floatValue !== originalFloatValue) {
            props.onChange(floatValue);
        }
    };

    return (
        <TextField
            label={_.get(props, 'schema.title', '')}
            value={props.value ? `${props.value}` : '0'}
            onChange={handleChange}
            variant="outlined"
            // size="small" // NOTE: this is disabled for now until we set the size for all rjsf fields to be small/compact
            InputProps={{
                inputComponent: DollarFieldMask,
            }}
            onFocus={(event) => {
                // NOTE: timeout to get around various browser event issues
                const target = event.target;
                setTimeout(() => target.select(), 0);
            }}
            disabled={_.get(props, 'readonly', false)}
            required={_.get(props, 'required', false)}
        />
    );
};

export default CustomCurrencyWidget;
