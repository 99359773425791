import { useEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop(props) {
    let location = useLocation();
    const { children } = props;

    useEffect(() => {
        if (location.pathname.includes('/escrow/')) {
            // TODO - hacky so fix
            const windowScroll = window.scrollY;

            if (windowScroll >= 160) {
                window.scrollTo(0, 160);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{children}</>;
}

export default ScrollToTop;
