import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';


export function calculateComplete(order, parcel) {
    // returns true or false
    // compare today's date to the objection period end date
    const objectionPeriodEnd = calculateRequiredCompleteDate(order, parcel);
    if (!objectionPeriodEnd) {
        return false;
    }

    const today = new Date();

    return isAfter(today, objectionPeriodEnd);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    // NOTE: there is no 'action' that completes this event in advance.
    // The 'complete' date is the final date of the objection period if it has passed
    if (!calculateComplete(order, parcel)) {
        return null;
    }

    return calculateRequiredCompleteDate(order, parcel);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj or null
    const objectionPeriodEndDate = _.get(parcel, 'additional_data.objection_date');
    if (!objectionPeriodEndDate) {
        return null;
    }

    return parseISO(objectionPeriodEndDate);
}

export function calculateTitleExamOrderedDate(order, parcel) {
    const titleExamOrderedDate = _.get(parcel, 'additional_data.title_exam_ordered_date');
    if (!titleExamOrderedDate) {
        return null;
    }

    return parseISO(titleExamOrderedDate);
}

export function calculateTitleExamETA(order, parcel) {
    const titleExamETA = _.get(parcel, 'additional_data.title_exam_eta');
    if (!titleExamETA) {
        return null;
    }

    return parseISO(titleExamETA);
}

export function calculateTitleExamReceivedDate(order, parcel) {
    const titleExamReceivedDate = _.get(parcel, 'additional_data.title_exam_received_date');
    if (!titleExamReceivedDate) {
        return null;
    }

    return parseISO(titleExamReceivedDate);
}

export function calculateTitleCommitmentDate(order, parcel) {
    const titleCommitmentDate = _.get(parcel, 'additional_data.title_commitment_date');
    if (!titleCommitmentDate) {
        return null;
    }

    return parseISO(titleCommitmentDate);
}

export function calculateTitleRequestDate(order, parcel) {
    const titleRequestDate = _.get(parcel, 'additional_data.title_request_date');
    if (!titleRequestDate) {
        return null;
    }

    return parseISO(titleRequestDate);
}

export function calculateObjectionPeriod(order, parcel) {
    return _.get(parcel, 'additional_data.objection_period', null);
}

export function calculateObjectionDate(order, parcel) {
    const surveyReceivedDate = _.get(parcel, 'additional_data.objection_date');
    if (!surveyReceivedDate) {
        return null;
    }

    return parseISO(surveyReceivedDate);
}


