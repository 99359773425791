import _ from 'lodash';
import rootApi from './root';

export const ACCOUNT_TAG = _.toUpper('account');

export const accountApi = rootApi.injectEndpoints({
    reducerPath: 'accountApi',
    endpoints: (builder) => ({
        getAccount: builder.query({
            query: () => 'account',
            providesTags: [ACCOUNT_TAG],
        }),
    }),
    overrideExisting: false,
})


export const { useGetAccountQuery } = accountApi