import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Link, Slide, Stack } from '@mui/material';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useGetAccountQuery } from '../api/account';
import Config from '../config';

function LoginPage() {
    const location = useLocation();
    const { data: account, isLoading, error, isError } = useGetAccountQuery();

    const { from } = location.state || { from: { pathname: '/' } };
    const loginUrl = `${Config.api_base}/account/login`;

    if (account && !isLoading && !isError) {
        return (
            <Navigate
                to={{
                    pathname: from.pathname,
                    search: from.search,
                }}
            />
        );
    }

    const isMaintenanceMode = error?.status === 503;
    const maintenanceAlertColor = 'info';

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            sx={{
                p: 3,
                height: '100vh',
                maxHeight: '-webkit-fill-available',
            }}
        >
            <img
                alt="Hudly Title Logo"
                src="/logo-white-transparent.png"
                style={{
                    width: '144px',
                }}
            />

            <LoadingButton
                href={loginUrl}
                variant="contained"
                loading={isLoading}
                size="large"
                // color="primary"
                color="secondary"
                sx={{
                    borderRadius: (theme) => theme.spacing(3),
                    textTransform: 'none',
                    minWidth: '160px',
                }}
                disabled={isMaintenanceMode}
            >
                Log In
            </LoadingButton>

            <Slide direction="up" in={isMaintenanceMode} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        position: 'absolute',
                        left: '0px',
                        bottom: '0px',
                        padding: '24px',
                        width: '100%',
                    }}
                >
                    <Alert
                        severity={maintenanceAlertColor}
                        variant="standard"
                        // className={'MuiAlert-standardInfo'}
                        sx={{
                            width: '100%',
                            maxWidth: '800px', // TODO maybe style changes for non-mobile
                            backgroundColor: (theme) => theme.palette[maintenanceAlertColor].light,
                            color: (theme) => theme.palette[maintenanceAlertColor].dark,
                            '& .MuiAlert-icon': {
                                color: (theme) => theme.palette[maintenanceAlertColor].main,
                            },
                            '& .MuiAlertTitle-root': {
                                color: (theme) => theme.palette[maintenanceAlertColor].dark,
                            },
                        }}
                    >
                        <AlertTitle>Temporary Maintenance</AlertTitle>
                        Please contact us at{' '}
                        <Link href="mailto:escrow@hudlytitle.com" color="info.main">
                            escrow@hudlytitle.com
                        </Link>{' '}
                        with any questions/concerns otherwise try again later.
                    </Alert>
                </Box>
            </Slide>
        </Stack>
    );
}

export default LoginPage;
