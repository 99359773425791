import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import useOrderData from 'components/common/hooks/useOrderData';
import { USDollar } from '../../../../helpers/utils';

export const AssignmentAmount = ({ parcel }) => {
    const earnestMoneyAmount = _.get(parcel, 'additional_data.earnest_money_amount', null);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Earnest Money:</Typography>
            <Typography color="text.primary">
                {earnestMoneyAmount ? USDollar.format(earnestMoneyAmount) : 'TBD'}
            </Typography>
        </Stack>
    );
};

const AssignmentFees = ({ order }) => {
    const boundingElement = useRef();
    const { assignorParcel: parcel } = useOrderData(order.id);

    return (
        <Stack direction="column" spacing={1} ref={boundingElement}>
            <AssignmentAmount parcel={parcel} />
        </Stack>
    );
};

AssignmentFees.propTypes = {
    order: PropTypes.object.isRequired,
};

export default AssignmentFees;
