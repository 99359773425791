import { Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import {
    calculateObjectionDate,
    calculateSurveyETA,
    calculateSurveyOrderedDate,
    calculateSurveyReceivedDate,
} from './util';

export const SurveySentDate = ({ order, parcel }) => {
    const surveyReceivedDate = calculateSurveyReceivedDate(order, parcel);

    const placeholderText = 'TBD';
    const formattedSurveyReceivedDate = surveyReceivedDate
        ? format(surveyReceivedDate, 'MMM d, yyyy')
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Survey Sent Date:</Typography>
            <Typography color="text.primary">{formattedSurveyReceivedDate}</Typography>
        </Stack>
    );
};

SurveySentDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const SurveyObjectionDate = ({ order, parcel }) => {
    // Only show objection date for purchase orders
    if (order.transaction_type !== TRANSACTION_TYPE.purchase) {
        return null;
    }

    const objectionPeriodEndDate = calculateObjectionDate(order, parcel);

    const placeholderText = 'TBD';

    const now = new Date();
    const dayDiff = objectionPeriodEndDate ? differenceInDays(objectionPeriodEndDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';
    const formattedObjectionDate = objectionPeriodEndDate
        ? `${format(objectionPeriodEndDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Objection Period End Date:</Typography>
            <Typography color="text.primary">{formattedObjectionDate}</Typography>
        </Stack>
    );
};

SurveyObjectionDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const SurveyOrderedDate = ({ order, parcel }) => {
    const surveyOrderedDate = calculateSurveyOrderedDate(order, parcel);

    const placeholderText = 'TBD';
    const formattedSurveyOrderedDate = surveyOrderedDate ? format(surveyOrderedDate, 'MMM d, yyyy') : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Survey Ordered Date:</Typography>
            <Typography color="text.primary">{formattedSurveyOrderedDate}</Typography>
        </Stack>
    );
};

SurveyOrderedDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const SurveyETADate = ({ order, parcel }) => {
    const surveyETA = calculateSurveyETA(order, parcel);

    const placeholderText = 'TBD';
    const formattedSurveyETA = surveyETA ? format(surveyETA, 'MMM d, yyyy') : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Survey ETA:</Typography>
            <Typography color="text.primary">{formattedSurveyETA}</Typography>
        </Stack>
    );
};

SurveyETADate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const SurveyDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.survey);

    const surveyOrderedDate = calculateSurveyOrderedDate(order, parcel);
    const surveyReceivedDate = calculateSurveyReceivedDate(order, parcel);

    const showETA = surveyOrderedDate && !surveyReceivedDate;
    const showReceivedDate = surveyOrderedDate && surveyReceivedDate;
    const showObjectionDate = showReceivedDate;

    return (
        <Stack direction="column" spacing={1}>
            <SurveyOrderedDate order={order} parcel={parcel} />

            {showETA && <SurveyETADate order={order} parcel={parcel} />}

            {showReceivedDate && <SurveySentDate order={order} parcel={parcel} />}

            {showObjectionDate && <SurveyObjectionDate order={order} parcel={parcel} />}
        </Stack>
    );
};

SurveyDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default SurveyDates;
