import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';


export const OptionPeriodInfoShort = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Option Period</b> is a window of time in the initial stages of the escrow process during which the
            buyer can inspect the property, secure financing, and decide whether to proceed with the purchase.
        </Typography>
    );
};

const OptionPeriodInfo = ({ order }) => {
    return (
        <Typography variant="body2" color="text.primary">
            The <b>Option Period</b> is a window of time in the initial stages of the escrow process during which the
            buyer can inspect the property, secure financing, and decide whether to proceed with the purchase. During
            the <b>Option Period</b>, the buyer has the right to terminate the contract for any reason without losing
            the Earnest Money.
            <br />
            <br />
            This window of time is secured by a payment from the buyer to the seller called the <b>Option Fee</b>.
        </Typography>
    );
};

OptionPeriodInfo.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OptionPeriodInfo;
