import { Box, Drawer, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import SidebarNav from './SidebarNav';

import { HeaderToolbar } from '../Topbar';

export const SIDEBAR_WIDTH = 240;

const Sidebar = (props) => {
    const { open, variant, onClose } = props;

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            variant={variant}
            sx={{
                // width: SIDEBAR_WIDTH,
                zIndex: 9,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: SIDEBAR_WIDTH,
                    boxSizing: 'border-box',
                    background: (theme) => theme.palette.hudly.main,
                    // backgroundImage: 'url(./egg-shell.png)',
                    border: 0,
                },
            }}
        >
            <HeaderToolbar />

            <Stack direction="column" sx={{ height: '100%', overflow: 'auto' }}>
                <SidebarNav />

                <Box sx={{ flex: 1 }} />

                <Box p={4}>
                    <img
                        alt="Cartoon House"
                        src="/house-1.svg"
                        style={{
                            width: '100%',
                        }}
                    />
                </Box>
            </Stack>
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
