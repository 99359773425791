import { Stack, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import PropTypes from 'prop-types';

import { PARCEL_TYPE, TRANSACTION_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import { calculateObjectionDate, calculateReceivedDate, calculateRequired } from './util';

export const HoaSentDate = ({ order, parcel }) => {
    const hoaRequired = calculateRequired(order, parcel);
    const hoaSentDate = calculateReceivedDate(order, parcel);

    const placeholderText = hoaRequired ? 'TBD' : 'N/A';
    const formattedHoaSentDate = hoaSentDate ? format(hoaSentDate, 'MMM d, yyyy') : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">HOA Sent:</Typography>
            <Typography color="text.primary">{formattedHoaSentDate}</Typography>
        </Stack>
    );
};

HoaSentDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export const HoaObjectionDate = ({ order, parcel }) => {
    if (order.transaction_type !== TRANSACTION_TYPE.purchase) {
        return null;
    }

    const hoaRequired = calculateRequired(order, parcel);
    const objectionDate = calculateObjectionDate(order, parcel);
    const now = new Date();
    const dayDiff = objectionDate ? differenceInDays(objectionDate, now) : null;
    const dayDiffText = dayDiff >= 0 ? ` (${dayDiff} days)` : '';

    const placeholderText = hoaRequired ? 'TBD' : 'N/A';
    const formattedObjectionDate = objectionDate
        ? `${format(objectionDate, 'MMM d, yyyy')}${dayDiffText}`
        : placeholderText;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography color="text.secondary">Objection Date:</Typography>
            <Typography color="text.primary">{formattedObjectionDate}</Typography>
        </Stack>
    );
};

HoaObjectionDate.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

const HoaDates = ({ order }) => {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.hoa);

    return (
        <Stack direction="column" spacing={1}>
            <HoaSentDate order={order} parcel={parcel} />

            <HoaObjectionDate order={order} parcel={parcel} />
        </Stack>
    );
};

HoaDates.propTypes = {
    order: PropTypes.object.isRequired,
};

export default HoaDates;
