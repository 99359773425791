import { parseISO } from 'date-fns';
import _ from 'lodash';

import { ENTITY_TYPE, PARCEL_STATUS } from '../../../../helpers/constants';
import { isEntityStatusTerminal } from '../../../../helpers/utils';


export function isSchedulingComplete(schedulingParcel) {
    return _.get(schedulingParcel, 'current_status.status') === PARCEL_STATUS.complete;
}

export function isSigningComplete(signingParcel) {
    return _.get(signingParcel, 'current_status.status') === PARCEL_STATUS.complete;
}

export function calculateComplete(order, parcel) {
    // returns true or false
    return isEntityStatusTerminal(ENTITY_TYPE.parcel, parcel.current_status.status);
}

export function calculateCompleteDate(order, parcel) {
    // returns date obj or null
    if (!calculateComplete(order, parcel)) {
        return null;
    }

    // assumption is that if closing is 'complete', it was completed on the order's close date
    return calculateRequiredCompleteDate(order, parcel);
}

export function calculateRequiredCompleteDate(order, parcel) {
    // returns date obj
    return parseISO(order.close_date);
}

