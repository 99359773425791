import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { PARCEL_TYPE } from '../../../../helpers/constants';
import { findOrderParcelByType } from '../../../../helpers/utils';
import SnapshotLink from '../common/SnapshotLink';
import { ClosingDocumentsInfoShort } from './ClosingDocumentsInfo';

function ClosingDocumentsSnapshot({ order }) {
    const parcel = findOrderParcelByType(order, PARCEL_TYPE.balancing);

    return (
        <Stack direction="column" spacing={4}>
            <ClosingDocumentsInfoShort order={order} />

            <SnapshotLink orderId={order.id} sectionType={'closing_documents'} />
        </Stack>
    );
}

ClosingDocumentsSnapshot.propTypes = {
    order: PropTypes.object.isRequired,
};

export default ClosingDocumentsSnapshot;
