import { Box, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useGetAccountQuery } from '../../../api/account';
import Config from '../../../config';
import UserAvatar from '../../common/UserAvatar';
import useDeviceDetails, { SCREEN_SIZE } from '../../common/hooks/useDeviceDetails';

const Profile = () => {
    const deviceDetails = useDeviceDetails();
    const { data: account, isLoading: loadingAccount } = useGetAccountQuery();

    const [anchorEl, setAnchorEl] = useState(null);

    const logoutUrl = `${Config.api_base}/account/logout`; // TODO const
    const isSmallScreen = deviceDetails.size === SCREEN_SIZE.small;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!account || loadingAccount) {
        return null;
    }

    const avatarDetails = {
        firstName: _.split(_.get(account, 'name', ' '), ' ')[0],
        lastName: _.split(_.get(account, 'name', ' '), ' ')[1],
        avatarUrl: _.get(account, 'picture', null),
    };

    return (
        <Box>
            <ListItemButton
                className={isSmallScreen ? 'profile-button-small' : 'profile-button'}
                onClick={handleClick}
                sx={{
                    '&.profile-button-small': {
                        padding: 0,
                    },
                }}
            >
                <ListItemAvatar
                    className={isSmallScreen ? 'profile-avatar-small' : 'profile-avatar'}
                    sx={{
                        '&.profile-avatar-small': {
                            minWidth: '40px',
                        },
                    }}
                >
                    <UserAvatar
                        firstName={avatarDetails.firstName}
                        lastName={avatarDetails.lastName}
                        avatarUrl={avatarDetails.avatarUrl}
                        avatarProps={{
                            sx: {
                                marginRight: 0,
                                backgroundColor: '#d34f4f',
                            },
                        }}
                    />
                </ListItemAvatar>

                {!isSmallScreen && (
                    <ListItemText
                        primary={_.get(account, 'name', '')}
                        primaryTypographyProps={{
                            fontWeight: '400',
                            fontSize: '16px',
                            color: (theme) => theme.palette.white,
                        }}
                    />
                )}
            </ListItemButton>

            <Menu id="profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem>
                    <Link to={logoutUrl} style={{ color: 'inherit', textDecoration: 'none' }}>
                        Logout ({account.email})
                    </Link>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default Profile;
